<template>
  <div>
    <v-breadcrumbs :items="breadcrumbList" class="pa-0 text-pfblack-100">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        breadcrumbList: []
      }
    },
    mounted() {
      this.updateList()
    },
    watch: {
      $route() {
          this.updateList()
        }
    },
    methods: {
      updateList() {
        this.breadcrumbList = this.$route.meta.breadcrumb
      }
    }
  }
</script>
<style scoped>
.v-breadcrumbs >>> li {
  font-size: .75rem;
}
.v-breadcrumbs >>> a,
.theme--light.v-breadcrumbs >>> .v-breadcrumbs__item--disabled
 {
  color: inherit;
}
@screen md {
  .v-breadcrumbs >>> li {
    font-size: .875rem;
  }
}
</style>