<template>
    <section id="feature" class="feature">
      <div class="feature__inner js-f-main">
          <SectionTitle title="<span>F</span><span>e</span><span>a</span><span>t</span><span>u</span><span>r</span><span>e</span>"/>
          <div class="r-box">
            <img src="@/assets/img/accounting/hukidasi.png" alt="" class="heding-img pc js-f-fade01">
            <img src="@/assets/img/accounting/hukidasi-sp.png" alt="" class="heding-img sp js-f-fade03">
            <h3 class="js-f-fade02">プロフィットバンク<br class="sp">経理代行の5つの特徴</h3>
            <div class="feature__content" v-for='item in items' :key='item.id' :class='"js-f-trigger" + item.id'>
              <img :src='item.Img' class="sp sp-img">
              <div class="text-box">
                <p class="title" v-html='item.Title'></p>
                <p class="text">{{ item.Text }}</p>
                <p class="large-text">{{ item.LargeText }}</p>
              </div>
              <div class="img-box pc">
                <img :src='item.Img'>
              </div>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {id : 1,Title : 'ITを駆使した<br class="sp">これからの経理代行サービス<br>お客様にあわせた経理業務を提案',Text : '「採用」する経理から、「利用」する経理へ。\n「この業務、必要なの？」をあらゆる角度から分析し、さらに業務フローを再構築することで、不要な業務や重複作業を削減。 ITを駆使し、お客様に最適なシステムや業務の自動化をご提案します。',LargeText : '1',Img : require('@/assets/img/accounting/img01.png'),},
        {id : 2,Title : '幅広い業務領域と<br class="sp">フレキシブルな対応',Text : '徹底したヒアリングを行い、負担となっている経理業務を改善し、本業へ集中できるようサポートします。経理以外の人事労務、営業事務等の作業についても柔軟に対応できます。',LargeText : '2',Img : require('@/assets/img/accounting/img02.png'),},
        {id : 3,Title : 'コア業務に集中。<br class="sp">生産性をより高く',Text : '本質的に必要な経理業務を適切に遂行し、経理・会計の領域で本来やるべき業務(付加価値や専門性が高い仕事)への集中をご支援します。アウトソースできる業務を積極的にお任せいただき、作業をしているだけの時間を減らしてコア業務に取り組む時間を創りだすことで、今まで以上の生産性を実現します。経営者の方には空いた時間を、経営戦略や営業活動などのコア業務に充てていただくことが可能です。',LargeText : '3',Img : require('@/assets/img/accounting/img03.png'),},
        {id : 4,Title : 'リアルタイムな経営モニタリング',Text : '日々の経理業務を滞りなく進めることで、経営状況のリアルタイムなモニタリングを実現します。\n売上・費用の鮮度の高い見える化や毎月のスムーズな月次決算によって、経理がラクになるだけでなく、必要なデータを必要なときに得て経営判断を素早く行えるようになります。',LargeText : '4',Img : require('@/assets/img/accounting/img04.png'),},
        {id : 5,Title : 'ずっと協働できる<br class="sp">経理のパートナー',Text : '私たちは『退職しません』。スタッフチームでサポート致します。スタッフの急な退職や長期休暇などの影響なく、安定したサービスをご提供します。事業・組織・業務フローなどの成長や変化へ柔軟に対応し続ける、ずっと頼れる経理パートナーです。',LargeText : '5',Img : require('@/assets/img/accounting/img05.png'),},
      ]
    }
  },
  methods: {
    AccountingFeature() {
      gsap.fromTo('.js-f-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-f-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-f-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-f-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-f-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-f-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-f-fade03' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-f-fade03',start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger1',  start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger2',  start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger3',  start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger4' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger4',  start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger5' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger5',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.AccountingFeature();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}


.feature {
  padding:2rem 0 5rem 0;
  background-color: #F1EEE7;
  @include sp {
    padding: 1rem 0 0.2rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 4.5rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.5rem 0;
    padding-left: 10%;
  }
  }
  .heding-img {
    width: 43.375rem;
    margin: 0 auto;
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }
  .r-box {
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
        padding-top: 1.5rem;
    }
  }
 &__content {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 2.5rem;
   @include sp {
     display: block;
     width: 15.4375rem;
   }
   &:nth-of-type(2n) {
     flex-direction: row-reverse;
   }

   .sp-img {
     @include sp {
       width: 15.4375rem;
       max-width: 100%;
       margin: 0 auto;
     }
   }
  
  .text-box {
    width: 31.25rem;
    position: relative;
    @include sp {
      width: 15.4375rem;
      margin: 0 auto;
    }
    .title {
      font-size: 1.5rem;
      font-weight: 700;
      font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif !important;
      color: #E56910;
      margin-bottom: 1rem;
      position: relative;
      z-index: 1;
      white-space: pre-line;
      @include sp {
        font-size: 1rem!important;
        line-height: 140%;
        margin-bottom: 0.5rem;
      }

      ::v-deep {
        .sp {
          display: none;
          @include sp {
            display: block;
          }
        }
      }
    }
    .text {
      font-size: 1rem;
      line-height: 200%;
      @include sp {
        font-size: 0.75rem;
        line-height: 180%;
      }
    }
    .large-text {
      position: absolute;
      top: -3.125rem;
      left: 0;
      font-size: 10rem;
      font-weight: 900;
      font-family: 'Noto Sans JP', sans-serif;
      color: #FAF9F7;
      z-index: 0;
      @include sp {
        font-size: 5.5rem;
        top: -6.25rem;
        left: -2.5rem;
      }
    }
  }
  .img-box {
    img {
      width: 21.25rem;
    }
  }

 }
}
</style>