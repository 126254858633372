<template>
    <section id="feature" class="feature">
      <div class="feature__inner js-f-main">
          <SectionTitle title="<span>F</span><span>e</span><span>a</span><span>t</span><span>u</span><span>r</span><span>e</span>"/>
          <div class="r-box">
            <h3 id="feature-title" class="js-f-fade01">プロフィットバンク<br>ソフトウェア受託開発の<br class="sp">4つの特徴</h3>
            <div class="feature__content" v-for='item in items' :key='item.id' :class='"js-f-trigger" + item.id'>
              <img :src='item.ImgSp' class="sp sp-img">
              <div class="text-box">
                <p class="title" v-html='item.Title'></p>
                <p class="text">{{ item.Text }}</p>
                <p class="large-text">{{ item.LargeText }}</p>
              </div>
              <div class="img-box pc">
                <img :src='item.Img'>
              </div>
            </div>

            <div class="feature__more js-f-fade02">
                <p class="m-title">プロフィットバンクでは<br>お支払いのご相談も<br class="sp">承っております。</p>
                <p class="text">
                  分割でお支払いされる場合は、大きく分けて２つあります。<br>
                  「リース契約」か「銀行融資」が主流ですが、どちらがいいとは一概には言えません。<br>
                  経理代行業務も行う弊社であれば、資金調達の最適な方法をご提案できます。<br>
                  <mark>ソフトウェア開発と資金調達の両方をご相談いただけるのは、プロフィットバンクならではです。</mark><br>
                  業務の効率化に悩みがあったり、今のソフトウェアに不満がある場合は、是非ご相談ください。
                </p>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import  gsap  from 'gsap';
import  ScrollTrigger  from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";


export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {id : 1,Title : '要件定義がしっかりとできる',Text : '高機能なものができても、お客様のご要望とズレていては、意味がありません。しっかりとお客様とご相談し、ご要望に沿ったしっかりとしたシステムを開発します。',LargeText : '1',Img : require('@/assets/img/development/f-img01.png'),ImgSp : require('@/assets/img/development/f-img01-sp.png'),},
        {id : 2,Title : '低コスト',Text : '自社開発であるため、コストが割高にならず最適な費用で開発を行うことができます。',LargeText : '2',Img : require('@/assets/img/development/f-img02.png'),ImgSp : require('@/assets/img/development/f-img02.png'),},
        {id : 3,Title : '最新技術のソフトウェア<br class="sp">開発のご提案',Text : '最新の開発技術に対応しており、Go言語やNode.jsを活用したソフトウェア開発を提案しております。',LargeText : '3',Img : require('@/assets/img/development/f-img03.png'),ImgSp : require('@/assets/img/development/f-img03.png'),},
        {id : 4,Title : '最適な支払い方法のご提案',Text : 'ソフトウェア開発は費用が大きくなることが多く、キャッシュ一括でのお支払いをされる企業様は少ないでしょう。分割で支払う場合、経理アウトソーシング事業があるプロフィットバンクにご相談ください。',LargeText : '4',Img : require('@/assets/img/development/f-img04.png'),ImgSp : require('@/assets/img/development/f-img04.png'),},
      ]
    }
  },
  methods: {
    DeveFeature() {
      gsap.fromTo('.js-f-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-f-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-f-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-f-fade01',start: 'center 70%' }});
      gsap.fromTo('.js-f-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-f-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger1',  start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger2',  start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger3',  start: 'center 70%'}});
      gsap.fromTo('.js-f-trigger4' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-f-trigger4',  start: 'center 70%'}});
    }
  },
  mounted() {
    const dom1 = document.querySelector('#feature-title');
    const a = dom1.getBoundingClientRect().top + window.pageYOffset;
    console.log(a)

    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(function () {
      this.DeveFeature();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}


.feature {
  padding:2rem 0 5rem 0;
  background-color: #F1EEE7;
  @include sp {
    padding: 1rem 0 0.2rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 4.5rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.5rem 0;
    padding-left: 4%;
  }
  }
  .heding-img {
    width: 43.375rem;
    margin: 0 auto;
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }
  .r-box {
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
        padding-top: 1.5rem;
    }
  }
 &__content {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 2.5rem;
   @include sp {
     display: block;
     width: 15.4375rem;
   }
   &:nth-of-type(2n) {
     flex-direction: row-reverse;
   }

   .sp-img {
     @include sp {
       width: 15.4375rem;
       max-width: 100%;
       margin: 0 auto;
     }
   }
  
  .text-box {
    width: 31.25rem;
    position: relative;
    @include sp {
      width: 15.4375rem;
      margin: 0 auto;
    }
    .title {
      font-size: 1.5rem!important;
      font-weight: 700;
      font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif !important;
      color: #E56910;
      margin-bottom: 1rem;
      position: relative;
      z-index: 1;
      white-space: pre-line;
      @include sp {
        font-size: 1rem!important;
        line-height: 140%;
        margin-bottom: 0.5rem;
      }

      ::v-deep {
        .sp {
          display: none;
          @include sp {
            display: block;
          }
        }
      }
    }
    .text {
      font-size: 1rem;
      letter-spacing: -0.03em;
      line-height: 200%;
      @include sp {
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 180%;
      }
    }
    .large-text {
      position: absolute;
      top: -3.125rem;
      left: 0;
      font-size: 10rem;
      font-weight: 900;
      font-family: 'Noto Sans JP', sans-serif;
      color: #FAF9F7;
      z-index: 0;
      @include sp {
        font-size: 5.5rem;
        top: -6.25rem;
        left: -2.5rem;
      }
    }
  }
  .img-box {
    img {
      width: 21.25rem;
      position: relative;
      top: -2.5rem;
    }
  }

 }

 &__more {
   background-color: #fff;
   border-radius: 1.25rem;
   padding: 3.5rem 4.1875rem 4rem 4.1875rem;
   margin-top: 7.1875rem;
   position: relative;
   @include sp {
     width: 17.6875rem;
     padding: 2rem 1.25rem 1.5rem 1.25rem;
     margin: 5.5rem 0 2.5rem -0.625rem;
   }
   &::after {
     content: '';
     position: absolute;
     top: -10.625rem;
     right: 11.25rem;
     width: 6.25rem;
     height: 9.375rem;
     background-image: url('~@/assets/img/development/sec-round.png');
     background-position: center;
     background-size:3.125rem;
     @include sp {
       top: -5.9375rem;
       right: 8.125rem;
       width: 1.25rem;
       height: 6.25rem;
       background-size: 1.5625rem;
     }
   }
   .m-title {
     font-size: 2.5rem;
     font-weight: bold;
     color: #E56910;
     text-align: center;
     line-height: 160%;
     margin-bottom: 1.5rem;
     @include sp {
       font-size: 1.25rem;
       margin-bottom: 1rem;
     }
   }
   .text {
     font-size: 1rem;
     line-height: 200%;
     @include sp {
       font-size: 0.75rem;
       line-height: 180%;
     }
     mark {
       font-weight: bold;
       background-color: #F2AB34;
     }
   }
 }
}
</style>