<template>
  <div class="page-menu">
    <div class="page-menu__flex">
      <div class="small"><router-link to="/WebProduct" @click.native="reset()">ホームページ制作</router-link></div>
      <div class="small"><router-link to="/development" @click.native="reset()">ソフトウェア受託開発</router-link></div>
    </div>
    <div class="large">
      <span>SERVICE<br>サービス内容</span>
      <p>経理代行</p>
    </div>
  </div>
</template>
<script>
  export default {

    methods: {
      reset: function () {
        this.$router.go({path: this.$router.currentRoute.path, force: true})
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";


ul {
  padding: 0;
}

p {
  margin: 0;
}

.page-menu {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-top: 5rem;
  position: relative;
  z-index: 2;
  @include sp {
    display: block;
    margin-top: 1rem;
  }

  &__flex {
    display: flex;
    @include sp {
      margin-bottom: 1rem;
    }
  }
  .large {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 45.4375rem;
    background-color: #E56910;
    padding: 2.5rem 6.5rem 2.5rem 11.25rem;
    margin-right: 1.5rem;
    @include sp {
      justify-content: start;
      width: 21rem;
      max-width: 100%;
      padding: 1.5rem 0 1.5rem 24px;
      margin-right: 0;
    }
    span {
      font-size: 1rem;
      color: #fff;
      @include sp {
        font-size: 0.75rem;
      }
    }
    p {
    font-size: 2rem;
    font-weight: 900;
    color: #fff;
    margin-bottom: -0.5rem;
    @include sp {
      font-size: 1.25rem;
      margin-left: 20px;
    }
    }
  }
  .small {
      margin-right: 1.5rem;
      @include sp {
        margin-right: 0;
        margin-left: 1.5rem;
      }
    a {
      display: block;
      width: fit-content;
      background-color: #fff;
      border: 0.0625rem #E56910 solid;
      padding: 0.5625rem 1.2rem;
      font-size: 1.25rem;
      font-weight: 700;
      color: #E56910;
      transition: 0.2s;
      @include sp {
        font-size: 0.75rem;
        padding: 0.6875rem 0.7rem;
      }
      &:hover {
        color: #fff;
        background-color: #E56910;
        transition: 0.2s;
      }
    }
  }
}

</style>