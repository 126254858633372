<template>
  <div id="home">
    <component
      v-for="section in sections"
      :is="section.layout"
      :key="section.index"
      :id="section.id"
      :title="section.title"
      :class="section.className"
      :bgcolor="section.bgcolor"
      :txtcolor="section.txtcolor"
      :content="section.content"
      :breadcrumb="section.breadcrumb"
    />
  </div>
</template>


<script>
/* SectionTile読み込みの予定 */
//import constants from '../common/constants';

/* ConponentFile読み込み */
import SectionMainView from "@/components/templates/SectionMainView";
import SectionBasic from "@/components/templates/SectionBasic";
import Hero from "@/components/organisms/home/Hero";
import Concept from "@/components/organisms/home/Concept";
import Mission from "@/components/organisms/home/Mission";
import Service from "@/components/organisms/home/Service";
import Works from "@/components/organisms/home/Works";
import Message from "@/components/organisms/home/Message";
import Recruit from "@/components/organisms/home/Recruit";
import Contact from "@/components/organisms/home/CVArea";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default {
  components: {
    Hero,
    Concept,
    Mission,
    Service,
    Works,
    Message,
    Recruit,
    Contact,
  },
  data() {
    return {
      sections: [
        {
          id: "hero",
          title: "Hero",
          className: "top-hero",
          bgcolor: "pfgray-100",
          content: Hero,
          layout: SectionMainView,
        },
        {
          id: "mission",
          title: "<span>M</span><span>i</span><span>s</span><span>s</span><span>i</span><span>o</span><span>n</span>",
          className: "top-mission",
          content: Mission,
          layout: SectionBasic,
        },
        {
          id: "concept",
          title: "<span>C</span><span>o</span><span>n</span><span>c</span><span>e</span><span>p</span><span>t</span>",
          className: "top-concept",
          bgcolor: "pfgray-100",
          content: Concept,
          layout: SectionBasic,
        },
        {
          id: "service",
          title: "<span>S</span><span>e</span><span>r</span><span>v</span><span>i</span><span>c</span><span>e</span>",
          className: "top-service",
          bgcolor: "pforange-100",
          txtcolor: "white",
          content: Service,
          layout: SectionBasic,
        },
        {
          id: "works",
          title: "<span>W</span><span>O</span><span>R</span><span>K</span><span>S</span> <span>&</span> <span>P</span><span>A</span><span>R</span><span>T</span><span>N</span><span>E</span><span>R</span><span>S</span>",
          className: "top-works",
          content: Works,
          layout: SectionBasic,
        },
        {
          id: "message",
          title: "<span>M</span><span>e</span><span>s</span><span>s</span><span>a</span><span>g</span><span>e</span>",
          className: "top-message",
          bgcolor: "pfgray-100",
          content: Message,
          layout: SectionBasic,
        },
        {
          id: "recruit",
          title: "<span>R</span><span>e</span><span>c</span><span>r</span><span>u</span><span>i</span><span>t</span>",
          className: "top-recruit",
          bgcolor: "pforange-100",
          txtcolor: "white",
          content: Recruit,
          layout: SectionBasic,
        },
        {
          id: "contact",
          title: "<span>C</span><span>o</span><span>n</span><span>t</span><span>a</span><span>c</span><span>t</span>",
          className: "top-contact",
          content: Contact,
          layout: SectionBasic,
        },
      ],
    };
  },
  methods : {
    HomeAnimation() {
        gsap.fromTo('.top-mission h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.top-mission h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
        gsap.fromTo('.top-concept h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.top-concept h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
        gsap.fromTo('.top-service h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.top-service h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
        gsap.fromTo('.top-works h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.top-works h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
        gsap.fromTo('.top-message h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.top-message h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
        gsap.fromTo('.top-recruit h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.top-recruit h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
        gsap.fromTo('.top-contact h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.top-contact h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.HomeAnimation();
    });
  },
watch: {
        hogeList: function() {
            this.$nextTick(() => {
                var positionY = sessionStorage.getItem('positionY')
                scrollTo(0, positionY);
                setTimeout(function(){
                    scrollTo(0, positionY);
                }, 500);
            })
        }
    },
};
</script>