<template>
  <div id="notfound">
      <component 
        v-for="section in sections" 
        :is="section.layout" 
        :key="section.index" 
        :id="section.id" 
        :title="section.title" 
        :color="section.color" 
        :content="section.content" 
      />
  </div>
</template>
<script>
import NotFound from '@/components/organisms/NotFound';
import SectionBasic from '@/components/templates/SectionBasic';

export default {
  components:{
    NotFound,
    SectionBasic
  },
  data() {
    return {
      sections:[
        {id:'notfound', color:'pforange-100', content:NotFound, layout:SectionBasic }
      ]
    }
  },
  methods:{
    goToHome(){
      this.$router.push('/')
    }
  }
}
</script>