<template>
  <div class="pr-10 pr-md-0">
    <h3 class="message__title js-m-fade01">
      <img src="@/assets/img/top/ceo_message.png" alt="挑戦し続ける" />
    </h3>
    <div class="message__cover">
      <p class="message__cover-text js-m-fade02">
        <span>
        もともと会計事務所などの士業の方々と一緒に中小企業の経営者のサポートを行なってきました。<br>
        中小企業の経営者のお話しを伺っていると、やりたいことは山ほどあるのに、時間や人員が足りず、手が回らない。目先の業務に手が取られ、本来やらなければならない業務を後回しにしてしまっている。<br>
        中小企業の悩みに直面しました。
        </span>
        <span>
        “外部の視点で課題解決に向けたアドバイスをする”ことはコンサルティング会社としての役割ですが、私たちは課題解決を“実行する”ことをモットーにお客様とともに会社の成長のお手伝いをしてきました。
        </span>
        <span>
        IT導入と経理業務、労務管理に課題を感じている中小企業様は多く、そこに注力していく。<br>
        刻々と移り変わる時代の中で、中小企業の成長に貢献するべく、日々新たな課題解決に向けて、まずは私たちが新しい挑戦をしております。
        </span>
      </p>
    </div>
    <p class="message__ceo js-m-fade03">
      <img src="@/assets/img/top/ceo_name.png" alt="植木琢哉" />
    </p>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default {

  methods: {
    scrollToHash () {
      const hash=this.$route.hash
      this.$nextTick(() => {
        this.$scrollTo(hash, 0, { offset: 10 })
      })
    },
    TopMessage() {
      gsap.fromTo('.js-m-fade01' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-m-fade01',  start: 'center 70%'}});
      gsap.fromTo('.js-m-fade02' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-m-fade02',  start: 'center 70%'}});
      gsap.fromTo('.js-m-fade03' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-m-fade03',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.TopMessage();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";

.message__title {
  width: 19.6875rem;
  min-height: 3rem;
  padding: 0 0.625rem;
  margin-bottom: 3.375rem;
  position: relative;
  z-index: 1;
  @include sp {
    width: 9.125rem;
    min-height: 1rem;
    margin-bottom: 2rem;
    padding: 0 0.3125rem;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.75rem;
    width: 100%;
    height: 1.4375rem;
    background-color: #F2AB34;
    z-index: 0;
    @include sp {
      height: 0.8125rem;
      bottom: -0.3125rem;
    }
  }
  img {
    position: relative;
    z-index: 1;
  }
}

.message__cover {
  width: 52.5rem;
  @include sp {
    width: 100%;
  }
  &-text {
    font-size: 1rem;
    line-height: 2;
    @include sp {
      font-size: 0.75rem;
    }
    span {
      display: block;
      margin-bottom: 1.5rem;
      @include sp {
        margin-bottom: 1.125rem;
      }
    }
  }
}

.message__ceo {
  width: 17.3125rem;
  min-height: 1.875rem;
  margin-left: auto;
  @include sp {
    width: 10.5625rem;
  }
}
</style>