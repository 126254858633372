<template>
  <v-btn
  outlined
  tile
  :disabled="disabled"
  :to="getLink"
  width="100%"
  class="font-bold text-base lg:text-xl h-[55px] md:h-20 tracking-tight 
        hover:before:opacity-0"
  :class="[`md:text-${getTxtSize}`,
          isArrow ? 'btnarrow' : 'btnbase',
          {'btnblack' : isBlack}]"
  >
  <span>{{getBtnName}}</span>
  </v-btn>
</template>
<script>
export default {
  props:{
    arrow:{
      type:Boolean
    },
    href:{
      type:String
    },
    txtsize:{
      type:String,
      default: 'xl'
    },
    txtcolor:{
      type:String,
      default: 'pforange-200'
    },
    bgcolor:{
      type:String,
      default: 'white'
    },
    txtwhite:{
      type:Boolean,
      default: false
    },
    btnname:{
      type:String,
      default:'詳しくはこちら'
    },
    link: {
      type:String,
      default:''
    },
    isBlack: {
      type:Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    }
  },
  data(){
    return {
      isArrow: this.arrow,
      getTitle: this.title,
      getTxtSize: this.txtsize,
      getTxtColor: this.txtcolor,
      getBgColor: this.bgcolor,
      isTxtWhite: this.txtwhite,
      getBtnName: this.btnname,
      getLink: this.link,

    }
  },  
}
</script>
<style scoped>
.btnbase {
  color: theme('colors.pforange.200');
  background-color: #fff;
  transition: all 0.5s ease-out;
}
.btnbase:hover {
  color: #fff;
  border-color: transparent;
  background-color: theme('colors.pforange.200');
}

.btnblack {
  color: theme('colors.pfblack.200');
}
.btnbase.btnblack:hover {
  background-color: theme('colors.pfblack.200');
}

.btnarrow {
  color: theme('colors.pforange.200');
  background-color: #fff;
}
.btnarrow  span {
  position: relative;
}
.btnarrow  span::after {
  content: "";
  opacity: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: url("~@/assets/img/btnarrow.png");
  background-size: 88px 9px;
  transition:  all 0.3s 0s ease-in-out;
  width: 88px;
  height: 9px;
  margin-left: 8px;
}
.btnarrow:hover span::after {
  animation: mvarrow 1.2s infinite;
}
@keyframes mvarrow {
  50% {
    /* right: .2em; */
    margin-left: 16px;
    opacity: 1;
  }
  100% {
    /* right: -.4em; */
    margin-left: 24px;
    opacity: 0;
  }
}
</style>