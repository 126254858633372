<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" class="relative">
    <select :id="name" :name="name" v-model="inputtedValue" @click="active = !active" @blur="active = false" 
    @keydown.prevent.down="moveDown" @keydown.prevent.up="moveUp" @keyup.enter="select"
    class="
    base text-base rounded-small py-[18px] px-4 px-md-6 leading-relaxed bg-white border-pfblack-100 focus:border-pforange-200
    z-20 relative
    ">
    <option v-for="(option,index) in options" :key="index" :value="option.value" class="hidden">{{option.text}}</option>
    </select>

    <transition>
    <ul v-show="active" class="rounded-small border border-pfblack-100 bg-white pl-0 pt-4
    absolute w-full top-[1.25rem] z-10
    ">
      <li v-for="(option,index) in options" :key="index" ref="li" @click="selectOption(option.text, index)" 
      :class="{change:currentIndex === index}" class="
      text-base py-[18px] px-4 px-md-6 leading-relaxed
      hover:bg-pforange-100 hover:bg-opacity-60 focus:bg-pforange-100 last:rounded-b-small cursor-pointer">
        {{option.text}}
      </li>
    </ul>
    </transition>
    <p v-show="errors.length" class="help text-pforange-200 pt-2 px-4 px-md-6 mb-0 text-left">
      {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>
<script>
export default {
    props:{
      name:{
        type:String
      },
      value: {
        type:String
      },
      options: {
        type: Array
      },
      rules: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        active: false,
        currentIndex : 0,
      }
    },
    methods: {
      moveDown() {
        if(!this.active) {
          this.active=true;
        }else if(this.currentIndex < this.$refs.li.length -1) {
          this.currentIndex = this.currentIndex +1;
        }
      },
      moveUp() { 
        if(!this.active) {
          this.active=true;
        }else if(this.currentIndex > 0){
          this.currentIndex = this.currentIndex - 1;  
        }
      },
      selectOption(newValue, index) {
        this.currentIndex = index
        this.$emit("input", newValue)
        this.active = false;
      },
      select (){
        let newValue = this.$refs.li[this.currentIndex].innerText;
        this.selectOption(newValue, this.currentIndex);
      }
    },
    computed:{
      inputtedValue:{
        get(){
          return this.value
        // },
        // 通常のoption要素によって選択させる場合は、このset処理が必要
        // （ul/liで擬似的にリスト実装したため、代わりにmethodsがemitを担ってる）
        // set(newValue){
          
        //   this.$emit("input", newValue)
        }
      }
    }
};
</script>
<style scoped>
/* .v-list-item {
  color:theme('colors.pforange.100') !important;
}
.v-select >>> .v-icon::before {
  content: '';
}
.v-select >>> .v-input__slot {
  border: 1px solid theme('colors.pfblack.100');
  border-radius: 10px;
  padding:18px 24px 18px 24px !important;
}
#app >>> .v-menu__content {
  border: 1px solid theme('colors.pfblack.100') !important;
  border-radius: 10px;
} */
.base {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent url("~@/assets/img/form-css-arrow.png") no-repeat center right 26px/18px 10px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  width: 100%;
}
.base::-ms-expand {
	display: none;
}
.base:focus {
	border: 1px solid rgba(0, 0, 0, 0.32);
	box-shadow: none;
	outline: none;
}
.change{
  background:theme('colors.pforange.100');
}
.v-enter-active, .v-leave-active {
    transition: all 0.3s
}

.v-enter {
    transform: translateY(-10px);
}

.v-enter, .v-leave-to {
    opacity: 0
}
</style>