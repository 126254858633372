<template>
  <div class="btn-trigger" id="btn01" @click="isActive = !isActive" :class="{ active:isActive }">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>
<script>
export default {
  props: {
    active: Boolean
  },
  data(){
    return {
      isActive: this.active
    }
  },
  watch: {
    active (newActive) {
      this.isActive = newActive;
    }
  }
}
</script>
<style scoped>
/*=============================
.btn-trigger
=============================*/
.btn-trigger {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.btn-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  /* border-radius: 4px; */
}
.btn-trigger, .btn-trigger span {
  display: inline-block;
  transition: all .5s;
  box-sizing: border-box;
}
.btn-trigger span:nth-of-type(1) {
  top: 0;
}
.btn-trigger span:nth-of-type(2) {
  top: 14px;
}
.btn-trigger span:nth-of-type(3) {
  bottom: 0;
}


/*=============================
#btn01
=============================*/
#btn01.active span:nth-of-type(1) {
  -webkit-transform: translateY(14px) rotate(-45deg);
  transform: translateY(14px) rotate(-45deg);
}
#btn01.active span:nth-of-type(2) {
  opacity: 0;
}
#btn01.active span:nth-of-type(3) {
  -webkit-transform: translateY(-14px) rotate(45deg);
  transform: translateY(-14px) rotate(45deg);
}
</style>