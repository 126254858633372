<template>
  <div class="pr-10 pr-md-0">
    <h3 class="mb-10 js-r-fade01">
      <span class="inline-block">ともに働く仲間を募集しています</span>
    </h3>

    <v-row>
      <v-col cols="12">
        <v-card
            flat
            class="rounded-small md:rounded-large js-r-fade02"
        >
          <v-container class="py-6  px-4 pa-md-8">
            <v-row
                v-for="item in items"
                :key="item.index"
                :cols="item.flex"
                class="-mx-4 -mt-2 md:-m-4 mb-6 last:mb-0 recruit__cover border-bottom"
            >
                <v-col cols="12" md="4" class="text-base md:text-xl lg:text-xl font-bold leading-[1.4] md:leading-loose px-4 py-1 py-md-6">
                  <span v-for="(title, index) in item.title" :key="index" class="inline-block">{{ title }}</span>
                </v-col>
                <v-col cols="12" md="8" class="text-xs md:text-base px-4 py-1 py-md-6">
                  <div class="mb-0 recruit__text">
                  <p v-for="(desc, index) in item.desc" :key="index" class="inline-block" v-html='desc'></p>
                  </div>
                </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

      <div class="btn-cover js-r-fade03">
        <router-link  v-scroll-to="'/partners#recruit'" to="/partners#recruit" @click.native="reset()">ビジネスパートナーを<br>ご希望の方はこちらから</router-link>
        <router-link to="/contact">中途採用の<br>ご応募はこちらから</router-link>
      </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
    data(){
        return {
            items: [
                { title: ['募集','職種'], desc: '経理、会計業務/コンサルティング業務', flex: 12 },
                { title: ['雇用形態'], desc: '正社員', flex: 12 },
                { title: ['職務内容'], desc: ['経理・会計の入力業務、財務分析<br>経営課題に対するコンサルティング業務<br>※必ず上位者と一緒に取組むため、経験が浅い方もご安心ください。',], flex: 12 },
                { title: ['給与'], desc: '月給：20万8000円～25万円', flex: 12 },
                { title: ['勤務時間'], desc: '8:50～17:50', flex: 12 },
                { title: ['休日'], desc: '完全週休2日制 (土日),祝', flex: 12 },
                { title: ['求める','人材'], desc: ['経理・会計の経験がある方(労務経験もあれば尚良)<span>求める人物像</span>・人間関係を大切にする方<br>・指示を待つのではなく、自ら考え行動する方<br>・成長意欲がある方',] , flex: 12 },
            ],
        }
    },

  methods: {
      reset: function () {
      const urlHash = location.hash;
      if(urlHash){
        const hash=this.$route.hash
        this.$scrollTo(hash, 0, { offset: -50 })
        console.log('hash-active')
        return
      } else {
        this.$router.go({path: this.$router.currentRoute.path, force: true})
      }
    },
    TopRecruit() {
      gsap.fromTo('.js-r-fade01' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-r-fade01',  start: 'center 70%'}});
      gsap.fromTo('.js-r-fade02' , 0.5 ,{opacity:0 ,},{opacity:1, scrollTrigger: {  trigger: '.js-r-fade02',  start: 'top 70%'}});
      gsap.fromTo('.js-r-fade03' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-r-fade03',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.TopRecruit();
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";

.border-bottom {
  position: relative;
  &:last-of-type {
    &::before {
      display: none;
    }
  }
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    width: 96%;
    height: 1px;
    background-color: #F1EEE7;
  }
}


.recruit__cover {

  &:last-of-type {
    .recruit__text {
      ::v-deep {
      span {
        font-weight: bold;
        display: block;
      }
      }
    }
  }
}

.btn-cover {
  max-width: 43.75rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 2.5rem auto 0 auto;
  @include sp {
    max-width: 15.4375rem;
    display: block;
    margin: 1.5rem auto 0 auto;
  }
  a {
    display: block;
    width: 21rem;
    font-size: 1.25rem;
    font-weight: bold;
    color:#E56910;
    text-align: center;
    background-color:  #fff;
    box-shadow:0 0 0 0.0625rem #E56910 inset;
    padding: 1.375rem 0;
    transition: all .5s ease-out;
    @include sp {
      width: 100%;
      font-size: 1rem;
      padding: 0.5625rem 0;
      margin-bottom: 2.0625rem;
    }
    &:nth-of-type(1) {
      &:hover {
        color:#fff;
        background-color:  #E56910;
        transition: all .5s ease-out;
      }
    }
    &:nth-of-type(2) {
      color: #fff;
      background-color: #E56910;
      &:hover {
        background-color:#e08747;
        transition: all .5s ease-out;
      }
      @include sp {
        margin-bottom: 0;
      }
    }
  }
}
</style>