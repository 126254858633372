<template>
  <div>
      <v-row>
        <v-col cols="12" class="">
          <v-row class="">
            <v-col cols="12" class="mb-0 mb-md-4 pb-2">
              <div class="pr-10 pr-md-0">
                <div class="max-w-[678px] mx-auto bg-white border-pforange-100 border-[3px] border-solid p-4 px-md-10 py-md-8 leading-[1.6]">
                  <p class="text-center mb-2 mb-md-4">
                    <span class="md:inline-block">お電話でのお問い合わせも受け付けております。</span>
                    <span class="md:inline-block">お気軽にお問い合わせください。</span>
                  </p>
                  <p class="font-bold leading-[1.6] text-center mb-2 mb-md-4">
                    <span class="mr-2 mr-md-4 text-xs md:text-2xl">TEL.</span>
                    <span class="text-base md:text-[2rem]">{{tel}}</span>
                  </p>
                  <p class="mb-0 text-center ">
                    <span class="inline-block">※受付時間：{{ businesshours[0]}}</span>
                    <span class="inline-block">（土日祝はWEBよりお問い合わせください）</span>
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="pt-2">
              <div class="pr-10 pr-md-0">
                <p class="mb-0">
                  WEBからのお問い合わせ・お見積のご依頼は下記入力フォームに必要事項をご記入のうえ、『送信内容を確認する』ボタンをクリックしてください。1営業日以内にご返信いたします。
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="text-center wide -my-3">
          <validation-observer v-slot="{ invalid }">
            <form action="#" method="POST" @submit.prevent="$router.push('/contact/confirm')">
              <dl class="mb-8 ">
                <div v-for="(input,index) in inputs" :key="index" class="flex flex-col md:flex-row w-full py-2 py-md-3">
                  <dt class="min-w-[208px] lg:min-w-[240px] text-left">
                    <FormTitle :required="input.required" class="mb-2 mb-md-0 md:py-[18px]">{{input.title}}</FormTitle>
                  </dt>
                  <dd class="flex-auto w-full text-left">
                  <component :is="input.component" :type="input.type" :name="input.name" :field="input.title" :placeholder="input.placeholder" :options="input.options" v-model="input.value" :required="input.required" :rules="input.rules"/>
                  </dd>
                </div>
              </dl>
              <div class="px-10 px-md-0">
                <ButtonService btnname="送信内容を確認する" :disabled="invalid" arrow type="submit" class="px-3 px-md-7 max-w-[336px] md:text-xl mx-auto" />
              </div>
            </form>
          </validation-observer>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import ButtonService from '@/components/modules/ButtonService';
import FormTitle from '@/components/modules/FormTitle';
import FormInput from '@/components/modules/FormInput';
import FormRadio from '@/components/modules/FormRadio';
import FormSelect from '@/components/modules/FormSelect';
import FormTextarea from '@/components/modules/FormTextarea';
import ContactConfirm from '@/components/organisms/contact/ContactConfirm';
import ContactComplete from '@/components/organisms/contact/ContactComplete';
import { TEL, BUSINESSHOURS } from '@/common/constants';

export default {
  components:{
    ButtonService,
    FormTitle,
    FormInput,
    FormRadio,
    FormSelect,
    FormTextarea,
    ContactConfirm,
    ContactComplete,
  },
  props:{
    inputs:{
      type:Array
    }
  },
  data(){
    return {
      tel: TEL,
      businesshours: BUSINESSHOURS,
    }
  }
}
</script>
<style scoped>
.wide {
  max-width: calc(100% + 40px);
  width: calc(100% + 40px);
  flex: 1 0 100%;
  margin-left: -40px;
}
@screen md {
.wide {
  max-width: 100%;
  width: 100%;
  flex: 0 0 100%;
  margin-left: 0;
}
}
</style>