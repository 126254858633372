<template>
  <div id="webproduct">
    <Breadcrumb />
    <PageNav />
    <Hero />
    <CV />
    <FourServices />
    <Case />
    <Feature />
    <Works />
    <CV />
    <Faq />
  </div>
</template>


<script>
import Breadcrumb from "@/components/organisms/web-product/Breadcrumb.vue";
import PageNav from "@/components/organisms/web-product/PageNav.vue";
import Hero from "@/components/organisms/web-product/Hero.vue";
import FourServices from "@/components/organisms/web-product/FourServices.vue";
import Case from "@/components/organisms/web-product/Case.vue";
import Feature from "@/components/organisms/web-product/Feature.vue";
import CV from "@/components/organisms/web-product/CVArea.vue";
import Works from "@/components/organisms/web-product/Works.vue";
import Faq from "@/components/organisms/web-product/Faq.vue";

export default {
  components: {
    Breadcrumb,
    PageNav,
    Hero,
    FourServices,
    Case,
    Feature,
    CV,
    Works,
    Faq,
  },
  mounted() {
    this.$nextTick(() => {
      this.$noScroll();
      setTimeout(this.$returnScroll, 800);
    });
  },
  watch: {
    hogeList: function () {
      this.$nextTick(() => {
        var positionY = sessionStorage.getItem("positionY");
        scrollTo(0, positionY);
        setTimeout(function () {
          scrollTo(0, positionY);
        }, 500);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
</style>