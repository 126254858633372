<template>
  <div class="pr-10 pr-md-0">
      <h3 class="mb-2 mb-md-4 js-top-c-fade">
        <span class="d-inline-block">業務を効率化し、</span>
        <span class="d-inline-block">売上拡大をサポート。</span>
      </h3>
      <p class="text mb-10 js-top-c-fade02">
        <span class="inline-block">当社のコンセプトはITの活用と経理サポートでお客様の日々の業務を効率化し、成長に貢献することです。</span>
        <span class="inline-block">3つのサービスの掛け合わせで「利益率向上」の好循環を作り出します。</span>
      </p>
      <v-row justify="center" class="-mx-7 -my-9">
        <v-col
          v-for="(card, index) in cards"
          :key="card.id"
          cols="12"
          sm="7"
          md="4"
          class="px-7 py-9 js-top-c-fade03"
        >
          <v-card
            color="transparent"
            class="relative"
            
            flat>
              <div class="md:relative">
                <v-img
                  :aspect-ratio="1/1"
                  :src="card.src"
                  class="mx-auto align-end"
                  max-width="247"
                >
                  <v-card-title v-text="card.title" class="justify-center font-bold pb-10"></v-card-title>
                </v-img>
                <div v-if="index > 0" class="absolute -top-12 md:top-1/2 transform  translate-y-0 md:-translate-y-1/2 -translate-x-1/2 md:translate-x-0  left-1/2 md:-left-11">
                  <!-- <IconClose/> -->
                  <v-img  src="@/assets/img/iconClose.svg" width="34px" alt="cross" />
                </div>
              </div>
            <v-card-text class="px-0 pb-0 text-center text-pfblack-100 md:text-left text-xs md:text-base leading-[1.8] md:leading-8">
              <span v-for="(desc, key) in card.desc" :key="key" class="inline-block">{{ desc }}</span>
                
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="1" md="9"  class="hidden md:block pt-0 pb-8">
            <img  src="@/assets/img/arrow_large.svg" alt="arrow" class="mx-auto" />
        </v-col>
      </v-row>

      <v-row justify="center js-top-c-fade04 min-height">
        <v-col cols="12" justify="center" class="block md:hidden">
            <img  src="@/assets/img/arrow_small.svg" alt="arrow" width="12" class="mx-auto" />
        </v-col>  
        <v-col>
          <p class="text-center text-2xl font-bold mb-2 md:text-8 md:mb-4">利益率向上</p>
          <p class="text-center text-2xl font-bold mb-5 concept__up-text">売上拡大のための仕掛けづくりへ</p>
          <div class="concept__up-cover" v-for='content in contents' :key='content.id'>
            <div class="img-box mb-4">
              <img :src='content.img' alt="">
              <p class="img-box__text font-bold">{{ content.title }}</p>
            </div>
            <div class="text-box">
              <span class="inline-block" v-for="(desc, key) in content.desc" :key="key" v-html='desc'></span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
export default {

  data(){
    return {
      cards: [
        { id:1, title: 'IT活用', src: require('@/assets/img/concept_it.png'), desc: ['お客様一人','ひとりに合わせた','ソフトウェアや','システムの開発・','ミドルウェアの','導入支援']},
        { id:2, title: '経理改革', src: require('@/assets/img/concept_account.png'), desc: ['お客様の経営数字を','リアルタイム','に視覚化し、','経営スピードの向上','と経費をスリム化']},
        { id:3, title: '情熱', src: require('@/assets/img/concept_passion.png'), desc: ['当社はお客様と','真剣に向き合い、','お客様が抱える','課題の解決方法','を徹底的に考えます']},
      ],
      contents : [
        {id:1,img: require('@/assets/img/concept_system.png'),title:'システム導入や\n広告宣伝',desc:['削減できたコストや上がった売上をもとに、<br>さらに強い企業にするための仕掛けとして、<br>新しいシステムの導入やホームページ、<br class="sp">ECサイトの制作など<br class="sp">お客様に合ったプランのご提案が可能です。']}
      ]
    }
  },
  methods: {
    TopConcept() {
      gsap.fromTo('.js-top-c-fade' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1,y: 0,scrollTrigger: {  trigger: '.js-top-c-fade',  start: 'center 70%'}});
      gsap.fromTo('.js-top-c-fade02' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1,y: 0,scrollTrigger: {  trigger: '.js-top-c-fade02',  start: 'center 70%'}});
      gsap.fromTo('.js-top-c-fade03' ,  0.5 , {opacity:0 ,y: 20,},{opacity:1,y: 0,scrollTrigger: {  trigger: '.js-top-c-fade03',  start: 'top 60%',  stagger: {    from : 'start',    amount : 0.5,  }}});
      gsap.fromTo('.js-top-c-fade04' , 0.5 , {opacity:0 ,y: 20,},{opacity:1,y: 0,scrollTrigger: {  trigger: '.js-top-c-fade04',  start: 'top 60%',  stagger: {    from : 'start',    amount : 0.5,  }}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.TopConcept();
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";

.min-height {
  min-height: 31.25rem;
}
.concept__up-cover {
  width: 25.375rem;
  margin: 0 auto;
  text-align: center;
  @include sp {
    width: 100%;
  }
}

.concept__up-text {
  @include sp {
    font-size: 1rem!important;
  }
}

.img-box {
  position: relative;
  img {
    width: 15rem;
    margin: 0 auto;
  }
  &__text {
    font-size: 1.125rem;
    line-height: 1.4;
    width: 7.875rem;
    position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text-box {
  line-height: 2;
  @include sp {
    font-size: 0.75rem;
  }
}

</style>