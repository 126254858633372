<template>
  <v-list flat class="py-0">
    <v-list-item-group class="mt-2">
      <v-list-item
        v-for="item in getItems"
        :key="item.index"
        :to="item.to"
        v-scroll-to="{el:item.scrollto, offset:offset}"
        class="px-16 pl-lg-6 pr-lg-3 tracking-tight min-h-5"
        @click="reset(),closeDrawer"
      >
      <v-list-item-content class="py-2 group " :class="{'pt-0': item.sub}">
        <!-- メインメニューの場合 -->
        <v-list-item-title v-if="!item.sub" class="text-uppercase text-white lg:text-pfblack-100 font-bold leading-relaxed 
        transition duration-300 ease-in-out group-hover:text-pfblack-100 lg:group-hover:text-pforange-200">
          {{ item.title }}
        </v-list-item-title>
        <!-- サブメニューの場合 -->
        <v-list-item-title v-if="item.sub" class="
          text-uppercase text-white lg:text-pfblack-100 font-bold leading-relaxed py-0 ml-2 mb-0 text-xs
          relative before:bg-white lg:before:bg-pfblack-100 before:w-3 before:h-[2px] before:inline-block before:top-2/4 before:transform before:-translate-y-2/4 before:align-middle before:mr-1
          transition duration-300 ease-in-out group-hover:text-pfblack-100 lg:group-hover:text-pforange-200
          ">
          {{ item.title }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-white lg:text-pfblack-100 text-xs leading-[1.58333]
        transition duration-300 ease-in-out group-hover:text-pfblack-100 lg:group-hover:text-pforange-200">{{ item.subtitle }}</v-list-item-subtitle>
      </v-list-item-content>  
      </v-list-item>
    </v-list-item-group>

    <v-list-item-group>
      <!-- ボタン -->
          <v-list-item class="px-16 px-lg-6">

              <v-btn class="bg-white lg:bg-pforange-200 mt-4 text-pforange-200 lg:text-white text-center px2"
                elevation="2"
                tile
                v-for="button in getButtons"
                :key="button.index"
                :to="button.to"
                width="152px"
                height="50px"
              >
                <span class="font-bold">{{ button.title }}</span>
                <span>{{ button.subtitle }}</span>
              </v-btn>
          </v-list-item> 
    </v-list-item-group>
  </v-list>
</template>
<script>
  import { BREAKPOINT } from '@/common/constants';
  export default {
    components: {
    },
    props: {
      items: Array,
      buttons: Array,
      sub: Boolean
    },
    data(){
      return {
        getItems: this.items,
        getButtons: this.buttons,
        winWidth: 0,
        offset:0
      }
    },
    mounted() {
      window.addEventListener('resize', this.calculateWindowWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.calculateWindowWidth);
    },
    methods: {
      closeDrawer() {
        this.$emit('drawer' , null)
      },
      calculateWindowWidth() {
        this.winWidth = window.innerWidth;
        if (this.winWidth <=BREAKPOINT) {
          this.offset = -55
        } else {
          this.offset = 0;
        }
      },
      reset() {
      const urlHash = location.hash;
      if(urlHash){
        const hash=this.$route.hash
        this.$scrollTo(hash, 0, { offset: -50 })
        console.log('hash-active')
        return
      } else {
        this.$router.go({path: this.$router.currentRoute.path, force: false})
      }
    }
  },
}
</script>
<style scoped>
.v-btn >>> .v-btn__content {
  flex-direction: column;
  text-align: center;
}

.v-list-item--active {
  /* pointer-events: none; */
}

/* .v-list-item--active .v-list-item__title {
  color: #E56910!important;
}

.v-list-item--active .v-list-item__subtitle {
  color: #E56910!important;
} */
</style>
