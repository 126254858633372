<template>
  <div class="pr-10 pr-md-0">
    <v-row>
      <v-col cols="12" class="mb-0 mb-md-4 pb-2">
        <dl class="mb-8">
          <div v-for="(input,index) in getInputs" :key="index" class="flex flex-col md:flex-row w-full py-2 py-md-3">
            <dt class="min-w-[208px] lg:min-w-[240px] text-left">
              <FormTitle :required="input.required" class="mb-2 mb-md-0 md:py-[18px]">{{input.title}}</FormTitle>
            </dt>
            <dd class="flex-auto w-full text-left md:py-[18px]">
              {{input.value}}
            </dd>
          </div>
        </dl>
        <v-row>
          <v-col cols="6">
            <ButtonService btnname="戻る" type="submit" isBlack @click.native="$router.back()" class="px-3 px-md-7 max-w-[336px] md:text-xl mr-6" />
          </v-col>
          <v-col cols="6">
            <ButtonService btnname="送信" ftype="submit" @click.native="send()" class="px-3 px-md-7 max-w-[336px] md:text-xl mx-auto" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ButtonService from '@/components/modules/ButtonService';
import FormTitle from '@/components/modules/FormTitle';
import axios from 'axios'

const URL = `${process.env.VUE_APP_API_URL_BASE}/wp-json/contact-form-7/v1/contact-forms/` +
                  `${process.env.VUE_APP_API_FORM_ID}/feedback/`;
const CONFIG = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }

export default {
  components:{
    ButtonService,
    FormTitle,
  },
  props:{
    inputs:{
      type:Array
    },
  },
  data(){
    return {
      getInputs: this.inputs,
    }
  },
  methods: {
    send: async function() {
      const params= this.inputs.reduce((result, c) => {
        result[c.name] = c.value
        return result
      },{});
      const qs = require('querystring');
      return await axios
        .post(URL, qs.stringify(params), CONFIG)
        .then((res) => {
          if (res.data.status !== 'mail_sent') {
            this.$router.push('/contact/failure')
          } else {
            this.$router.push('/contact/complete')
          }
        })
        .catch(() => {
          this.$router.push('/contact/failure')
      });
    }
  }
}
</script>