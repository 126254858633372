<template>
  <section id="works" class="works">
    <div class="works__inner">

      <div class="r-box">
        <h2 class="js-w-fade01">実績とパートナーの<br class="sp">紹介</h2>
        <p class="r-box__text js-w-fade02">
          経理代行、ソフトウェア受託開発、ホームページ制作それぞれの事業における
          ビジネスパートナーと実績の一部をご紹介いたします。
        </p>

        <div class="r-box__flex">
          <div class="item" v-for="item in items" :key="item.id" :class='"js-w-trigger" + item.id'>
            <div class="flex-name text-center">
              <span>{{ item.Name01 }}</span>
              ×
              <span>{{ item.Name02 }}</span>
            </div>
            <div
              class="img"
              :style="{ 'background-image': 'url(' + item.Img + ')' }"
            ></div>
            <p class="m-title">{{ item.Title }}</p>
            <p class="sub-title">{{ item.SubTitle01 }}</p>
            <p class="text" v-html="item.Text01"></p>
            <p class="sub-title">{{ item.SubTitle02 }}</p>
            <p class="text" v-html="item.Text02"></p>
          </div>
        </div>
      </div>
    </div>
        <v-col cols="12" md="12" class="sp-width text-center js-w-fade03">
          <ButtonService  @click.native="reset()" btnname="パートナーと実績を詳しく見る" link="/partners" arrow class="px-3 px-md-7 max-w-[21rem] md:text-xl mx-auto"/>
        </v-col>
  </section>
</template>

<script>
import ButtonService from "@/components/modules/ButtonService";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';



export default {
  components: {
    ButtonService,
  },
  data() {
    return {
      items: [
        {
          id : '1',
          Name01 : 'SSTECH',
          Name02 : 'Profitbnk',
          Img: require("@/assets/img/partners/kaisen-m.jpg"),
          Title: "海鮮居酒屋 M社",
          SubTitle01: "新規設備の導入",
          Text01:
            "返済不要の補助金を活用し、テイクアウト用の設備と食洗機を新規で導入しました。補助金等に関するお手続きはプロフィットバンクで行い、設備導入は（株）SSTECHにて行いました。",
          SubTitle02: "売上げアップと業務の効率化を実現！",
          Text02:
            "設備の導入で売上げがアップし、食洗機を導入したことで業務効率が上がりました。 返済不要の補助金を活用できたことで、設備導入における自己負担が大きく抑えられました。",
        },
        {
          id : '2',
          Name01 : 'Hamorebi',
          Name02 : 'Profitbnk',
          Img: require("@/assets/img/partners/syokuhin-s.jpg"),
          Title: "食品卸売業 S社",
          SubTitle01: "野菜、食料品のECサイトを開設",
          Text01:
            "返済不要の補助金を活用し、野菜などの食料品をインターネットで購入できるECサイトを制作しました。補助金等に関するお手続きはプロフィットバンクで行い、サイト制作はHamorebiにて行いました。",
          SubTitle02: "売上げアップと販路の拡大につながった！",
          Text02:
            "ECサイトで新規顧客の獲得につながり売り上げがアップ。新しい販路を増やし新たなサービスの拡充を行いました。返済不要の補助金を活用できたことで、サイト制作費の自己負担が大きく抑えられました。",
        },
      ],
    };
  },
  methods: {
      reset: function () {
        this.$router.go({path: this.$router.currentRoute.path, force: true})
      },


    TopWorks() {
      gsap.fromTo('.js-w-trigger1 .img' , 0.9 ,{backgroundSize:'200%' },{backgroundSize : '105%',scrollTrigger: {  trigger: '.js-w-trigger1 .img',  start: 'center 70%'}});
      gsap.fromTo('.js-w-trigger2 .img' , 0.9 ,{backgroundSize:'200%' },{backgroundSize : '105%',scrollTrigger: {  trigger: '.js-w-trigger2 .img',  start: 'center 70%'}});
      gsap.fromTo('.js-w-fade01' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-w-fade01',  start: 'center 70%'}});
      gsap.fromTo('.js-w-fade02' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-w-fade02',  start: 'center 70%'}});
      gsap.fromTo('.js-w-fade03' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-w-fade03',  start: 'top 70%'}});
      gsap.fromTo('.js-w-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-w-trigger1',  start: 'center 70%'}});
      gsap.fromTo('.js-w-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-w-trigger2',  start: 'center 70%'}});

    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.TopWorks();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.works {
  padding: 0 0 1rem 0;
  @include sp {
    padding: 0 0 1.5rem 0;
  }
  h2 {
    width: fit-content;
    font-weight: bold;
    font-size: 2.5rem;
    margin: 0.1rem 0 2rem 0;
    @include sp {
      font-size: 1.5rem;
      margin: 0 0 1rem 0;
    }
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      max-width: 100%;
      display: block;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    @include sp {
      width: 100%;
      display: block;
      padding: 0 2.5rem 0 0;
    }
    @include mini {
      width: 12.5rem;
    }

    &__text {
      font-size: 1rem;
      margin-bottom: 2.5rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        margin-bottom: 1.5rem;
      }
    }

    &__title {
      font-size: 3.75rem;
      font-family: "Noto Sans JP", sans-serif;
      text-align: center;
      margin-bottom: 3.3125rem;
      @include sp {
        width: fit-content;
        font-size: 1.25rem;
        line-height: 1;
        margin-bottom: 1.5625rem;
        padding-left: 20%;
      }
      .color-orange {
        font-size: 4.5rem;
        font-weight: bold;
        color: #e56910;
        @include sp {
          display: block;
          font-size: 2rem;
        }
      }
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.0625rem #cccccc solid;
      padding-bottom: 0.5rem;
      @include sp {
        display: block;
        width: 100%;
        margin: 0 0 1.5rem 0;
      }
      &:last-of-type {
        border-bottom: none;
        @include sp {
          margin-bottom: 0;
        }
      }
      &:first-of-type {
        .item:first-of-type {
          @include sp {
            padding-bottom: 0.5rem;
          }
        }
      }
    }

    .item {
      width: 25rem;
      height: auto;
      @include sp {
        width: 100%;
      }
      @include mini {
        width: 12.5rem;
      }

      .flex-name {
        font-size: 2rem;
        margin-bottom: 1rem;
        @include sp {
          font-size: 1.25rem;
          margin-bottom: 0.5625rem;
        }
        span {
          font-size: 2rem;
          font-weight: bold;
        @include sp {
          font-size: 1.25rem;
        }
        }
      }

      .img {
        width: 100%;
        height: 18.25rem;
        background-color: #fff;
        background-size: cover;
        background-position: center;
        border-radius: 1.25rem;
        margin-bottom: 1.4375rem;
        @include sp {
          height: auto;
          padding-top: 75%;
          border-radius: 0.5rem;
          margin-bottom: 0.5625rem;
        }
      }
      .m-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        @include sp {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
      }
      .sub-title {
        font-size: 1rem;
        color: #e56910;
        margin-bottom: 0.25rem;
        @include sp {
          font-size: 0.75rem;
        }
      }
      .text {
        font-size: 1rem;
        line-height: 200%;
        margin-bottom: 1.5rem;
        @include sp {
          font-size: 0.75rem;
          line-height: 180%;
          margin-bottom: 1rem;
        }
      }
    }

  }
}

.sp-width {
  @include sp {
    width: 100%;
    padding: 0 3rem 0 0;
  }
}

@keyframes mvarrow {
  50% {
    /* right: .2em; */
    margin-left: 1rem;
    opacity: 1;
  }
  100% {
    /* right: -.4em; */
    margin-left: 1.5rem;
    opacity: 0;
  }
}
</style>