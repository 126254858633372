<template>
<div>
  <table class="table-fixed w-full">
    <tr
      v-for="item in items"
      :key="item.index" 
      class=""
    >
      <th class="w-[80px] md:w-[182px] p-4 md:py-8 md:px-[55px] align-baseline">
        <p class="mb-0 leading-loose text-left">{{item.title}}</p>
        </th>
      <td class="p-4 md:py-8 md:px-[55px]">
          <p v-for="(desc, index) in item.desc" :key="index" class="mb-0 leading-relaxedmd:leading-loose md:tracking-widest">{{ desc }}</p>
      </td>
    </tr>
  </table>
</div>
</template>
<script>
import { TEL, ZIP, ADDRESS } from '@/common/constants';
export default {
  data(){
    return {
      items: [
        { title: '会社名', desc: ['株式会社プロフィットバンク'], flex: 12 },
        { title: '所在地', desc: [ZIP,`${ADDRESS[0]} ${ADDRESS[1]}`], flex: 12 },
        { title: '連絡先', desc: [TEL], flex: 12 },
        { title: '取締役', desc: ['植木 琢哉'], flex: 12 },
        { title: '事業内容', 
          desc: [
            '・経理代行事業',
            '・ホームページ制作事業',
            '・ソフトウェア受託開発事業',
          ], 
            flex: 12 
        },
      ],
    }
  }
}
</script>
<style scoped>
table {
  border: 2px solid #1A1A1A;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 20px;
}
table th,
table td {
  border-bottom: 2px solid #1A1A1A;
}
table th {
  background-color: theme('colors.pforange.100');
}
table td {
  background-color: #fff;
  border-left: 2px solid #1A1A1A;
}
table tr:last-child th,
table tr:last-child td {
  border-bottom: none;
}
table tr:first-child th:first-child {
  border-radius: 20px 0 0 0;
}
table tr:first-child td:last-child {
  border-radius: 0 20px 0 0;
}
table tr:last-child th:first-child {
  border-radius: 0 0 0 20px;
}
table tr:last-child td:last-child {
  border-radius: 0 0 20px 0;
}
</style>