import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Access from '@/views/Access.vue'
import Company from '@/views/Company.vue'
import Contact from '@/views/Contact.vue'
import ContactForm from '@/components/organisms/contact/ContactForm.vue'
import ContactConfirm from '@/components/organisms/contact/ContactConfirm.vue'
import ContactComplete from '@/components/organisms/contact/ContactComplete.vue'
import ContactFailure from '@/components/organisms/contact/ContactFailure.vue'
import NotFound from '@/views/NotFound.vue'
import Privacy from '@/views/Privacy.vue'
import WebProduct from '@/views/WebProduct.vue'
import Development from '@/views/Development.vue'
import Accounting from '@/views/Accounting.vue'
import Partners from '@/views/Partners.vue'

Vue.use(Router)
 
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: '株式会社プロフィットバンク',
        desc: '大阪府淀川・新大阪・西中島を中心に、IT×経理で業務の効率化・経費削減・売上拡大のサポートをしております。'
      }
    },
    {
      path: '/access',
      name: 'access',
      component: Access,
      meta: {
        title: 'アクセス',
        desc: '',
        breadcrumb: [
          { text:'トップページ', to:'/'},
          { text:'アクセス' },
        ]
      }
    },
    {
      path: '/company',
      name: 'company',
      component: Company,
      meta: {
        title: '会社概要',
        desc: '',
        breadcrumb: [
          { text:'トップページ', to:'/' },
          { text:'会社概要' },
        ]
      }
    },
    {
      path: '/contact',
      component: Contact,
      children: [
        {
          path: '',
          component: ContactForm,
          meta: {
            title: 'お問い合わせ',
            desc: '',
            breadcrumb: [
              { text:'トップページ', to:'/' },
              { text:'お問い合わせ', disabled: true },
            ]
          },
        },
        {
          path: 'confirm',
          component: ContactConfirm,
          beforeEnter: (to, from, next) => {
            if (from.path === '/contact') {
              next();
            } else {
              next({path: '/contact'});
            }
          },
          meta: {
            title: 'お問い合わせ',
            desc: '',
            breadcrumb: [
              { text:'トップページ', to:'/' },
              { text:'お問い合わせ', disabled: true },
            ]
          },
        },
        {
          path: 'complete',
          component: ContactComplete,
          beforeEnter: (to, from, next) => {
            if (from.path === '/contact/confirm') {
              next();
            } else {
              next({path: '/contact'});
            }
          },
          meta: {
            title: 'お問い合わせ',
            desc: '',
            breadcrumb: [
              { text:'トップページ', to:'/' },
              { text:'お問い合わせ', disabled: true },
            ]
          },
        },
        {
          path: 'failure',
          component: ContactFailure,
          beforeEnter: (to, from, next) => {
            if (from.path === '/contact/confirm') {
              next();
            } else {
              next({path: '/contact'});
            }
          },
          meta: {
            title: 'お問い合わせ',
            desc: '',
            breadcrumb: [
              { text:'トップページ', to:'/' },
              { text:'お問い合わせ', disabled: true },
            ]
          },
        },
      ]
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
      meta: {
        title: '個人情報保護方針',
        desc: '',
        breadcrumb: [
          { text:'トップページ', to:'/' },
          { text:'個人情報保護方針', disabled: true },
        ]
      }
    },
    {
      path: '/WebProduct',
      name: 'webproduct',
      component: WebProduct,
      meta: {
        title: 'ホームページ制作',
        desc: '',
        breadcrumb: [
          { text:'トップページ', to:'/' },
          { text:'ホームページ制作', disabled: true },
        ]
      }
    },
    {
      path: '/development',
      name: 'development',
      component: Development,
      meta: {
        title: 'ソフトウェア受託開発',
        desc: '',
        breadcrumb: [
          { text:'トップページ', to:'/' },
          { text:'ソフトウェア受託開発', disabled: true },
        ]
      }
    },
    {
      path: '/accounting',
      name: 'accounting',
      component: Accounting,
      meta: {
        title: '経理代行',
        desc: '',
        breadcrumb: [
          { text:'トップページ', to:'/' },
          { text:'経理代行', disabled: true },
        ]
      }
    },
    {
      path: '/partners',
      name: 'partners',
      component: Partners,
      meta: {
        title: '実績とビジネスパートナー',
        desc: '',
        breadcrumb: [
          { text:'トップページ', to:'/' },
          { text:'実績とビジネスパートナー', disabled: true },
        ]
      }
    },
    {
      path:'*',
      // redirect:'/'
      name:'NotFound',
      component: NotFound,
      meta: {
        title: 'ページが見つかりませんでした',
        desc: '',
      }
    }
  ]
})