
export const BREAKPOINT = 1263;
export const TEL = '06-6195-1860'
export const ZIP = '〒532-0011'
export const ADDRESS = ['大阪府大阪市淀川区西中島5-7-11','第8新大阪ビル5階']
export const BUSINESSHOURS = ['平日 9:00-17:00','（土日祝はお問い合わせフォームよりお願いいたします）']
// export default {
//   tel: '06-6195-1860',
//   accress: ['大阪府大阪市淀川区西中島5丁目7番11号',''], 
//   nav_lists:[
//     {
//       name: 'CONCEPT',
//       sub_name:'コンセプト',
//       url: '/'
//     },
//     {
//       name: 'MISSION',
//       sub_name:'ミッション',
//       url: '/' 
//     },
//     {
//       name: 'SERVICE',
//       sub_name:'サービス内容',
//       url: '/',
//       lists:[{
//             name:'-ソフトウェア受託開発',
//             url:'#'
//         },
//         {
//             name:'-経理代行',
//             url:'#'
//         },
//         {
//             name:'-ITコンサルティング',
//             url:'#'
//         }
//         ]
//     },
//     {
//       name: 'WORKS',
//       sub_name:'事例一覧',
//       url: '/',
//     },
//     {
//       name: 'MESSAGE',
//       sub_name:'代表者メッセージ',
//       url: '/'
//     },
//     {
//       name: 'RECRUIT',
//       sub_name:'中途採用・パートナー募集',
//       url: '/'
//     },
//     {
//       name: 'ACCESS',
//       sub_name:'アクセス',
//       url: '/access'
//     },
//     {
//       name: 'COMPANY',
//       sub_name:'会社概要',
//       url: '/company'
//     },
//   ]
// }