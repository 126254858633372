<template>
    <section id="recruit" class="recruit">
      <div class="recruit__inner js-recruit-main">
          <SectionTitle title="<span>R</span><span>e</span><span>c</span><span>r</span><span>u</span><span>i</span><span>t</span>"/>
          <div class="r-box">
            <h3 class="js-recruit-fade01">ビジネスパートナーを<br class="sp">募集しています</h3>
            <p class="r-box__text js-recruit-fade02">
              当社の事業に一緒に参画いただけるビジネス<br class="sp">パートナーを募集しています。<br>
              協業いただける方はお問い合わせページより<br class="sp">ご連絡ください。
            </p>
            <div class="recruit__contents" v-for='item in items' :key='item.Id' :class='"js-recruit-trigger" + item.id'>
              <p class="m-title" v-html='item.Title'></p>
              <div class="flex-box">
                <img :src='item.Img' class="pc">
                <p v-html='item.Text' class="text"></p>
                <img :src='item.Img' class="sp">
              </div>
            </div>
          <div class="js-recruit-fade03">
            <ButtonService btnname="お問い合わせ・ご応募はこちらから" link="/contact" arrow class="mx-400 ml-auto mr-auto block py-6 bg-orange text-white sp-style"/>
          </div>
          </div>
      </div>
    </section>
</template>

<script>
import gsap  from 'gsap';
import ScrollTrigger  from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";
import ButtonService from "@/components/modules/ButtonService";

export default {
  components: {
    SectionTitle,
    ButtonService
  },
  data() {
    return {
      items : [
        {
          id : '1',
          Title : '営業パートナー',
          Text : '当社サービスを一緒に営業してくださる方<br>スタートアップ企業・営業代理店の方',
          Img : require('@/assets/img/partners/r-img01.png'),
        },
        {
          id : '2',
          Title : 'ソフトウェア開発パートナー',
          Text : 'ソフトウェア開発を協業してくださる方<span>いずれは独立を目指している方、<br class="sp">個人事業主・フリーランスの方</span>技術的なスキルを積みたいという方を歓迎致します。',
          Img : require('@/assets/img/partners/r-img02.png'),
        },
        {
          id : '3',
          Title : 'ホームページ制作・<br class="sp">Webディレクター',
          Text : 'Web制作の業務を協業してくださる方<span>いずれは独立を目指している方、<br class="sp">個人事業主・フリーランスの方</span>技術的なスキルを積みたいという方を<br class="sp">歓迎致します。',
          Img : require('@/assets/img/partners/r-img03.png'),
        },
      ]
    }
  },
  methods: {
    PartnersRecruit() {
      gsap.fromTo('.js-recruit-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-recruit-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-recruit-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-recruit-fade01',start: 'center 70%' }});
      gsap.fromTo('.js-recruit-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-recruit-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-recruit-fade03' , 0.5 ,{opacity:0 , y: 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-recruit-fade03',start: 'center 70%'}});
      gsap.fromTo('.js-recruit-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-recruit-trigger1',  start: 'center 70%' }});
      gsap.fromTo('.js-recruit-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-recruit-trigger2',  start: 'center 70%'}});
      gsap.fromTo('.js-recruit-trigger3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-recruit-trigger3',  start: 'center 70%'}});
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(function () {
      this.PartnersRecruit();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.recruit {
  padding:2rem 0 5rem 0;
  background-color:#F2AB34;
  @include sp {
    padding: 1rem 0 1.5rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 1.5625rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 0.5rem 0;
    padding-left: 10%;
  }
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 0;
        padding: 1.5rem 0 0 15px;
    }

    &__text {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 3.125rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        padding-left: 5%;
        margin-bottom: 1.6875rem;
      }
    }
  }

  &__contents {
    width: 52.5rem;
    background-color: #fff;
    border-radius: 1.25rem;
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.05);
    padding: 2.5rem 5rem 2.625rem 4rem;
    margin-bottom: 2.5rem;
    @include sp {
      margin-bottom: 16px;
    }
    &:last-of-type {
     padding: 2.5rem 2.5rem 2rem 2.5rem;
     margin-bottom: 4rem;
    @include sp {
      padding: 1.25rem 1rem 1rem 1rem;
      margin-bottom: 1.5rem;
    }
    }
    @include sp {
      width: 15.4375rem;
      padding: 1.25rem 1rem 1rem 1rem;
      margin-left: 0.625rem;
    }
    .m-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
      @include sp {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0.5rem;
      }
    }
    .flex-box {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-start;
      @include sp {
        display: block;
      }
      .text {
        width: 31rem;
        max-width: 100%;
        font-size: 1rem;
        margin-right: 2.5rem;
        @include sp {
          font-size: 0.75rem;
          text-align: center;
          letter-spacing: -0.06em;
          margin-right: 0;
        }
        ::v-deep {
          span {
            display: block;
            font-weight: bold;
            color: #E56910;
          }
          .sp {
            display: none;
            @include sp {
              display: block;
            }
          }
        }
      }
    }
      img {
        zoom: .45;
        margin-top: -5rem;
        @include sp {
          width: 18.75rem;
          margin: 0 auto;
        }
      }
  }


/* コンポーネントstyle変更 */

  .text-pforange-200 {
    color: #fff!important;
  }

  .mx-400 {
    max-width: 25rem!important;
  }
  .bg-orange {
    background-color: #E56910;
  }
  .text-white {
    color: #fff;
    font-weight: normal!important;
  }

  .sp-style {
    @include sp {
      width: 15.4375rem!important;
      font-size: 0.875rem!important;
      padding: 1.0625rem 0!important;
      margin: 0 0 0 0.625rem!important;
    }
  }

}
</style>