<template>
  <div id="partners">
    <Breadcrumb />
    <PageNav />
    <Hero />
    <Works />
    <Works02 />
    <Recruit />
  </div>
</template>


<script>
import Breadcrumb from "@/components/organisms/partners/Breadcrumb.vue";
import PageNav from "@/components/organisms/partners/PageNav.vue";
import Hero from "@/components/organisms/partners/Hero.vue";
import Works from "@/components/organisms/partners/Works.vue";
import Works02 from "@/components/organisms/partners/Works02.vue";
import Recruit from "@/components/organisms/partners/Recruit.vue";

export default {
  components: {
    Breadcrumb,
    PageNav,
    Hero,
    Works,
    Works02,
    Recruit,
  },
  methods: {

  },
  mounted() {
    this.$nextTick(() => {
      this.$noScroll();
      setTimeout(this.$returnScroll, 800);
    });
  },
};
</script>