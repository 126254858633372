<template>
  <div id="company">
      <component 
        v-for="section in sections" 
        :is="section.layout" 
        :key="section.index" 
        :id="section.id" 
        :title="section.title"
        :className="section.class"
        :bgcolor="section.bgcolor" 
        :txtcolor="section.txtcolor" 
        :content="section.content" 
        :breadcrumb="section.breadcrumb"
      />
  </div>
</template>
<script>
/* SectionTile読み込みの予定 */
//import constants from '../common/constants'; 

/* ConponentFile読み込み */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionBasic from '@/components/templates/SectionBasic';
import CompanyInfo from '@/components/organisms/company/CompanyInfo';

export default {
  components:{
      CompanyInfo
  },
  data() {
    return {
      sections:[
        {id:'company-info',  class: 'company-title' , title:'<span>C</span><span>o</span><span>m</span><span>p</span><span>a</span><span>n</span><span>y</span>', content:CompanyInfo, layout:SectionBasic, breadcrumb:true },
      ]
    }
  },
  methods : {
    Company() {
        gsap.fromTo('.company-title h2 span' , {opacity:0 , delay : 0.9 },{opacity:1, delay : 0.9 ,scrollTrigger: {  trigger: '.company-title h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.Company();
    });
  },
}
</script>