<template>
  <section id="works" class="works">
    <div class="works__inner js-works-main">
      <SectionTitle title="<span>W</span><span>O</span><span>R</span><span>K</span><span>S</span> <span>&</span> <span>P</span><span>A</span><span>R</span><span>T</span><span>N</span><span>E</span><span>R</span><span>S</span>" />
      <div class="r-box">
        <h3 class="js-works-fade01">実績とパートナーの紹介</h3>
        <p class="r-box__text js-works-fade02">
          経理代行、ソフトウェア受託開発、<br
            class="sp"
          />ホームページ制作それぞれの事業における<br />
          ビジネスパートナーと実績の一部を<br class="sp" />ご紹介いたします。
        </p>

        <h3 class="r-box__title js-works-fade03">
          <span class="color-orange">SSTECH</span>
          ×
          <span class="color-orange">Profitbnk</span>
        </h3>

        <div class="r-box__flex">
          <div class="item" v-for="item in items" :key="item.id" :class='"js-works-trigger" + item.id'>
            <div
              class="img"
              :style="{ 'background-image': 'url(' + item.Img + ')' }"
            ></div>
            <p class="m-title">{{ item.Title }}</p>
            <p class="sub-title">{{ item.SubTitle01 }}</p>
            <p class="text" v-html="item.Text01"></p>
            <p class="sub-title">{{ item.SubTitle02 }}</p>
            <p class="text" v-html="item.Text02"></p>
          </div>
        </div>

        <div
          class="r-box__flex r-box__flex02 js-works-trigger3"
          v-for="detail in details"
          :key="detail.id"
        >
          <div class="left-item">
            <div
              class="left-img"
              :style="{ 'background-image': 'url(' + detail.Img + ')' }"
            ></div>
            <p class="left-title">
              <span class="span01">{{ detail.Genre }}</span
              >{{ detail.Name }}<span class="span02">{{ detail.Address }}</span>
            </p>
          </div>
          <div class="right-item">
            <p class="right-text" v-html="detail.Text"></p>
            <div class="btn-cover">
              <a :href="detail.Url" class="right-btn" target="_blank" rel="noopener noreferrer">HPを見る</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import  gsap  from 'gsap';
import  ScrollTrigger  from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";
// import ButtonService from "@/components/modules/ButtonService";

export default {
  components: {
    SectionTitle,
    // ButtonService,
  },
  data() {
    return {
      items: [
        {
          id : '1',
          Img: require("@/assets/img/partners/kaisen-m.jpg"),
          Title: "海鮮居酒屋 M社",
          SubTitle01: "新規設備の導入",
          Text01:
            "返済不要の補助金を活用し、テイクアウト用の設備と食洗機を新規で導入しました。補助金等に関するお手続きはプロフィットバンクで行い、設備導入は（株）SSTECHにて行いました。",
          SubTitle02: "売上げアップと業務の効率化を実現！",
          Text02:
            "設備の導入で売り上げがアップし、食洗機を導入したことで業務効率が上がりました。 返済不要の補助金を活用できたことで、設備導入における自己負担が大きく抑えられました。",
        },
        {
          id : '2',
          Img: require("@/assets/img/partners/ramen-l.jpg"),
          Title: "ラーメン店 I社",
          SubTitle01: "新規設備の導入と看板リニューアル",
          Text01:
            "返済不要の補助金を活用し、テイクアウト用の設備の導入と店看板のリニューアルを行いました。補助金等に関するお手続きはプロフィットバンクで、看板の入替えは（株）SSTECHにて行いました。",
          SubTitle02: "集客アップで売上げ拡大！",
          Text02:
            "看板のリニューアルで店舗のイメージアップを図り、設備導入も相まって、売り上げの拡大につながりました。返済不要の補助金を活用できたことで、費用の自己負担額が大きく抑えられました。",
        },
      ],

      details: [
        {
          Img: require("@/assets/img/partners/sstech.jpg"),
          Genre: "製氷機や厨房機器の修理",
          Name: "株式会社SSTECH",
          Address: "兵庫県伊丹市",
          Text: "業務用冷蔵庫、業務用製氷機、業務用食洗機のトラブルは当社にご相談ください。<br>ホシザキ・パナソニック・福島工業など様々なメーカーに対応しており、経験豊富なスタッフがお客様のご要望に幅広くお応えいたします。大阪府・兵庫県内全域に対応しています。",
          Url: "https://www.kk-sstech.com/",
        },
      ],
    };
  },

  methods: {
    PartnersWorks() {
      gsap.fromTo('.js-works-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-works-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-works-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-works-fade01',start: 'center 70%' }});
      gsap.fromTo('.js-works-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-works-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-works-fade03' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-works-fade03',start: 'center 70%'}});

      gsap.fromTo('.js-works-trigger1 .img' , 0.9 ,{backgroundSize:'200%' },{backgroundSize : '105%',scrollTrigger: {  trigger: '.js-works-trigger1 .img',  start: 'center 70%'}});
      gsap.fromTo('.js-works-trigger2 .img' , 0.9 ,{backgroundSize:'200%' },{backgroundSize : '105%',scrollTrigger: {  trigger: '.js-works-trigger2 .img',  start: 'center 70%'}});
      gsap.fromTo('.js-works-trigger3 .left-item .left-img' , 0.9 ,{backgroundSize:'130%'},{backgroundSize : '105%',scrollTrigger: {  trigger: '.js-works-trigger3 .left-item .left-img',  start: 'center 70%'}});
      gsap.fromTo('.js-works-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-works-trigger1',  start: 'center 70%'}});
      gsap.fromTo('.js-works-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-works-trigger2',  start: 'center 70%'}});
      gsap.fromTo('.js-works-trigger3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-works-trigger3',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(function () {
      this.PartnersWorks();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.works {
  padding: 2rem 0 1rem 0;
  background-color: #faf9f7;
  @include sp {
    padding: 3rem 0 1.5rem 0;
  }
  h3 {
    width: fit-content;
    font-weight: bold;
    font-size: 2.5rem;
    text-align: center;
    margin: 0.1rem auto 2rem auto;
    @include sp {
      font-size: 1.25rem;
      margin: 0 0 1rem 0;
      padding-left: 9%;
    }
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
      width: 100%;
      display: block;
      margin-left: 0;
      padding: 1.5rem 0 0 15px;
    }

    &__text {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 10rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        margin-bottom: 5rem;
        padding-left: 22px;
      }
    }

    &__title {
      font-size: 3.75rem;
      font-family: "Noto Sans JP", sans-serif;
      text-align: center;
      margin-bottom: 3.3125rem;
      @include sp {
        width: fit-content;
        font-size: 1.25rem;
        line-height: 1;
        margin-bottom: 1.5625rem;
        padding-left: 20%;
      }
      .color-orange {
        font-size: 4.5rem;
        font-weight: bold;
        color: #e56910;
        @include sp {
          display: block;
          font-size: 2rem;
        }
      }
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.0625rem #cccccc solid;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
      @include sp {
        display: block;
        width: 15.4375rem;
        margin: 0 0 1.5rem 0.3125rem;
      }
      &:last-of-type {
        border-bottom: none;
        @include sp {
          margin-bottom: 0;
        }
      }
      &:first-of-type {
        .item:first-of-type {
          @include sp {
            padding-bottom: 1.5rem;
          }
        }
      }
    }

    .item {
      width: 25rem;
      height: auto;
      @include sp {
        width: 15.4375rem;
      }

      .img {
        width: 100%;
        height: 18.25rem;
        background-color: #fff;
        background-size: cover;
        background-position: center;
        border-radius: 1.25rem;
        margin-bottom: 1.4375rem;
        @include sp {
          height: 12.0625rem;
          border-radius: 0.5rem;
          margin-bottom: 0.5625rem;
        }
      }
      .m-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        @include sp {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
      }
      .sub-title {
        font-size: 1rem;
        color: #e56910;
        margin-bottom: 0.25rem;
        @include sp {
          font-size: 0.75rem;
        }
      }
      .text {
        font-size: 1rem;
        line-height: 200%;
        margin-bottom: 1.5rem;
        @include sp {
          font-size: 0.75rem;
          line-height: 180%;
          margin-bottom: 1rem;
        }
      }
    }

    /*------  下の段  ------*/
    .left-item {
      width: 400px;
      @include sp {
        width: 247px;
      }
      .left-img {
        width: 100%;
        height: 18.25rem;
        background-color: #fff;
        background-size: cover;
        background-position: center;
        border-radius: 1.8rem;
        margin-bottom: 0.5rem;
        @include sp {
          height: 11.25rem;
          border-radius: 1rem;
        }
      }
      .left-title {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1;
        @include sp {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
        .span01 {
          display: block;
          font-size: 1rem;
          font-weight: normal;
          margin-bottom: 0.5rem;
          @include sp {
            font-size: 0.75rem;
          }
        }
        .span02 {
          display: block;
          font-size: 1rem;
          font-weight: normal;
          color: #9b9b9b;
          margin-top: 0.625rem;
          @include sp {
            font-size: 0.75rem;
          }
        }
      }
    }

    .right-item {
      width: 25rem;
      position: relative;
      @include sp {
        width: 15.4375rem;
        min-height: 13.75rem;
      }
      .right-text {
        font-size: 1rem;
        line-height: 200%;
        @include sp {
          font-size: 0.75rem;
          line-height: 180%;
        }
      }
      .btn-cover {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
      .right-btn {
        display: block;
        width: 25rem;
        background-color: #fff;
        border: 1px solid;
        padding: 1.5rem 0;
        font-size: 1.25rem;
        font-weight: bold;
        color: #e56910;
        text-align: center;
        @include sp {
          width: 15.4375rem;
          font-size: 1rem;
          padding: 1rem 0;
        }
        &::after {
          content: "";
          opacity: 0;
          display: inline-block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: url("~@/assets/img/btnarrow.png");
          background-size: 88px 9px;
          transition: all 0.3s 0s ease-in-out;
          width: 88px;
          height: 9px;
          margin-left: 8px;
        }
        &:hover::after {
          animation: mvarrow 1.2s infinite;
        }
      }
    }
  }
}

@keyframes mvarrow {
  50% {
    /* right: .2em; */
    margin-left: 16px;
    opacity: 1;
  }
  100% {
    /* right: -.4em; */
    margin-left: 24px;
    opacity: 0;
  }
}
</style>