<template>
  <div :id="getId" :class="`bg-${getBgColor}`">
    <v-container class="max-w-310 py-4 py-md-8 px-0 mt-lg-0">
      <v-flex class="section__wrapper w-full">
        <div><SectionTitle v-if="getTitle" :title="getTitle" :color="getTxtColor" :class="getClass"  class="w-28px md:w-52px mr-3 mr-md-13"></SectionTitle></div>
        <div class="content__wrapper w-full" :class="(getTitle) ? '':'no-breadcrumb'">
          <Breadcrumb v-if="isBreadcrumb" />
          <component :is="getContent" class="pb-6 pb-md-12" :class="(isBreadcrumb) ? 'pt-4 pt-md-6':'pt-6 pt-md-12'" />
        </div>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
import Breadcrumb from '@/components/modules/Breadcrumb';
import SectionTitle from '@/components/modules/SectionTitle';
export default {
  components:{
    Breadcrumb,
    SectionTitle
  },
  props:{
    id:{
      type:String,
      default: ''
    },
    title:{
      type:String
    },
    className:{
      type:String
    },
    bgcolor:{
      type:String,
      default: 'transparent'
    },
    txtcolor:{
      type:String,
      default: 'pforange-200'
    },
    content:{
      type:Object
    },
    breadcrumb: {
      type:Boolean,
      default: false
    }
  },
  data(){
    return {
      getId: this.id,
      getTitle: this.title,
      getClass: this.className,
      getBgColor: this.bgcolor,
      getTxtColor: this.txtcolor,
      getContent: this.content,
      isBreadcrumb: this.breadcrumb
    }
  }
}
</script>
<style scoped>
  .section__wrapper {
    padding: 0 24px;
  }
  .content__wrapper {
    padding-right: 0; 
  }
  .no-breadcrumb {
    padding-left: 40px;
  }
  @screen md {
    .section__wrapper {
      padding: 0 96px;
    }
    .content__wrapper {
      padding-right: 104px;
    } 
    .no-breadcrumb {
      padding-left: 104px;
    }
  }
</style>