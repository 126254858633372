<template>
    <section id="case" class="case">
      <div class="case__inner js-c-main">
          <SectionTitle title="<span>C</span><span>a</span><span>s</span><span>e</span> <span>S</span><span>t</span><span>u</span><span>d</span><span>i</span><span>e</span><span>s</span>"/>
          <div class="r-box">
            <h3 class="js-c-fade01">導入事例</h3>

            <div class="case__contents" v-for='item in items' :key='item.Id' :class='"js-c-trigger" + item.Id'>
              <img :src='item.Img' class="sp">
              <p class="m-title"><span>{{ item.Id }}</span>{{ item.Title }}</p>
              <p v-html='item.SubTitle' class="sub-title"></p>
              <div class="flex-box">
                <img :src='item.Img' class="pc">
                <p v-html='item.Text' class="text"></p>
              </div>
            </div>

            <p class="case__more js-c-fade02">
              その他、様々な開発実績がございます。<br>
              エクセルでの作業などの業務改善、<br class="sp">生産性の向上をご検討の場合は、<br class="sp">一度プロフィットバンクへご相談ください。
            </p>

          </div>
      </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {Id : '01',Title : '発注書・納品書の電子化',SubTitle : 'システム開発費 600万円、作業時間 1か月あたり４日分削減',Text : 'A社は顧客からの手書きの発注書を元に納品書へ転記・作成を行っていたが、手書きの発注書・納品書を電子化するシステムを導入。<br>発注書電子化のメリットを提示することでA社の顧客も合意。<br class="pc">発注書から自動的に納品書・請求書へ転記する<br class="pc">ように変更し業務時間が大幅に短縮できた。',Img : require('@/assets/img/development/c-img01.png'),},
        {Id : '02',Title : '各種書類の電子化、クラウド化',SubTitle : 'クラウド化することにより同一データの同時編集が可能に。<br class="pc">作業効率が大幅アップ',Text : '売上・仕入に関する書類（請求書、発注書等）、経費に関する書類（各種支払、従業員への給与）これらを全て電子化し、いつでもどこでも活用できるようにクラウドサービスを導入。クラウド上で経理・給与計算を実施することができた。また、既に登録済みのデータを活用するため業務時間が大幅に短縮できた。',Img : require('@/assets/img/development/c-img02.png'),},
        {Id : '03',Title : '在庫管理・発注システム',SubTitle : '作業の負荷軽減と業務の<br class="sp">精度の向上',Text : '設備を6台もっている企業様。各設備ごとの工程表を作成するためには原材料の在庫管理・発注業務が必要。それらの業務をすべてエクセルで行っており、作業の処理に限界を感じていた。また、在庫管理、発注業務に加え、各工場へ確認したり、取引先へ連絡することが非常に手間だった。これを改善するべく、原材料や商品の在庫管理が可能なシステムを導入した。導入後、在庫管理の時間が短縮でき、更に工程表の作成にもガントチャートと在庫管理を紐づけることで工程表の作成・修正を容易にすることができた。',Img : require('@/assets/img/development/c-img03.png'),},
      ]
    }
  },
  methods: {
    DeveCase() {
      gsap.fromTo('.js-c-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-c-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-c-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-c-trigger01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-c-trigger01',  start: 'center 70%'}});
      gsap.fromTo('.js-c-trigger02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-c-trigger02',  start: 'center 70%'}});
      gsap.fromTo('.js-c-trigger03' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-c-trigger03',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.DeveCase();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.case {
  padding:2rem 0 5rem 0;
  background-color: #FAF9F7;
  @include sp {
    padding: 1rem 0 1.5rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 4.5rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.5rem 0;
    padding-left: 27%;
  }
  }
  .heding-img {
    display: block;
    width: 43.375rem;
    margin: 0 auto;
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
        padding: 1.5rem 0 0 0;
    }

    &__text {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 3.125rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        margin-bottom: 2.5rem;
      }
      span {
        display: block;
        width: fit-content;
        font-size: 1.5rem;
        font-weight: bold;
        border-bottom: 0.0625rem solid;
        margin: 0 auto 1rem auto;
        @include sp {
          font-size: 1rem;
          margin: 0 0 0.8rem 0;
        }
      }
    }
  }

  &__contents {
    width: 52.5rem;
    background-color: #fff;
    border-radius: 1.25rem;
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.05);
    padding: 2.5rem 2.5rem 1rem 2.5rem;
    margin-bottom: 2.5rem;
    &:last-of-type {
     padding: 2.5rem 2.5rem 2rem 2.5rem;
    @include sp {
      padding: 0.625rem;
    }
    }
    @include sp {
      width: 15.4375rem;
      padding: 0.625rem;
    }
    .m-title {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 1.6875rem;
      @include sp {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
      span {
        display: inline-block;
        font-size: 1.5rem;
        margin-right: 0.625rem;
        @include sp {
          font-size: 1.125rem;
        }
      }
    }
    .sub-title {
      font-size: 1.5rem;
      font-weight: bold;
      color:  #E56910;
      margin-bottom: 1rem;
        @include sp {
          font-size: 1rem;
          letter-spacing: -0.01em;
          line-height: 140%;
        }
    }
    .flex-box {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-start;
      .text {
        width: 31rem;
        max-width: 100%;
        margin-right: 2.5rem;
        @include sp {
          font-size: 0.75rem;
          margin-right: 0;
        }
      }
    }
      img {
        zoom: .45;
        margin-top: -5rem;
        @include sp {
          zoom: .3;
          margin: 0 auto;
        }
      }
  }
  &__more {
    font-size: 1rem;
    line-height: 200%;
    text-align: center;
    @include sp {
      width: fit-content;
      font-size: 0.75rem;
    }
  }
}
</style>