<template>
  <div id="privacy">
      <component 
        v-for="section in sections" 
        :is="section.layout" 
        :key="section.index" 
        :id="section.id" 
        :title="section.title" 
        :bgcolor="section.bgcolor" 
        :txtcolor="section.txtcolor" 
        :content="section.content" 
        :breadcrumb="section.breadcrumb"
      />
  </div>
</template>
<script>
import SectionBasic from '@/components/templates/SectionBasic';
import PrivacyText from '@/components/organisms/privacy/PrivacyText';

export default {
  components:{
      PrivacyText,
      SectionBasic
  },
  data() {
    return {
      sections:[
        {id:'privacy-text', title:'Privacy Policy', content:PrivacyText, layout:SectionBasic, breadcrumb:true },
      ]
    }
  }
}
</script>