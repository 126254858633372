<template>
  <v-app class="bg-pfgray-200 font-yug text-pfblack-100 text-xs md:text-base">
    <!-- ナビ・ヘッダー -->
    <Header />

    <!-- メイン -->
    <v-main class="overflow-x-hidden">
      <!-- <transition mode="out-in"> -->
        <router-view/> 
      <!-- </transition> -->
    </v-main> 

    <!-- フッター -->
    <Footer />

    <!-- TOPへ戻る -->
    <transition name="fade">
      <GoToTop v-show="isShow" class="fixed bottom-[267px] md:bottom-[70px] right-0" />
    </transition>
  </v-app>
</template>

<script>
  import Header from '@/components/templates/Header';
  import Footer from '@/components/templates/Footer';
  import GoToTop from '@/components/modules/GoToTop';

  export default {
    name: 'App',
    components: {
      Header,
      Footer,
      GoToTop
    },
    data(){
      return{
        scrollY: 0
      }
    },
    mounted() {
      this.createTitleDesc(this.$route);
      window.addEventListener("scroll", this.onScroll);
      window.addEventListener("load", () => {
        this.onScroll();
      });
    },
    methods : {
      createTitleDesc(routeInstance){
        // タイトルを設定
        let setTitle = routeInstance.meta.title ? routeInstance.meta.title : '株式会社プロフィットバンク'
        document.title = setTitle

        // メタタグdescription設定
        let setDesc = routeInstance.meta.desc ? routeInstance.meta.desc : '';
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      },
      onScroll() {
        this.scrollY = window.pageYOffset;
      }
    },
    computed: {
      isShow() {
        return this.scrollY > 200 ? true : false;
      }
    },
    watch: { 
      $route() {
        this.createTitleDesc(this.$route);
      }
    }
  };
</script>

<style>
.v-main {
  padding-top: 55px;
  padding-left: 0;
}
.v-navigation-drawer__content {
  padding-bottom: 100px!important;
}
@screen lg {
  .v-main {
    padding-top: 0 !important;
    padding-left: 200px !important;
  }
}
[v-cloak] {
  display: none;
}
.v-toolbar__content {
  flex-direction: row-reverse;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>