<template>
  <div>
    <span class="copyright tracking-widest font-bold">{{getCopyright}}</span>
  </div>
</template>
<script>
export default {
  props:{
    copyright:{
      type:String
    },
  },
  data(){
    return {
      getCopyright: this.copyright,
    }
  }
}
</script>
<style scoped>
  .copyright {
    display: block;
    width: calc(100%/.8);
    font-size: 10px;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    text-align: center;
  }
  @screen md {
    .copyright {
    width: 100%;
    font-size: 12px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  }
</style>