<template>
    <section id="case" class="case">
      <div class="case__inner js-c-main">
          <SectionTitle title="<span>C</span><span>a</span><span>s</span><span>e</span> <span>S</span><span>t</span><span>u</span><span>d</span><span>i</span><span>e</span><span>s</span>"/>
          <div class="r-box">
            <h3 class="js-c-fade01">導入事例</h3>
            <p class="r-box__text js-c-fade01">
              <span>厨房機器の修理業 S社様の事例</span>「やりたいコト」だけに<br class="sp">集中できる仕組みづくり
            </p>

            <div class="flex-cover">
              <div class="flex-cover__box flex-cover__box--arrow js-c-fade02">
                <p class="m-title m-title__pdl">社内で経理業務を行う場合</p>
                <div class="flex-sp">
                  <img src="@/assets/img/case/img01.png" alt="" class="img01">
                  <div class="box">
                    <img src="@/assets/img/case/human.png" alt="" class="img02">
                    <p class="text">
                      正社員<br>年収：400万円
                    </p>
                  </div>
                </div>
              </div>

              <div class="flex-cover__box flex-cover__box--arrow02 js-c-fade03">
                <p class="m-title m-title__pdl02">経理代行サービス</p>
                <div class="flex-sp">
                  <img src="@/assets/img/case/img02.png" alt="" class="img01">
                  <div class="box">
                    <img src="@/assets/img/case/logo.png" alt="" class="img02">
                    <p class="text">
                    サービス利用料<br>
                    年間：<span class="text--orange">200万円</span>
                    </p>
                  </div>
                </div>
                  <img src="@/assets/img/case/case-img02__text.png" alt="" class="text-img">
              </div>

              <div class="flex-cover__box js-c-fade04">
                <p class="m-title m-title--orange m-title__pdl03">売上アップにつながる</p>
                <img src="@/assets/img/case/img03.png" alt="" class="img01 img01--center">
                <div class="hukidasi">
                  <p class="hukidasi__text">
                    <span>浮いたお金と時間</span>を<br>
                    コア業務に活かせる！！
                  </p>
                </div>
              </div>
            </div>

            <!-- メリット・デメリット -->
            <h4 class="js-c-fade05">経理代行の<br class="sp">メリット・デメリット</h4>
            <p class="pf-title js-c-fade06"><span>プロフィットバンクはいいとこ取り</span></p>
            <p class="pf-subtitle js-c-fade07">プロが経理を代行するので、<br class="sp">新人教育や退職引き継ぎ等の悩みも解決</p>
            <div class="table-img pc js-c-fade08">
              <img src="@/assets/img/case/table.png" alt="">
            </div>
          </div>
      </div>
            <div class="table-img sp js-c-fade09">
              <img src="@/assets/img/case/table-sp.png" alt="">
            </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  methods: {
    AccountingCase() {
      gsap.fromTo('.js-c-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-c-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-c-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade03' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade03',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade04' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade04',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade05' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade05',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade06' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade06',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade07' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade07',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade08' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade08',start: 'center 70%'}});
      gsap.fromTo('.js-c-fade09' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-c-fade09',start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.AccountingCase();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.case {
  padding:2rem 0 5rem 0;
  background-color: #FAF9F7;
  @include sp {
    padding: 1rem 0 1.5rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 4.5rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.5rem 0;
    padding-left: 27%;
  }
  }
  .heding-img {
    display: block;
    width: 43.375rem;
    margin: 0 auto;
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
        padding: 1.5rem 0 0 1rem;
    }

    &__text {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 3.125rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        margin-bottom: 2.5rem;
      }
      span {
        display: block;
        width: fit-content;
        font-size: 1.5rem;
        font-weight: bold;
        border-bottom: 0.0625rem solid;
        margin: 0 auto 1rem auto;
        @include sp {
          font-size: 1rem;
          margin: 0 0 0.8rem 0;
        }
      }
    }

    .flex-cover {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 11.25rem;
      @include sp {
        display: block;
      }
      .flex-cover__box {
        min-width: 15.625rem;
        position: relative;
        @include sp {
          min-width: initial;
          margin-bottom: 5rem;
        }
        &:nth-of-type(2) {
          margin: 0 4.5rem 0 4rem;
          @include sp {
            margin :0 0 6rem 0;
          }
        }
      }

      .flex-cover__box--arrow {

        &::after {
          content: '';
          position: absolute;
          bottom: 27.5rem;
          right: -5rem;
          width: 5rem;
          height: 5rem;
          background-image: url('~@/assets/img/case/arrow.png');
          background-position: center;
          background-size: 2rem;
          @include sp {
            bottom: -4rem;
            right: 40%;
            transform: rotate(90deg);
          }
        }
      }

      .flex-cover__box--arrow02 {

        &::after {
          content: '';
          position: absolute;
          bottom: 27.5rem;
          right: -4rem;
          width: 5rem;
          height: 5rem;
          background-image: url('~@/assets/img/case/arrow.png');
          background-position: center;
          background-size: 2rem;
          @include sp {
            bottom: -6rem;
            right: 40%;
            transform: rotate(90deg);
          }
        }
      }

      .m-title {
        font-size: 1rem;
        font-weight: bold;
        @include sp {
          width: fit-content;
        }
        &--orange {
          font-size: 1.5rem;
          color: #E56910;
          @include sp {
            font-size: 1rem;
          }
        }
        &__pdl {
          @include sp {
            padding-left: 1.0625rem;
          }
        }
        &__pdl02 {
          @include sp {
            padding-left: 3.3125rem;
          }
        }
        &__pdl03 {
          @include sp {
            padding-left: 1.875rem;
          }
        }
      }

      .flex-sp {
        @include sp {
          width: 14.375rem;
          display: flex;
          align-items: center;
        }
      }
      img {
        display: block;
      }
      .img01 {
        zoom: .5;
        margin: 0 auto;
        @include sp {
          zoom: .3;
          margin: 0;
        }
        &--center {
          margin-left: 5rem;
          @include sp {
            zoom: .4;
            margin-left:0;
          }
        }
      }
      .img02 {
        zoom: .5;
        margin: 0 auto 0.875rem auto;
        @include sp {
          zoom:.4;
        }
      }
      .text {
        font-size: 1rem;
        line-height: 1.6;
        @include sp {
          font-size: 0.75rem;
        }
        &--orange {
          color: #E56910;
        }
      }
      .text-img {
        zoom: .5;
        margin: 2.125rem auto;
        @include sp {
          margin: 1.25rem 0 0 1.25rem;
        }
      }

      .hukidasi {
        width: 18rem;
        height: 13.375rem;
        background-image: url('~@/assets/img/case/hukidasi.png');
        background-position: center;
        background-size: 17.5rem;
        padding: 5rem 0;
        position: absolute;
        left: -1.25rem;
        @include sp {
          width: 12.5rem;
          height: 9.375rem;
          background-size: 11.25rem;
          left: 0;
          padding: 3.4375rem 0;
        }
        &__text {
          font-size: 1.25rem;
          font-weight: bold;
          @include sp {
            font-size: 0.75rem;
          }
          span {
            font-size: 1.5rem;
            color: #E56910;
            @include sp {
              font-size: 1rem;
            }
          }
        }
      }
    }

    // 経理代行のメリットデメリット

    h4 {
      font-size:2.5rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 2.5rem;
      @include sp {
        width: fit-content;
        font-size:1.5rem;
        margin-bottom: 1.5rem;
      }
    }
    .pf-title {
      width: fit-content;
      font-size: 2rem;
      font-weight: bold;
      color: #E56910;
      text-align: center;
      margin: 0 auto 2.125rem auto;
      @include sp {
        font-size: 14px;
        margin: 0 0 1.0625rem 0;
      }
      span {
        background:linear-gradient(transparent 60%, rgba(242, 171, 52, 0.5) 60%);
      }
    }
    .pf-subtitle {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 1.5rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        margin-bottom: 1rem;
      }
    }
  }
    .table-img {
      @include sp {
        width: 31.25rem;
        overflow-x: scroll;
        margin: 0 auto;
      }
      @include sp02 {
        width: 95%;
        margin: 0 0 0 auto;
      }
      img {

        @include sp {
          width: 37.5rem;
          max-width: initial;
        }
        @include sp02 {
        width: 30.125rem;
        }
      }
    }
 
}
</style>