<template>
  <div id="development">
    <Breadcrumb />
    <PageNav />
    <Hero />
    <CVArea />
    <Problem />
    <Feature />
    <Case />
    <Works />
    <CVArea />
  </div>
</template>


<script>
import Breadcrumb from "@/components/organisms/development/Breadcrumb";
import PageNav from "@/components/organisms/development/PageNav";
import Hero from "@/components/organisms/development/Hero";
import CVArea from "@/components/organisms/development/CVArea";
import Problem from "@/components/organisms/development/Problem";
import Feature from "@/components/organisms/development/Feature";
import Case from "@/components/organisms/development/Case";

import Works from "@/components/organisms/development/Works";

export default {
  components: {
    Breadcrumb,
    PageNav,
    Hero,
    CVArea,
    Works,
    Problem,
    Feature,
    Case,
  },
  mounted() {
    this.$nextTick(() => {
      this.$noScroll();
      setTimeout(this.$returnScroll, 800);
    });
  },
  watch: {
    hogeList: function () {
      this.$nextTick(() => {
        var positionY = sessionStorage.getItem("positionY");
        scrollTo(0, positionY);
        setTimeout(function () {
          scrollTo(0, positionY);
        }, 500);
      });
    },
  },
};
</script>

