<template>
  <ValidationProvider  :name="field" :rules="rules" v-slot="{ errors }">
    <input :id="name" :name="name" :type="type" :placeholder="placeholder"  v-model="inputtedValue" class="
    base text-base rounded-small py-[18px] px-4 px-md-6 leading-relaxed bg-white border-pfblack-100 focus:border-pforange-200">
    <p v-show="errors.length" class="help text-pforange-200 pt-2 px-4 px-md-6 mb-0 text-left">
      {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>

<script>
export default {
    props:{
      name:{
        type:String
      },
      placeholder:{
        type:String
      },
      field:{
        type:String
      },
      value: {
        type:String
      },
      type: {
        type: String,
        required: true
      },
      rules: {
        type: Object,
        required: true
      },
      required: {
        type: Boolean,
        default: false
      }
    },
    computed:{
      inputtedValue:{
        get(){
          return this.value
        },
        set(newValue){
          this.$emit("input", newValue)
        }
      }
    }
};
</script>
<style scoped>
.base {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 0;
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  width: 100%;
}
.base:focus {
	border: 1px solid rgba(0, 0, 0, 0.32);
	box-shadow: none;
	outline: none;
}
</style>