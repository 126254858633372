<template>
  <v-footer
    padless
    color="white"
  >
    <v-row class="m-0">
      <!-- ヘッダー情報 -->
      <v-col class="footer__info md:py-20 font-bold">
        <!-- ロゴ -->
        <v-col
          cols="12"
          class="pa-0 mb-1"
        >
          <div class="logo__container">
            <Logo class="mx-auto w-auto" />
          </div>
        </v-col>

        <!-- フッターメニュー -->
        <v-col
          cols="12"
          class="pa-0 text-center"
        >
          <v-list
            flat
            class="pa-0"
          >
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.to"
              link
              v-ripple="{class: `transparent--text`}"
              class="d-inline-block pa-0"
            >
              <v-list-item-content  class="px-3">
                 <v-list-item-title class="underline text-pfblack-100 transition duration-300 ease-in-out  hover:text-pforange-200 h-full">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <!-- 会社情報 -->
        <v-col
          cols="12"
          class="text-center pb-0 text-xs"
        >
          <div>
            <p class="mb-2">{{ zip }}</p>
            <p>
            <span class="d-inline-block  text-xs md:text-base mr-2">{{ address[0] }}</span>
            <span class="d-inline-block  text-xs md:text-base">{{ address[1] }}</span>
            </p>
            <p class="mb-0"><a :href="`tel:${telnum}`">TEL：{{tel}}</a></p>
          </div>
        </v-col>
      </v-col>

      <!-- コピーライト -->
      <v-col
        cols="12"
        class="py-2 py-md-4 bg-pforange-100 "
      >
        <Copyright :copyright="copyright" class="bg-pforange-100" />
      </v-col>

    </v-row>
  </v-footer>
</template>
<script>
  import Logo from '@/components/modules/Logo';
  import Copyright from '@/components/modules/Copyright';
  import { TEL, ZIP, ADDRESS } from '@/common/constants';
  
  export default {
    components: {
      Logo,
      Copyright
    },
    data(){
      return {
        zip: ZIP,
        tel: TEL,
        telnum: TEL.replace(/-/g,""),
        address: ADDRESS,
        copyright: 'Copyright © profitbnk inc. All Rights Reserved.',
        items: [
          { title: '個人情報保護方針', to:'/privacy'},
          { title: 'お問い合わせ', to:'/contact'},
        ],
      }
    }
  }
</script>
<style scoped>
  .footer__info {
    padding-top:49px;
    padding-bottom: 40px;
  }
  .v-list-item__title {
    font-size: .75rem;
  }
  .logo__container {
    width: 152px;
    margin: 0 auto;
  }
  a[href*="tel:"] {
    color: inherit;
  }
  @screen md {
    .v-list-item__title {
      font-size: 1rem;
    }
    .logo__container {
      width: 215px;
      margin: 0 auto;
    }
    a[href*="tel:"] {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }
  @screen lg {
    footer {
        padding-left: 200px;
    }
  }
</style>