<template>
  <div class="hero__cover">
    <div class="hero__background js-scale"></div>

    <div class="hero__en">
      <img src="@/assets/img/top/beyour02.png" alt="BE YOUR"/>
      <img src="@/assets/img/top/partner.png" alt="PARTNER" />
      <img src="@/assets/img/top/beyour02.png" alt="BE YOUR" />
      <img src="@/assets/img/top/partner.png" alt="PARTNER" />
      <img src="@/assets/img/top/beyour02.png" alt="BE YOUR" class="sp"/>
      <img src="@/assets/img/top/partner.png" alt="PARTNER" class="sp" />
      <img src="@/assets/img/top/beyour02.png" alt="BE YOUR" class="sp"/>
      <img src="@/assets/img/top/partner.png" alt="PARTNER" class="sp"/>

    </div>

    <div class="hero__main-text js-hero__main-text">
      <h1>
        <span class="block">企業の困りゴトに</span>
        <span class="block"><span class="text-orange">FIT</span>する</span>
        <span class="block">サービスを。</span>
      </h1>
    </div>
  </div>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default {

  methods: {
    TopHero() {
      gsap.fromTo('.js-hero__main-text' , 1 ,{opacity:0 , delay : 0.8},{opacity:1, delay : 0.8});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.TopHero();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";

.hero__cover {
  position: relative;
}

.hero__en {
  overflow-x: visible;
  overflow-y: hidden;
  height: 50.125rem;
  position: absolute;
  top: 0;
  z-index: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @include sp {
    height: 35.3125rem;
  }
}
.hero__en::-webkit-scrollbar {
  display:none;
}
.hero__en img {
  display: block;
  width: 68.75rem;
  margin-bottom: 2.1875rem;
  @include sp {
    width: 31.25rem;
    margin-bottom: 0.75rem;
  }
  @include sp02 {
     width: 20.25rem;
  }
}
.hero__en img:nth-of-type(1) {
      margin-top: -1.25rem;
    @include sp {
      margin-top: 0.625rem;
    }
  }
.hero__en img:nth-of-type(2n-1) {
    position: relative;
    margin-left: 8.125rem;
    @include sp {
      margin-left: 0;
    }
  }
.hero__en img:nth-of-type(2n) {
    position: relative;
    @include sp {
      width: 37.5rem;
      margin-left: 10rem;
    }
    @include sp02 {
      width: 21.75rem;
      margin-left: 1.875rem;
    }
  }
.hero__text {
  position: relative;
  z-index: 2;
}
.hero__background {
  background: url("~@/assets/img/top/hero.png");
  background-size: 25rem 24.375rem;
  background-repeat: no-repeat;
  background-position: center 86%;
  width: calc(100% + 8.6875rem);
  height: 31.8125rem;
  margin: -2rem -4.6875rem 0 -4rem;
  position: relative;
  top: 3.125rem;
  z-index: 2;
}

.hero__main-text {
  position: absolute;
  top: 50%;
  left: 6.25rem;
  transform: translateY(-50%);
  z-index: 3;
  @include sp {
    top: 2.375rem;
    left: 6.5rem;
    transform: translateY(0);
  }
  @include sp02 {
    top: 2.375rem;
    left: 1.5rem;
    transform: translateY(0);
  }
}
.hero__main-text h1 {
  font-size: 3rem;
  @include sp {
    font-size: 2rem;
  }
}
.hero__main-text h1 .text-orange {
  font-size: 9rem;
  color: #e56910;
  @include sp {
    font-size: 3.5rem;
  }
}

.pc {
  @include sp {
    display: none!important;
  }
}

@screen sm {
  .hero__background {
    background-size: contain;
    width: auto;
    height: 35rem;
    margin-right:-18.75rem;
  }
}

@screen md {
  .hero__background {
    background-size: 51.125rem;
    width: auto;
    height: 54.375rem;
    margin: -9.125rem -26.25rem -0.625rem 0;
  }
}
</style>