<template>
  <div class="breadcrumb pc">
    <Breadcrumb/>
  </div>
</template>
<script>
import Breadcrumb from "@/components/modules/Breadcrumb";

export default {
  
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";

.breadcrumb {
  padding-top: 32px;
  padding-left: 200px;
}
</style>