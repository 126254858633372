<template>
    <section class="fourservices businessweb-empathy">
      <div class="fourservices__inner js-four-main">
          <SectionTitle title="<span>F</span><span>o</span><span>u</span><span>r</span> <span>S</span><span>e</span><span>r</span><span>v</span><span>i</span><span>c</span><span>e</span><span>s</span>"/>
          <div class="r-box">
            <h3 class="js-four-fade01">集客までまるっと<br class="sp">お任せできる<br>4つのサービス</h3>
            <div class="item" v-for='item in items' :key='item.id' :class='"js-four-fade" + item.id'>
              <p class="item__title">{{ item.Title }}</p>
              <p class="item__sub-title">{{ item.SubTitle }}</p>
              <p class="item__text">{{ item.Text }}</p>
              <p class="item__number">{{ item.id }}</p>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import  gsap  from 'gsap';
import  ScrollTrigger  from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {id : 1,Title : 'ホームページ制作',SubTitle : '成果につながる設計と\n見て伝わるデザイン',Text : 'お客様が迷うことなく欲しい情報に辿りつけるように設計をすることが大切です。お客様がストレスなくホームページにアクセスすることができるサイト制作を心がけています。ホームぺージの構築やECサイトの構築、リニューアル等、お客様のご要望に応じた企画のご提案、制作はもちろん、成果につなげる設計構築を踏まえ、“見て伝わる” デザインを重視したサイト制作を行っております。',},
        {id : 2,Title : 'WEB広告',SubTitle : '的確なターゲット設定を\n行い利益につながる運営を',Text : 'スマートフォンやタブレットが普及した今、多くのターゲットを狙うことができるWEB広告には様々な種類があります。しかし、それぞれ与える効果や影響を与えられる範囲が異なります。広告によってメリットやデメリットもあるため、プロモーションの目的を明確にし、より効果的な広告の企画と運営を行います。',},
        {id : 3,Title : 'WEBコンサルティング',SubTitle : '抱えているお悩みを解決\nするために目標値を設定',Text : '数値化したお悩みを改善するためにコンサルティングをさせていただき、繰り返しご提案と改善を行います。改善スピードを上げるために、最適なサービスやITシステムのご紹介も行います。',},
        {id : 4,Title : 'その他（付随する制作物等）',SubTitle : 'チラシ、パンフレット、ロゴ、\n名刺等の制作も承ります。',Text : '紙媒体のメリットは幅広い層のお客様に情報を届けることができる点です。WEBで出来ない紙媒体ならではのプランも必要に応じてご提案致します。パンフレット・チラシ・DMなどトータルでのプロデュースも可能です。',},
      ]
    }
  },
  methods: {
    WebFour() {
      gsap.fromTo('.js-four-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-four-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-four-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-four-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-four-fade1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-four-fade1',start: 'center 70%'}});
      gsap.fromTo('.js-four-fade2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-four-fade2',start: 'center 70%'}});
      gsap.fromTo('.js-four-fade3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-four-fade3',start: 'center 70%'}});
      gsap.fromTo('.js-four-fade4' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-four-fade4',start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
   this.$nextTick(function () {
      this.WebFour();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}





.fourservices {
  padding: 5rem 0;
  @include sp {
    padding: 2.5rem 0;
  }

  h3 {
    width: fit-content;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.75rem;
    text-align: center;
    margin: 0 auto 6.125rem auto;
    @include sp {
      font-size: 1.5rem;
      line-height: 140%;
      margin: 0 0 3.8125rem 0;
      padding-left: 9%;
    }
  }

  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }

    .r-box {
      width: 52.5rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: 3.5rem;
      @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
      }
      .item {
        width: 25rem;
        border-radius: 1.25rem;
        background-color: #fff;
        box-shadow: 0.25rem 0.25rem 0.75rem rgba(51, 51, 51, 0.04);
        margin-bottom: 6.25rem;
        padding: 3.6875rem 2rem 2.5rem 2rem;
        position: relative;
        &:nth-of-type(4) {
          margin-bottom: 6.25rem;
          @include sp {
            margin-bottom: 0;
          }
        }
        @include sp {
          width: 15.4375rem;
          border-radius: 0.625rem;
          margin: 0 0 4.0625rem 0;
          padding: 2rem 1rem 1.25rem 1rem;
        }
        &__title {
          width: 17.375rem;
          background-color: #E56910;
          padding: 0.625rem 0 0.5625rem 0;
          position: absolute;
          top: -1.5625rem;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1.5rem;
          font-weight: 700;
          color: #fff;
          text-align: center;
          @include sp {
            width: 10.625rem;
            padding: 0.375rem 0;
            top: -1.0625rem;
            font-size: 0.875rem;
          }
        }
        &__sub-title {
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 160%;
          text-align: center;
          white-space: pre-line;
          margin-bottom: 1rem;
          @include sp {
            font-size: 0.875rem;
            line-height: 140%;
          }
        }
        &__text {
          font-size: 1rem;
          font-weight: 500;
          line-height: 200%;
          @include sp {
            font-size: 0.75rem;
            line-height: 180%;
          }
        }
        &__number {
          font-size: 5rem;
          font-weight: 900;
          font-family: "Noto Sans JP", sans-serif;;
          color: #FAF9F7;
          position: absolute;
          top: -3.75rem;
          left: 0;
          @include sp {
            font-size:3rem;
            top: -4.375rem;
          }
        }

        &:nth-of-type(4) {

          .item__title {
            font-size: 1.25rem;
            @include sp {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}



</style>