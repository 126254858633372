<template>
    <section id="faq" class="faq">
      <div class="faq__inner js-qa-main">
          <SectionTitle title="<span>F</span><span>a</span><span>q</span>"/>
          <div class="r-box">
            <h3 class="js-qa-fade01">よくあるご質問</h3>
            <div class="faq__content" v-for='item in items' :key='item.id' :class='"js-qa-trigger" + item.id'>
              <p class="question">{{ item.Title }}</p>
              <p class="answer">{{ item.Text }}</p>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {id : 1,Title : '業務の引き継ぎは必要ですか？',Text : 'A．専門の担当者がお伺いして、現状の経理業務についてヒアリングさせていただき業務の引き継ぎを行います。業務内容により、一定期間ご訪問にて経理業務を行うことも可能ですので、スムーズに業務を移行することができます。',},
        {id : 2,Title : '会計ソフトの変更は必要ですか？',Text : 'A．状況を伺ったのちに経理フローをより合理化するため、会計ソフトの変更をお願いする場合がございますが、これまでの会計データの移行もしっかりサポートいたします。ご要望をお伺いし適切なプランをご提案いたします。',},
        {id : 3,Title : '導入に要する時間は？',Text : 'A．単純に業務を引き継ぐのではなく、業務改善・効率化を並行して進めるため、数ヶ月ほどの導入期間をいただいております。ご事情によりお急ぎの場合はご相談ください。',},
        {id : 4,Title : '「給与計算だけ」など、部分的な業務の依頼はできますか？',Text : 'A．はい、可能です。\nただし部分的な業務のみでは重複する作業が残ることがありますので、経理業務全体をご依頼いただくことをおすすめしております。より効率化を図ることができます。',},
        {id : 5,Title : '担当スタッフが休みの場合の対応はどうなりますか？',Text : 'A．チームで経理業務を行っていますので、通常通りの対応が可能です。',},
        {id : 6,Title : 'イレギュラーな業務が発生した場合は？',Text : 'A．業務内容に応じ、お客様とご相談の上、最適な方法で対処いたします。お客様ごとの経理業務マニュアルを日々アップデートしていますので、同じ事象に関してはスムーズに対応することが可能です。',},
        {id : 7,Title : 'マニュアルがない業務も依頼できますか？',Text : 'A．はい、可能です。\nヒアリングをさせていただく際に業務フローをお伺いさせていただきます。',},
      ]
    }
  },
  methods: {
    AccountingQa() {
      gsap.fromTo('.js-qa-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-qa-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-qa-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-qa-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-qa-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-qa-trigger1',  start: 'center 70%'}});
      gsap.fromTo('.js-qa-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-qa-trigger2',  start: 'center 70%'}});
      gsap.fromTo('.js-qa-trigger3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-qa-trigger3',  start: 'center 70%'}});
      gsap.fromTo('.js-qa-trigger4' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-qa-trigger4',  start: 'center 70%'}});
      gsap.fromTo('.js-qa-trigger5' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-qa-trigger5',  start: 'center 70%'}});
      gsap.fromTo('.js-qa-trigger6' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-qa-trigger6',  start: 'center 70%'}});
      gsap.fromTo('.js-qa-trigger7' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-qa-trigger7',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.AccountingQa();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}


.faq {
  padding:2rem 0 5rem 0;
  background-color: #F1EEE7;
  @include sp {
    padding: 1rem 0 2rem 0;
  }
  h3 {
    @include h2-title(13%);
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
     @include sp {
      width: 1.5rem;
     }
    }
  }

  .r-box {
    margin-left: 3.5rem;
    @include sp {
      width:  100%;
      margin-left: 1.25rem;
      padding-top: 1.5rem;
    }
  }
 
 &__content {
   margin-bottom: 2.5rem;
    @include sp {
      width: 15.4375rem;
      margin: 0 0 2.5rem 0;
    }
   .question {
     font-size: 1.5rem;
     font-weight: 500;
     margin-bottom: 1rem;
     padding-left: 3rem;
     position: relative;
    @include sp {
      font-size: 1rem;
      line-height: 140%;
      margin-bottom: 0.5rem;
      padding-left: 1.875rem;
    }
     &::before {
       content: '';
       position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 2.25rem;
        height: 2.25rem;
        background-image: url('~@/assets/img/q.png');
        background-repeat: no-repeat;
        background-size: 2.25rem;
        @include sp {
          top: 0;
          transform: translateY(0);
          width: 1.4063rem;
          height: 1.4063rem;
          background-size: 1.4063rem;
        }
     }
   }
   .answer {
     width: 52.5rem;
     font-size: 1rem;
     font-weight: 500;
     line-height: 200%;
     background-color: #fff;
     border-radius: 0.625rem;
     padding: 1rem 1.5rem;
     white-space: pre-line;
    @include sp {
      width: 15rem;
      font-size: 0.75rem;
      line-height: 180%;
      padding: 1rem 1.5rem;
    }
   }
 }
}
</style>