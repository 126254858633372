<template>
  <div>
    <h3 class="mb-2 mb-md-4 js-s-fade01">
      <span class="inline-block">徹底的なヒアリングに<br class="sp">基づく、<br class="sp">最適なサービス</span>
    </h3>
    <p class="mb-6 mb-md-10 pr-10 pr-md-0 js-s-fade02">
      <span class="inline-block">経理代行、ソフトウェア受託開発でバックオフィス業務の効率化、生産性の向上を図り、さらなる売上拡充のためのシステム開発、<br class="sp">ホームページ作成を行います。</span>
    </p>

    <div class="service__arrow-box js-s-fade04">
      <img src="@/assets/img/top/arrow-left.png" alt="コスト削減・生産性向上">
      <img src="@/assets/img/top/arrow-right.png" alt="更なる売上拡大">
    </div>
     <p class="sp sp-arrow sp-arrow--top js-s-fade05">コスト削減・生産性向上</p>
    <v-row class="pb-6 -mt-8 pr-10 pr-md-0 frame-position js-s-fade03">
      <div class="frame-orange"></div>
      <div class="frame-white"></div>
      <v-col
        v-for="(card,index) in cards"
        :key="index"
        cols="12"
        :md="card.flex"
        class="d-flex px-2 py-8 justify-center"
      >
        <v-card
          flat
          class="rounded-b-0 rounded-t-small md:rounded-t-large d-flex flex-column  max-w-[269.33px]"
          >
          <v-card-title class="text-pfblack-100 text-base md:text-xl lg:text-2xl font-bold flex-grow justify-center px-8 h-full py-6 content-center">
            <span v-for="(title,index) in card.title" :key="index" class="md:leading-[1.4] lg:leading-[1.6]">{{ title }}</span>
          </v-card-title>
          <v-card-text class="text-pfblack-100 px-6  h-full d-flex flex-column flex-grow flex-wrap items-start">
            <span v-for="(desc,index) in card.desc" :key="index" class="inline-block text-xs md:text-sm lg:text-base leading-[1.8] md:leading-[1.8] lg:leading-loose">{{ desc }}</span>
          </v-card-text>
          <v-card-actions class="-mb-9 md:-mb-12 px-4">
            <ButtonService  @click.native="reset()" :link="card.link" :bgcolor="card.bgcolor" :txtcolor="card.txtcolor" :bdcolor="card.bdcolor" :txtwhite="card.txtwhite" txtsize="base" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
     <p class="sp sp-arrow sp-arrow--bottom  js-s-fade06">更なる売上拡大</p>
  </div>
</template>
<script>
import ButtonService from '@/components/modules/ButtonService';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  components:{
    ButtonService
  },
  data(){
    return {
      cards: [
        { title: ['経理代行'], btnUrl: 'https://google.com', desc: ['経理業務に課題を感じている会社様に対して、経理部門を弊社で請負います。','請求書発行や入金確認等の経理システムを導入したいなど、お客様に寄り添ったシステムを開発して業務を効率化・利益改善に繋げます！'], flex: 4, link:'/accounting' },
        { title: ['ソフト','ウェア','受託開発'], btnUrl: 'https://google.com', desc: ['自社開発であるため、コストが割高にならず最適な費用で開発を行うことができます。更にお客様一人ひとりに合わせたソフトウェアを開発するため、導入した後にありがちな「使えなくなった」といったトラブルを防ぎます。'], flex: 4, link:'/development'},
        { title: ['HP・EC','サイト','制作'], btnUrl: 'https://google.com', desc: ['お客様のサービス用のLP、またオンライン上で商品の注文をすることができるECサイトの制作を行います。ヒアリングからデザイン、構築まですべてお任せください。HPからの集客をお手伝いし、売上拡大に貢献します。'], flex: 4, link:'/WebProduct' },
      ],
    }
  },
  methods: {
      reset: function () {
        this.$router.go({path: this.$router.currentRoute.path, force: true})
      },
    TopService() {
      gsap.fromTo('.js-s-fade01' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1,y: 0,scrollTrigger: {  trigger: '.js-s-fade01',  start: 'center 70%'}});
      gsap.fromTo('.js-s-fade02' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1,y: 0, scrollTrigger: {  trigger: '.js-s-fade02',  start: 'center 70%'}});
      gsap.fromTo('.js-s-fade03' , 0.5 ,{opacity:0 ,x : -100,},{opacity:1,x : 0,scrollTrigger: {  trigger: '.js-s-fade03',  start: 'top 70%',}});
      gsap.fromTo('.js-s-fade04' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-s-fade04',  start: 'center 70%'}});
      gsap.fromTo('.js-s-fade05' , 0.5 ,{opacity:0 ,y: 20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-s-fade05',  start: 'center 70%'}});
      gsap.fromTo('.js-s-fade06' , 0.5 ,{opacity:0 ,y: -20,},{opacity:1, y: 0,scrollTrigger: {  trigger: '.js-s-fade06',  start: 'center 70%'}});
    }

  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.TopService();
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";

.service__arrow-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  @include sp {
    display: none;
  }
  img {
    display: block;
    width: 16.8125rem;
    &:nth-of-type(1) {
      margin-left: -0.625rem;
    }
    &:nth-of-type(2) {
      margin-right: -0.625rem;
    }
  }
}
.sp-arrow {
  @include sp {
    width: 15.4375rem;
    font-size: 0.875rem;
    font-weight: bold;
    text-align: center;
    background-size: 15.4375rem;
    position: relative;
    left: 50%;
    transform: translateX(-59%);
  }
  &--top {
    color: #fff;
    background-image: url('~@/assets/img/top/arrow-top.png');
    padding: 1.25rem 0 0.9375rem 0;
    margin-bottom: 1.375rem;
  }
  &--bottom {
    background-image: url('~@/assets/img/top/arrow-bottom.png');
    padding: 0.9375rem 0 1.25rem 0;
    margin-top: 1rem;
  }
}
.frame-position {
  position: relative;
}

.frame-orange {
  position: absolute;
  pointer-events: none;
  top: 0.9375rem;
  width: 67%;
  height: 96%;
  border: 0.1875rem solid #E56910;
  border-radius: 1.25rem;
  z-index: 1;
  @include sp {
    width: 19rem;
    height: 65%;
    left: 50%;
    transform: translateX(-57%);
  }
  @include sp02 {
    width: 88%;
    height: 65%;
    left: -0.0625rem;
    transform: translateX(0);
  }
}
.frame-white {
  position: absolute;
  pointer-events: none;
  top: 1.375rem;
  right: 0;
  width: 67%;
  height: 96%;
  border: 0.1875rem solid #fff;
  border-radius: 1.25rem;
  z-index: 2;
  @include sp {
    width: 21rem;
    height: 65%;
    top: 34%;
    right: 50%;
    transform: translateX(43.5%);
  }
  @include sp02 {
    width: 94%;
    height: 65%;
    top: 34%;
    right: 9%;
    transform: translateX(0);
  }
}
</style>