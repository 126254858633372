<template>
  <div class="title__vertical" :class="`text-${getColor}`">
      <h2 class="md:text-8 font-bold text-uppercase mx-px tracking-widest" v-html="getTitle"></h2>
  </div>
</template>
<script>
export default {
  props:{
    title:{
      type:String
    },
    color:{
      type:String,
      default: 'pforange-200'
    },
  },
  data(){
    return {
      getTitle: this.title,
      getColor: this.color,
    }
  }
}
</script>

<style scoped>
.title__vertical {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  width: 100%;
  display: flex;
  align-items: center;

}
.title__vertical::before {
  content: '';
  display: inline-block;
	width: 2px;
	height: 46px;
	background-color: currentColor;
  margin-bottom: .5rem;
}
.title__vertical h2 {
  font-size: 1rem;
}
@screen md {
  .title__vertical::before {
    width: 3px;
    height: 92px;
    margin-bottom: 1rem;
  }
}
</style>