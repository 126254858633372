import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from '../router';
import '@/assets/tailwind.css'
import './plugins/vee-validate.js'
import './plugins/vue-scrollto.js'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')



function noScroll (event) { event.preventDefault() }
Vue.prototype.$noScroll = function () {
  document.addEventListener('touchmove', noScroll, { passive: false })
  document.addEventListener('mousewheel', noScroll, { passive: false })
}
Vue.prototype.$returnScroll = function () {
  document.removeEventListener('touchmove', noScroll, { passive: false })
  document.removeEventListener('mousewheel', noScroll, { passive: false })
}