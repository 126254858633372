<template>
    <section id="benefit" class="case bg-pfgray-100">
      <div class="case__inner js-c-main">
          <SectionTitle title="<span>C</span><span>a</span><span>s</span><span>e</span> <span>S</span><span>t</span><span>u</span><span>d</span><span>y</span><span>＆</span> <span>p</span><span>r</span><span>i</span><span>c</span><span>e</span>"/>
          <div class="r-box">
            <h3 class="js-case-fade01">サービス例と料金</h3>
            <div class="case__inner-cover" v-for='item in items' :key='item.id' :class='"js-case-fade" + item.id'>
              <div class="flex-box">
                <div class="l-item">
                  <p class="l-item__sub-title">{{ item.SubTitle }}</p>
                  <p class="l-item__title">{{ item.Title }}</p>
                  <p class="l-item__price" v-html='item.Price'></p>
                </div>
                <div class="r-item">
                  <p class="r-item__text" v-html='item.Text'></p>
                </div>
              </div>
              <p class="number">{{ item.id }}</p>
            </div>
          </div>
      </div>
      <p class="case__text js-case-fade02">上記は参考価格となります。<br>ご要望をお伺いした上でお見積・ご提案させていただきます。<br>お気軽にお問い合わせください！</p>
    </section>
</template>

<script>
import  gsap  from 'gsap';
import  ScrollTrigger  from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {id : 1,Title : 'ホームページ制作',SubTitle : 'リニューアルも新規も\n低コストでお受けします！',Text : '・5ページ\n<span class="span01">（例：トップ、特徴、会社情報、お問い合わせ、プライバシーポリシー等）</span>\n・スマートフォンの表示にも対応\n・納期2ヶ月\nご要望をお伺いして適切な仕様と費用をご提案します',Price : '<span class="span01">330,000円</span><span class="span02">(税込)</span>'},
        {id : 2,Title : '更新作業代行(月額)',SubTitle : 'パソコンが苦手な方でも安心！',Text : '・月2回のホームページ内容の更新作業\n<span class="span01">（例：お知らせの更新、画像の差し替え、商品の追加・削除等）</span>',Price : '<span class="span01">11,000円</span><span class="span02">(税込)</span>'},
        {id : 3,Title : 'WEBコンサルティング',SubTitle : 'もっと集客に\n力を入れたいに方にオススメ！',Text : '・集客のためのホームページ分析や改善策の提案\n<span class="span01">（例：投稿や広告の表示回数、クリックされた回数などの分析・改善等）</span>',Price : '<span class="span01">33,000円</span><span class="span02">(税込)</span>'},
        {id : 4,Title : 'チラシ3枚+パンフレット1枚込み',SubTitle : 'WEB運用と合わせて！\n幅広いお客様にダイレクトに伝える',Text : '・集客のためのホームページとセットで幅広い年齢層や地域の\nお客様に向けて効果的な宣伝が見込めます。\n<span class="span01">（例：商品やサービス、ホームページURLを掲載したチラシ等）</span>',Price : '<span class="span01">110,000円</span><span class="span02">(税込)</span>'},
      ]
    }
  },
  methods: {
    WebCase() {
      gsap.fromTo('.js-c-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-c-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-case-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-case-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-case-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-case-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-case-fade1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-case-fade1',start: 'center 70%'}});
      gsap.fromTo('.js-case-fade2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-case-fade2',start: 'center 70%'}});
      gsap.fromTo('.js-case-fade3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-case-fade3',start: 'center 70%'}});
      gsap.fromTo('.js-case-fade4' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-case-fade4',start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
   this.$nextTick(function () {
      this.WebCase();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.case {
  padding:2rem 0 5rem 0;
  @include sp {
    padding: 2.5rem 0;
  }
  h3 {
    @include h2-title(9%);
  }
  &__inner {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }

    &-cover {
      background-color: #fff;
      box-shadow: 0.25rem 0.25rem 0.75rem rgba(51, 51, 51, 0.04);
      border-radius: 1.25rem;
      margin-bottom: 2.5rem;
      padding: 2.5rem 0.3125rem 2.5rem 1.1875rem;
      position: relative;
      @include sp {
      width: 15.4375rem;
      border-radius: 0.625rem;
      margin: 0 0 1.5rem 0;
      padding: 1.25rem 0rem;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 5.625rem;
        height: 5.625rem;
        background-image: url('~@/assets/img/accent.png');
        background-repeat: no-repeat;
        background-size: 5.625rem;
        @include sp {
          width: 3.5rem;
          height: 3.5rem;
          background-size: 3.5rem;
        }
      }
      &:nth-of-type(4) {
        .l-item__title {
         font-size: 1.125rem;
         @include sp {
           font-size:0.875rem;
         }
        }
      }
      .number {
        position: absolute;
        top: 0.75rem;
        left: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
        color: #fff;
        @include sp {
          top: 0.375rem;
          left: 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }


  .flex-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sp {
        display: block;
        width: 15.4375rem;
      }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
    }
  }
  .l-item {
    width: 18.125rem;
    @include sp {
      width: 15.4375rem;
      margin: 0 auto;
    }
    &__sub-title {
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      line-height: 160%;
      margin-bottom: 1rem;
      white-space: pre-line;
      @include sp {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      color:#E56910;
      text-align: center;
      margin-bottom: 0.25rem;
      @include sp {
        font-size: 0.875rem;
      }
    }
    &__price {
      font-size: 1rem;
      font-weight: 700;
      text-align: center;
      @include sp {
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }
      &::v-deep {
        .span01 {
          font-size: 1.5rem;
          @include sp {
            font-size: 1.25rem;
          }
        }
        .span02 {
          font-weight: 400;
          @include sp {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
  .r-item {
    width: 31.9375rem;
    position: relative;
    @include sp {
      width: 15.4375rem;
      margin: 0 auto;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 0.0625rem;
      height: 9.3125rem;
      background-color: #cccccc;
      @include sp {
        width: 10.5rem;
        height: 0.0625rem;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__text {
      font-size: 1rem;
      line-height: 180%;
      padding-left: 1.5rem;
      white-space: pre-line;
      @include sp {
        font-size: 0.75rem;
        padding-left: 0;
        padding: 1rem 1.25rem 0 1.25rem;
      }
      &::v-deep {
        .span01 {
          font-size: 1rem;
          @include sp {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  &__text {
    width: fit-content;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    padding-left: 5.625rem;
      @include sp {
        font-size: 0.75rem;
        padding-left: 0;
      }
  }
}
</style>