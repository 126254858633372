<template>
  <div class="pr-10 pr-md-0 w-full">
    <h3 class="mb-4 mb-md-4 text-center -mx-5 pt-[80px] md:pt-[162px]">
      <span class="inline-block">お問い合わせ</span>
      <span class="inline-block">ありがとうございます。</span>
    </h3>
    <v-row class="justify-center  sm:px-10  w-full mx-auto pb-[91px] md:pb-[213px]">
      <v-col cols="12" md="12" class="w-full">
        <p class="text-center mb-0 mr-[-17px]">
          <span class="md:inline-block">
            <span class="inline-block">ご入力いただいた内容の送信が</span>
            <span class="inline-block">完了いたしました。</span>
          </span>
          <br>
          <span class="inline-block">確認でき次第、担当よりご連絡いたします。</span>
        </p>
      </v-col>
      <v-col cols="12" md="12" class="text-center">
        <ButtonService btnname="トップページに戻る" link="/" arrow class="px-3 px-md-7 max-w-[336px] md:text-xl" />
      </v-col> 
    </v-row>
  </div>
</template>
<script>
import ButtonService from '@/components/modules/ButtonService';
export default {
  components:{
    ButtonService,
  },
  props:{
    inputs:{
      type:Array
    }
  }
}
</script>