import Vue from 'vue';
import { ValidationProvider,ValidationObserver, localize, setInteractionMode, extend } from 'vee-validate';
import { required, email, regex } from 'vee-validate/dist/rules';

// Add a rule.
// エラーメッセージの日本語化
import ja from 'vee-validate/dist/locale/ja.json';
localize('ja', ja);

// インタラクションモードをセット(グローバルで適用)
setInteractionMode('eager');

extend('secret', {
  validate: value => value === 'example',
  message: 'This is not the magic word'
});

extend('even', value => {
  return value % 2 === 0;
});

extend('positive', value => {
  return value >= 0;
});

// No message specified.
extend('email', email);

// Override the default message.
extend('required', required);

// extend('url', url);

extend('regex', regex);
// extend('required', {
//   ...required,
//   message: 'This field is required'
// });

// extend('phone', {
//   message (fieldName) {
//       return `${fieldName} is not a valid phone number`;
//   },
//   validate (value) {
//       return new Promise(resolve => {
//           let phone = new PhoneNumber(value);
//           resolve({ valid: phone.isValid() })
//       });
//   }
// });

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);