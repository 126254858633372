<template>
    <section id="happy" class="happy">
      <div class="happy__inner js-h-main">
          <SectionTitle title="<span>H</span><span>a</span><span>p</span><span>p</span><span>y</span> <span>C</span><span>u</span><span>s</span><span>t</span><span>o</span><span>m</span><span>e</span><span>r</span><span>s</span>"/>

          <div class="r-box">
            <h3 class="js-h-fade01">お客様の声</h3>
            <div class="content" v-for='item in items' :key='item.id' :class='"js-h-trigger" + item.id'>
              <div class="img" :style="{ 'background-image': 'url(' + item.Img + ')' }"></div>
              <div class="text-box">
                <p class="m-title">{{ item.Title }}</p>
                <p class="text" v-html='item.Text'></p>
                <p class="name">{{ item.Name }}</p>
              </div>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {id : '1',Title : '経理業務を丸投げできて、IT導入支援もしてくれるので業務がかなりスムーズになった。',Text : '<span class="pc">本当の意味で何でも相談できる会社さんです（笑）</span>弊社は仕事柄、現場に出ることが多く経理業務が後回しになりがちでした。プロフィットバンクさんでは、経理業務を丸投げできるし、情報共有のためのIT導入支援もしてくれるので、業務がかなりスムーズになりました。仕事も早いのでとても助かっています。<span>今後とも宜しくお願いします！</span>',Name : '株式会社 SSTECH　様',Img : require('@/assets/img/accounting/company01.png'),},
        {id : '2',Title : 'コストが安価でもサービスの質は安心！新規事業の相談にも乗ってもらい助かってます。',Text : '<span>毎月の集金業務が数百件あり一人で処理をすることが負担でした。</span><span class="pc">経理の方を直接雇用する場合と比較してコストが安価だったので半信半疑でしたが、安心してお任せすることができ、今はとても助かっています。</span>経理業務だけではなく、新規事業の立ち上げの相談にも乗ってもらっています。今後とも宜しくお願いします。',Name : '株式会社 CloudNine　様',Img : require('@/assets/img/accounting/company02.png'),}
      ]
    }
  },
  methods: {
    AccountingHappy() {
      gsap.fromTo('.js-h-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-h-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-h-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-h-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-h-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-h-trigger1',  start: 'center 70%'}});
      gsap.fromTo('.js-h-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-h-trigger2',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.AccountingHappy();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}


.happy {
  padding:2rem 0 5rem 0;
  background-color: #FAF9F7;;
  @include sp {
    padding: 1rem 0 1.2rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 3rem auto 2.5rem auto;
  @include sp {
    font-size: 1.5rem;
    margin: 0 0 1.5rem 0;
    padding-left: 22%;
  }
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
     @include sp {
      width: 1.5rem;
     }
    }
  }

  .r-box {
    margin-left: 3.5rem;
    @include sp {
      width:  100%;
      margin-left: 1.25rem;
      padding-top: 1.5rem;
    }
  }
  .content {
    width: 52.5rem;
    min-height: 23.5rem;
    background-color: #fff;
    border-radius: 1.25rem;
    box-shadow: 0.25rem 0.25rem 0.75rem rgba(51, 51, 51, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 3rem 2.5rem;
    margin-bottom: 2.5rem;
    @include sp {
      width: 15.4375rem;
      display: block;
      border-radius: 0.5rem;
      padding: 0.625rem;
    }
    .img {
      width: 14.875rem;
      height: 17.5rem;
      background-size: 12.5rem;
      background-position: center;
      background-repeat: no-repeat;
      @include sp {
        width: 13.5625rem;
        height: 7.125rem;
        background-size: 13rem;
      }
    }
    .text-box {
      width: 30.625rem;
      @include sp {
        width: 100%;
      }
      .m-title {
        font-size: 1.5rem;
        font-weight: bold;
        color:#E56910;
        line-height: 1.6;
        @include sp {
          font-size: 1rem;
        }
      }
      .text {
        font-size: 1rem;
        line-height: 1.5;
        margin-top: 1rem;
        white-space:pre-wrap;
        @include sp {
          font-size: 0.75rem;
        }
        ::v-deep span {
          display: block;
        }
        ::v-deep .pc{
          @include sp {
            display: inline;
          }
        }
        ::v-deep .sp {
          @include sp {
            display: block;
          }
        }
      }
      .name {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1rem;
        @include sp {
          font-size: 0.75rem;
          text-align: right;
        }
      }
    }
  }

}
</style>