<template>
  <div id="accounting">
    <Breadcrumb />
    <NAV />
    <FV />
    <CV />
    <Problem />
    <Feature />
    <Case />
    <Price />
    <Happy />
    <QA />
    <CV />
  </div>
</template>
<script>
import Breadcrumb from "@/components/organisms/accounting/Breadcrumb.vue";
import NAV from "@/components/organisms/accounting/PageNav.vue";
import FV from "@/components/organisms/accounting/Hero.vue";
import CV from "@/components/organisms/accounting/CVArea.vue";
import Problem from "@/components/organisms/accounting/Problem.vue";
import Case from "@/components/organisms/accounting/Case.vue";
import Price from "@/components/organisms/accounting/Price.vue";
import Happy from "@/components/organisms/accounting/Happy.vue";
import QA from "@/components/organisms/accounting/Faq.vue";
import Feature from "@/components/organisms/accounting/Feature.vue";

export default {
  components: {
    Breadcrumb,
    NAV,
    FV,
    CV,
    Problem,
    Case,
    Feature,
    Price,
    Happy,
    QA,
  },
  mounted() {
    this.$nextTick(() => {
      this.$noScroll();
      setTimeout(this.$returnScroll, 800);
    });
  },
  watch: {
    hogeList: function () {
      this.$nextTick(() => {
        var positionY = sessionStorage.getItem("positionY");
        scrollTo(0, positionY);
        setTimeout(function () {
          scrollTo(0, positionY);
        }, 500);
      });
    },
  },
};
</script>