<template>
    <section id="problem" class="problem">
      <div class="problem__inner js-p-main">
          <SectionTitle title="<span>P</span><span>R</span><span>O</span><span>B</span><span>L</span><span>E</span><span>M</span>"/>
          <div class="r-box">
            <h3 class="js-p-fade01">日々の業務でこんな<br class="sp">お悩みはありませんか？</h3>
            <div class="img-box js-p-fade02">
            <picture>
              <source media="(min-width: 960px)" srcset="~@/assets/img/development/p-img01.png"/>
              <source media="(min-width: 100px)" srcset="~@/assets/img/development/p-img01-sp.png"/>
              <img src="@/assets/img/development/p-img01.png">
            </picture>
            <picture>
              <source media="(min-width: 960px)" srcset="~@/assets/img/development/p-img02.png"/>
              <source media="(min-width: 100px)" srcset="~@/assets/img/development/p-img03-sp.png"/>
              <img src="@/assets/img/development/p-img01.png">
            </picture>
            <picture>
              <source media="(min-width: 960px)" srcset="~@/assets/img/development/p-img03.png"/>
              <source media="(min-width: 100px)" srcset="~@/assets/img/development/p-img02-sp.png"/>
              <img src="@/assets/img/development/p-img01.png">
            </picture>
            <picture>
              <source media="(min-width: 960px)" srcset="~@/assets/img/development/p-img04.png"/>
              <source media="(min-width: 100px)" srcset="~@/assets/img/development/p-img04-sp.png"/>
              <img src="@/assets/img/development/p-img01.png">
            </picture>

            </div>
            <img src="@/assets/img/accounting/arrow.png" alt="" class="arrow js-p-fade03">
            <p class="arrow-text js-p-fade04"><span class="line">その業務の<span class="bold">お悩み</span>は私たちが<br class="sp"><span class="bold">解決</span>します！！</span></p>
          
          
            <div class="problem__more">
              <p class="m-title js-p-fade05">プロフィットバンクなら…</p>
              <p class="text js-p-fade05">お客様に合わせた最適なソフトウェアを<br class="sp">開発して<br class="pc">業務の効率を大幅にUPします。</p>
              <ul class="js-p-fade06">
                <li>お客様に合ったソフトウェア・システムを開発いたします。</li>
                <li>現在お使いのソフトウェア・システムの不具合を修正いたします。</li>
                <li>要件定義や開発・テスト等の単体業務のみも対応可能。</li>
                <li>ソフトウェア・システムの課題をトータルで解決します。</li>
                <li>自動化したい業務があれば、相談を承っております。</li>
              </ul>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import  gsap  from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";
// import Vue from 'vue';





export default {
  components: {
    SectionTitle,
  },
  methods: {
    DeveProblem() {
      gsap.fromTo('.js-p-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-p-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-p-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade01',  start: 'center 70%' }});
      gsap.fromTo('.js-p-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade02',  start: 'center 70%'}});
      gsap.fromTo('.js-p-fade03' , 0.5 ,{opacity:0 ,y : -20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade03',  start: 'center 70%'}});
      gsap.fromTo('.js-p-fade04' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade04',  start: 'center 70%'}});
      gsap.fromTo('.js-p-fade05' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade05',  start: 'center 70%'}});
      gsap.fromTo('.js-p-fade06 li' , {opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade06',  start: 'center 70%'},stagger : {  from : 'start',  amount : 0.5,}});
    }
  },
  mounted() {
  gsap.registerPlugin(ScrollTrigger)
   this.$nextTick(function () {
      this.DeveProblem();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.problem {
  padding:2rem 0 5rem 0;
  background-color: #FAF9F7;
  @include sp {
    padding: 2.5rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 4.5rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.6rem 0;
    padding-left: 8%;
  }
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 0;
    }
  }

  .img-box {
    width: 52.5rem;
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
      width: 15.4375rem;
      height: 670px;
    }
    picture {
      display: block;
      width: 14.0625rem;
      height: auto;
      position: relative;
      @include sp {
        width: 8.9375rem;
      }
     &:nth-of-type(2n) {
        position: relative;
        top: 14.375rem;
        @include sp {
          top: -8.125rem;
          right: -8.125rem;
        }
    }
    &:nth-of-type(3) {
      @include sp {
        top: -12.5rem;
      }
    }
    &:last-of-type {
      @include sp {
        top: -20rem;
      }
    }
      img {
        width: 100%;
      }
    }
  }

  .arrow {
    display: block;
    width: 7.25rem;
    height: auto;
    margin: 19rem auto 3.9375rem auto;
    @include sp {
      width: 3.75rem;
      margin: 3rem auto 2rem auto;
    }
  }
  .arrow-text {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    @include sp {
      font-size: 0.9375rem;
      line-height: 1.4;
    }
    .bold {
      font-size: 2.5rem;
    @include sp {
      font-size: 1.25rem;
    }
    }

  .line {
    background:linear-gradient(transparent 60%, rgba(242, 171, 52, 0.5) 60%);
  }
  }

  &__more {
    margin-top: 10rem;
    @include sp {
      margin-top: 5rem;
    }
    .m-title {
      font-size: 2.5rem;
      font-weight: bold;
      color: #E56910;
      margin-bottom: 1.5rem;
      text-align: center;
      @include sp {
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }
    }
    .text {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      line-height: 200%;
      margin-bottom: 3.5rem;
      @include sp {
        font-size: 0.75rem;
        margin-bottom: 1.5rem;
      }
    }

    ul {
      padding-left: 0;
      @include sp {
        width: 15.625rem;
        margin-left: 1rem;
      }
    }

    li {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      padding-left: 2.625rem;
      position: relative;
      @include sp {
        font-size: 0.875rem;
        padding-left: 1.4375rem;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 2.5rem;
        height: 2.5rem;
        background-image: url('~@/assets/img/development/check.png');
        background-size: 2.25rem;
        background-position: center;
        @include sp {
          top: 0.1875rem;
          transform: translateY(0);
          width: 1.125rem;
          height: 1.125rem;
          background-size: 1.125rem;
        }
      }
    }
  }
}
</style>