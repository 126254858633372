<template>
<div class="pr-10 pr-md-0 pb-0">
  <v-row class="mb-0">
    <v-col cols="12">
      <dt class="font-bold leading-[1.8] md:leading-[1.6]">住所</dt>
      <dd class="mb-2 mb-md-6">
        <p class="mb-0 leading-[1.8] md:leading-[1.6] ">{{ zip }}</p>
        <p class="mb-0 leading-[1.8] md:leading-[1.6] ">
          <span class="inline-block mr-4">{{ address[0] }}</span>
          <span class="inline-block">{{ address[1] }}</span>
        </p>
      </dd>
      <dd class="w-full relative pt-[48.8%] mb-2 mb-md-6">
        <iframe 
        class="absolute top-0 left-0 w-full h-full border-0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.0495460744587!2d135.49777961504876!3d34.729145480427164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e43b3d7c86db%3A0x5aeaf702f3d4ffe3!2z44CSNTMyLTAwMTEg5aSn6Ziq5bqc5aSn6Ziq5biC5reA5bed5Yy66KW_5Lit5bO277yV5LiB55uu77yX4oiS77yR77yRIOesrO-8mOaWsOWkp-mYquODk-ODqw!5e0!3m2!1sja!2sjp!4v1618023636355!5m2!1sja!2sjp" 
          width="840" 
          height="410" 
          style="border:0;" 
          allowfullscreen="">
        </iframe>
      </dd>
      <dd class="font-bold leading-[1.8] md:leading-[1.6]">
        <span class="inline-block">地下鉄御堂筋線 西中島南方駅、</span>
        <span class="inline-block">阪急 南方駅、地下鉄御堂筋線 新大阪駅、</span>
        <span class="inline-block">JR 新大阪駅から徒歩約8分</span>
        </dd>
    </v-col>

    <v-col cols="12" md="3" class="py-2 pr-md-0">
      <dt class="font-bold leading-[1.8] md:leading-[1.6]">TEL</dt>
      <dd class="leading-[1.8] md:leading-[1.6]">{{ tel }}</dd>
    </v-col>

    <v-col cols="12" md="9" class="py-2 pl-ml-0">
      <dt class="font-bold leading-[1.8] md:leading-[1.6]">受付時間</dt>
      <dd class="leading-[1.8] md:leading-[1.6]">
        <span class="inline-block">{{ businesshours[0] }}</span>
        <span class="inline-block">{{ businesshours[1] }}</span>
        </dd>
    </v-col>
  </v-row>
</div>
</template>
<script>
import { TEL, ZIP, ADDRESS, BUSINESSHOURS } from '@/common/constants';
export default {
  data() {
    return {
      zip: ZIP,
      tel: TEL,
      address: ADDRESS,
      businesshours: BUSINESSHOURS
    }
  }
}
</script>
