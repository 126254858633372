<template>
  <div class="pr-10 pr-md-0 w-full">
    <h3 class="mb-4 mb-md-4 text-center -mx-5 pt-[80px] md:pt-[162px]">
      <span class="inline-block">フォームの送信に</span>
      <span class="inline-block">失敗しました。</span>
    </h3>
    <v-row class="justify-center  sm:px-10  w-full mx-auto pb-[91px] md:pb-[213px] ">
      <v-col cols="12" md="12" class="w-full">
        <p class="text-center mb-0 mr-[-17px]">
          <span class="inline-block">申し訳ございません。</span>
          <span class="inline-block">もう一度フォームを送信いただくか、</span>
          <span class="inline-block">下記までご連絡ください。</span>
        </p>
        <p class="font-bold leading-[1.6] text-center mb-2 mb-md-4">
          <span class="mr-2 mr-md-4 text-xs md:text-2xl">TEL.</span>
          <span class="text-base md:text-[2rem]">{{tel}}</span>
        </p>
        <p class="mb-0 text-center ">
          <span class="inline-block">※受付時間：{{ businesshours[0]}}</span>
          <span class="inline-block">（土日祝はWEBよりお問い合わせください）</span>
        </p>
      </v-col>
      <v-col cols="12" md="12" class="text-center">
        <ButtonService btnname="トップページに戻る" link="/" arrow class="px-3 px-md-7 max-w-[336px] md:text-xl" />
      </v-col> 
    </v-row>
  </div>
</template>
<script>
import ButtonService from '@/components/modules/ButtonService';
import { TEL, BUSINESSHOURS } from '@/common/constants';

export default {
  components:{
    ButtonService,
  },
  props:{
    inputs:{
      type:Array
    }
  },
  data(){
    return {
      tel: TEL,
      businesshours: BUSINESSHOURS,
    }
  }
}
</script>