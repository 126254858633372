<template>
  <div>
    <!-- ナビゲーション -->
  
    <v-navigation-drawer
      v-cloak
      v-model="drawer"
      hide-overlay
      app
      class="top-55px lg:top-0 bg-pforange-200 lg:bg-white w-full lg:w-50 z-30 space"
    >
      <!-- ロゴ -->
      <div class="pt-7 pb-5 px-6 d-none d-lg-block">
        <Logo width="auto" class="mx-auto" />
      </div>
      <!-- メニューリスト -->
      <NavigationList :items="items" :buttons="buttons" @drawer='drawer = $event'/>
    </v-navigation-drawer>

    <!-- メニューバー -->
    <v-app-bar
      app
      flat
      color="white"
      class="d-lg-none z-40"
      height="55px"
      >
      <!-- ハンバーガー -->
      <div  class="app-bar-hamburger bg-pforange-200 absolute w-55px h-55px">
        <Hamburger :active="drawer" @click.native="drawer = !drawer" />
      </div>
      <v-toolbar-title class="flex-grow-1 text-center px-6">
        <Logo width="146px" class="mx-auto d-inline-block" />
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>
<script>
  import Hamburger from '@/components/atoms/Hamburger';
  import Logo from '@/components/modules/Logo';
  import NavigationList from '@/components/modules/NavigationList';

  export default {
    components: {
      Hamburger,
      Logo,
      NavigationList
    },
    data(){
      return{
        drawer: null,
        selectedItem: 0,
        items: [
          { title: 'MISSION', subtitle: '私たちの理念', icon: '' , to:'/#mission', scrollto:''},
          { title: 'CONCEPT', subtitle: 'コンセプト', icon: '', to:'/#concept', scrollto:''},
          { title: 'SERVICE', subtitle: 'サービス内容', icon: '', to:'/#service', scrollto:''},
          { title: '経理代行', subtitle: '', icon: '', to:'/accounting', scrollto:'body', sub:true },
          { title: 'ホームページ制作', subtitle: '', icon: '', to:'/WebProduct', scrollto:'body', sub:true },
          { title: 'ソフトウェア受託開発', subtitle: '', icon: '', to:'/development', scrollto:'body', sub:true },
          { title: 'Works & Partners', subtitle: '実績とパートナー', icon: '' , to:'/partners', scrollto:'body'},
          { title: 'MESSAGE', subtitle: '代表者メッセージ', icon: '' , to:'/#message', scrollto:''},
          { title: 'RECRUIT', subtitle: '採用情報', icon: '' , to:'/#recruit', scrollto:''},
          { title: '中途採用', subtitle: '', icon: '' , to:'/#recruit', scrollto:'' ,sub:true },
          { title: 'パートナー募集', subtitle: '', icon: '' , to:'/partners#recruit', scrollto:'/partners#recruit',sub:true },
          { title: 'ACCSESS', subtitle: 'アクセス', icon: '' , to:'/access', scrollto:'body'},
          { title: 'COMPANY', subtitle: '会社概要', icon: '' , to:'/company', scrollto:'body'},
        ],
        buttons: [
          {title: 'Contact', subtitle:'お問い合わせ', to:'/contact'}
        ]
      }
    },
  };
</script>
<style scoped>
nav {
  top: 55px !important;
  width: 100vh !important;
}

.app-bar-hamburger {

  display:flex;
  justify-content:center;
  align-items:center;
}
.v-app-bar >>> .v-toolbar__content {
  padding: 0;
}

@screen lg {
  nav {
    top: 0 !important;
    width: 200px !important;
  }
}
</style>