<template>
  <section id="development-hero" class="development-hero">
    <div class="unit">
      <div class="shape js-h-scale">
        <img src="@/assets/img/development/hero-mv.png" class="pc"/>
        <img src="@/assets/img/development/hero-mv-sp.png" class="sp"/>
      </div>
      <p class="mv_copy"><img src="@/assets/img/beyour.png" /></p>
      <p class="mv_copy"><img src="@/assets/img/partner.png" /></p>
    </div>
    <div class="catchcopy js-h-fade01">
      <p class="catchcopy-sub">新大阪/西中島を中心に地域に密着したサービス</p>
      <p class="catchcopy-main">
        その業務を<span class="span01">もっとスピーディに。</span><br />
        ITの力で<span>新しい価値</span>を創造します。
      </p>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default {

  methods: {
    DeveHero() {
      gsap.fromTo('.js-h-fade01' , 1 ,{opacity:0 , delay : 0.5},{opacity:1, delay : 0.5 });
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(function () {
      this.DeveHero();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
ul {
  padding: 0;
}

p {
  margin: 0;
}



.development-hero {
  min-height: 35.0625rem;
  background-color: #faf9f7;
  position: relative;
  top: -3.75rem;
  margin-bottom: -3.75rem;
  @include sp {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  @include sp02 {
    top: -3.75rem;
    min-height: 28.75rem;
    padding-top: 4.9rem;
    padding-bottom: 0;
    margin-bottom: -3.8125rem;
  }
  .shape {
    width: 55.625rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    @include sp {
      width: 30rem;
    }
    @include sp02 {
      width: 28.3125rem;
      top: 6.8rem;
      left: 0.8rem;
    }
  }

  .mv_copy {
    display: block;
    width: 70.8125rem;
    position: relative;
    z-index: 1;
    @include sp {
      width: 40rem;
    }
    @include sp02 {
      width: 20.25rem;
      max-width: 100%;
    }
    &:nth-of-type(1) {
      padding-top: 3.4375rem;
      @include sp02 {
        padding-top: 0;
      }
    }
    &:nth-of-type(2) {
      margin: 4.5rem 0 0 auto;
      @include sp02 {
        margin: 1.4rem 0 0 auto;
      }
    }
  }

  .catchcopy {
    width: fit-content;
    position: absolute;
    top: 53%;
    left: 40%;
    transform: translate(-40%, -53%);
    z-index: 2;
    @include sp02 {
    top: 75%;
    left: 10%;
    transform: translate(0, -75%);
    }
    &-sub {
      font-size: 1.5625rem;
      font-weight: 700;
      color: #1a1a1a;
      @include sp {
        font-size: 0.75rem;
      }
    }
    &-main {
      font-size: 2.5rem;
      font-weight: 700;
      font-family: "Noto Sans JP", sans-serif;
      line-height: 1.6;
        @include sp {
          font-size: 1.5rem;
        }
      @include sp02 {
        font-size: 1rem;
      }
      span {
        display: inline-block;
        width: fit-content;
        font-size: 3rem;
        font-weight: 900;
        letter-spacing: 0.076em;
        position: relative;
        @include sp {
          font-size: 2rem;
        }
        @include sp02 {
          font-size: 1.25rem;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0.14rem;
          left: 0;
          display: block;
          width: 100%;
          height: 1.125rem;
          background-color: #e56910;
          z-index: -1;
          @include sp02 {
            height: 0.625rem;
          }
        }
      }
      .span01 {
        &::before {
          content: "";
          position: absolute;
          bottom: 0.14rem;
          left: 0;
          display: block;
          width: 95%;
          height: 1.125rem;
          background-color: #e56910;
          z-index: -1;
          @include sp02 {
            height: 0.625rem;
          }
        }
      }
    }
  }
}
</style>