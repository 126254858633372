<template>
  <router-link to="/" @click.native="reset()">
    <v-img
      :width="getWidth"
      src="@/assets/img/logo.png"
      alt="株式会社プロフィットバンク"
    >
    </v-img>
  </router-link>
</template>
<script>
export default {
  props:{
    width:{
      type:String,
      default: ''
    }
  },
  data(){
    return {
      getWidth: this.width
    }
  },
  methods : {
      reset: function () {
        this.$router.go({path: this.$router.currentRoute.path, force: true})
      }
    }
  }
</script>