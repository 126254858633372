<template>
  <section id="works02" class="works02">
    <div class="works02__inner">
      <div class="space-box"></div>
      <div class="r-box">
        <h3 class="r-box__title js-works02-fade01">
          <span class="color-orange">Hamorebi</span>
          ×
          <span class="color-orange">Profitbnk</span>
        </h3>

        <div class="r-box__flex">
          <div class="item" v-for="item in items" :key="item.id" :class='"js-works02-trigger" + item.id'>
            <div class="img" :style="{ 'background-image': 'url(' + item.Img + ')' }"></div>
            <p class="m-title">{{ item.Title }}</p>
            <p class="sub-title">{{ item.SubTitle01 }}</p>
            <p class="text" v-html="item.Text01"></p>
            <p class="sub-title">{{ item.SubTitle02 }}</p>
            <p class="text" v-html="item.Text02"></p>
          </div>
        </div>

        <div class="r-box__flex r-box__flex02 js-works02-fade02" v-for='detail in details' :key='detail.id'>
          <div class="left-item js-works02-trigger3">
            <div class="left-img" :style="{ 'background-image': 'url(' + detail.Img + ')' }"></div>
            <p class="left-title">
              <span class="span01">{{ detail.Genre }}</span
              >{{ detail.Name }}<span class="span02">{{ detail.Address }}</span>
            </p>
          </div>
          <div class="right-item">
            <p class="right-text" v-html='detail.Text'></p>
            <div class="btn-cover">
              <!-- <ButtonService
                btnname="HPを見る"
                link="/contact"
                arrow
                class="px-3 px-md-7 md:text-xl"
              /> -->
              <div class="right-btn">準備中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import  gsap  from 'gsap';
import  ScrollTrigger  from 'gsap/ScrollTrigger';
// import ButtonService from "@/components/modules/ButtonService";

export default {
  components: {
    // ButtonService,
  },
  data() {
    return {
      items: [
        {
          id : '1',
          Img: require('@/assets/img/partners/syokuhin-s.jpg'),
          Title: "食品卸売業 S社",
          SubTitle01: "野菜、食料品のECサイトを開設",
          Text01: "返済不要の補助金を活用し、野菜などの食料品をインターネットで購入できるECサイトを制作しました。補助金等に関するお手続きはプロフィットバンクで行い、サイト制作はHamorebiにて行いました。",
          SubTitle02: "売上げアップと販路の拡大につながった！",
          Text02: " ECサイトで新規顧客の獲得につながり売り上げがアップ。新しい販路を増やし新たなサービスの拡充を行いました。返済不要の補助金を活用できたことで、サイト制作費の自己負担が大きく抑えられました。",
        },
        {
          id : '2',
          Img: require('@/assets/img/partners/insyoku-m.jpg'),
          Title: "飲食店 M社",
          SubTitle01: "お寿司テイクアウトの通販サイトを開設",
          Text01: "返済不要の補助金を活用し、お寿司のテイクアウト、配達を受注できる通販サイトを制作しました。補助金等に関するお手続きはプロフィットバンクで行い、サイト制作はHamorebiにて行いました。",
          SubTitle02: "集客アップで売上げ拡大！",
          Text02: " ECサイトで新規顧客の獲得につながり売上げがアップ。制作したサイトがお店の宣伝効果にもつながりました。返済不要の補助金を活用できたことで、サイト制作費の自己負担が大きく抑えられました。",
        },
      ],

      details : [
        {
          Img : require('@/assets/img/partners/hamorebi.jpg'),
          Genre : 'Webサイトのデザイン・制作',
          Name : 'Hamorebi',
          Address : '大阪府大阪市',
          Text : 'ディレクター、エンジニア、デザイナーが複数名在籍するクリエイティブチームです。ホームページやLP、ECサイトといったWebサイトの制作を得意としており、要件定義からデザイン、制作まで一貫してスピーディ且つ柔軟に行います。見栄えの良さだけではなく、Webサイトの目的に合わせて構成やツール等のご提案からお手伝いします。',
          Url : '',
        }
      ]
    };
  },
  methods: {
    PartnersWorks02() {
      gsap.fromTo('.js-works02-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-works02-fade01',start: 'center 70%' }});
      gsap.fromTo('.js-works02-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-works02-fade02',start: 'center 70%'}});

      gsap.fromTo('.js-works02-trigger1 .img' , 0.9 ,{backgroundSize:'150%' },{backgroundSize : '105%',scrollTrigger: {  trigger: '.js-works02-trigger1 .img',  start: 'center 70%'}});
      gsap.fromTo('.js-works02-trigger2 .img' , 0.9 ,{backgroundSize:'150%' },{backgroundSize : '105%',scrollTrigger: {  trigger: '.js-works02-trigger2 .img',  start: 'center 70%'}});
      gsap.fromTo('.js-works02-trigger3 .left-img' , 0.9 ,{backgroundSize:'120%' },{backgroundSize : '90%',scrollTrigger: {  trigger: '.js-works02-trigger3 .left-img',  start: 'center 70%'}});
      gsap.fromTo('.js-works02-trigger1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-works02-trigger1',  start: 'center 70%' }});
      gsap.fromTo('.js-works02-trigger2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-works02-trigger2',  start: 'center 70%'}});
      gsap.fromTo('.js-works02-trigger3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-works02-trigger3',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(function () {
      this.PartnersWorks02();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.works02 {
  padding: 2rem 0 1rem 0;
  background-color: #F1EEE7;
  @include sp {
    padding: 1rem 0 1.5rem 0;
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
      width: 100%;
      display: block;
      margin-left: 0;
      padding: 1.5rem 0 0 0;
    }

    &__text {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 10rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        margin-bottom: 5rem;
        padding-left: 1.5625rem;
      }
    }

    &__title {
      font-size: 3.75rem;
      font-family: "Noto Sans JP", sans-serif;
      text-align: center;
      margin-bottom: 3.3125rem;
      @include sp {
        width: fit-content;
        font-size: 1.25rem;
        line-height: 1;
        margin-bottom: 1.5625rem;
        padding-left: 28%;
      }
      .color-orange {
        font-size: 4.5rem;
        font-weight: bold;
        color: #e56910;
        @include sp {
          display: block;
          font-size: 2rem;
        }
      }
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.0625rem #cccccc solid;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
      @include sp {
        width: 15.4375rem;
        display: block;
        margin: 0 auto;
      }
      &:last-of-type {
        border-bottom: none;
        @include sp {
          margin-bottom: 0;
        }
      }
      &:first-of-type {
        .item:first-of-type {
          @include sp {
            padding-bottom: 24px;
          }
        }
      }
    }

    .item {
      width: 25rem;
      height: auto;
      @include sp {
        width: 15.4375rem;
      }
      .img {
        width: 100%;
        height: 18.25rem;
        background-color: #fff;
        background-size: cover;
        background-position: center;
        border-radius: 1.25rem;
        margin-bottom: 1.4375rem;
        @include sp {
          height: 12.0625rem;
          border-radius: 0.5rem;
          margin-bottom: 0.5625rem;
        }
      }
      .m-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        @include sp {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
      }
      .sub-title {
        font-size: 1rem;
        color: #e56910;
        margin-bottom: 0.25rem;
        @include sp {
          font-size: 0.75rem;
        }
      }
      .text {
        font-size: 1rem;
        line-height: 200%;
        margin-bottom: 1.5rem;
        @include sp {
          font-size: 0.75rem;
          line-height: 180%;
          margin-bottom: 1rem;
        }
      }
    }

    /*------  下の段  ------*/
    .left-item {
      width: 25rem;
      @include sp {
        width: 15.4375rem;
        padding-top: 1.5rem;
      }
      .left-img {
        width: 100%;
        height: 18.25rem;
        background-color: #fff;
        background-size: 18.75rem;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 1.25rem;
        margin-bottom: 1rem;
        @include sp {
          height: 11.25rem;
          background-size: 12.5rem;
          border-radius: 1rem;
        }
      }
      .left-title {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1;
        @include sp {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
        .span01 {
          display: block;
          font-size: 1rem;
          font-weight: normal;
          margin-bottom: 0.5rem;
          @include sp {
            font-size: 0.75rem;
          }
        }
        .span02 {
          display: block;
          font-size: 1rem;
          font-weight: normal;
          color: #9b9b9b;
          margin-top: 0.625rem;
          @include sp {
            font-size: 0.75rem;
          }
        }
      }
    }

    .right-item {
      width: 25rem;
      position: relative;
      @include sp {
        width: 15.4375rem;
        min-height: 16.25rem;
      }
      .right-text {
        font-size: 1rem;
        line-height: 200%;
        @include sp {
          font-size: 12px;
          line-height: 180%;
        }
      }
      .btn-cover {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
      .right-btn {
        width: 25rem;
        background-color: #fff;
        padding: 1.5rem 0;
        font-size: 1.25rem;
        font-weight: bold;
        color: #E56910;
        text-align: center;
        @include sp {
          width: 15.4375rem;
          font-size: 1rem;
          padding: 1rem 0;
        }
      }
    }
  }
}
</style>