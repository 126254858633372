<template>
    <section id="problem" class="problem">
      <div class="problem__inner js-p-main">
          <SectionTitle title="<span>P</span><span>R</span><span>O</span><span>B</span><span>L</span><span>E</span><span>M</span>"/>
          <div class="r-box">
            <h3 class="js-p-fade01">経理の事でこんな<br class="sp">お悩みはありませんか？</h3>
            <div class="img-box js-img js-p-fade02">
            <picture>
              <source media="(min-width: 960px)" srcset="~@/assets/img/accounting/problem-img01.png"/>
              <source media="(min-width: 100px)" srcset="~@/assets/img/accounting/problem-img01-sp.png"/>
              <img src="@/assets/img/accounting/problem-img01.png">
            </picture>

            <picture>
              <source media="(min-width: 960px)" srcset="~@/assets/img/accounting/problem-img02.png"/>
              <source media="(min-width: 100px)" srcset="~@/assets/img/accounting/problem-img02-sp.png"/>
              <img src="@/assets/img/accounting/problem-img02.png">
            </picture>

            <picture>
              <source media="(min-width: 960px)" srcset="~@/assets/img/accounting/problem-img03.png"/>
              <source media="(min-width: 100px)" srcset="~@/assets/img/accounting/problem-img03-sp.png"/>
              <img src="@/assets/img/accounting/problem-img03.png">
            </picture>
            </div>
            <img src="@/assets/img/accounting/arrow.png" alt="" class="arrow js-p-fade03">
            <p class="arrow-text js-p-fade04"><span class="line"><span class="bold">経理の悩み</span>は私たちが<br class="sp"><span class="bold">解決</span>します！！</span></p>
          </div>
      </div>
    </section>
</template>

<script>
import SectionTitle from "@/components/modules/SectionTitle";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default {
  components: {
    SectionTitle,
  },
  methods: {
    AccountingProblem() {
      gsap.fromTo('.js-p-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-p-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-p-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade01',  start: 'center 70%'}});
      gsap.fromTo('.js-p-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade02',  start: 'center 70%'}});
      gsap.fromTo('.js-p-fade03' , 0.5 ,{opacity:0 ,y : -20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade03',  start: 'center 70%'}});
      gsap.fromTo('.js-p-fade04' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-p-fade04',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.AccountingProblem();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.problem {
  padding:2rem 0 5rem 0;
  background-color: #FAF9F7;
  @include sp {
    padding: 2.5rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 4.5rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.6rem 0;
    padding-left: 8%;
  }
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
    }
  }

  .img-box {
    width: 52.5rem;
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
      width: 15.4375rem;
      height: 500px;
    }
    picture {
      display: block;
      width: 16.8125rem;
      height: auto;
      position: relative;
      &:nth-of-type(2) {
        @include sp {
          margin-left: auto;
          top: -6.875rem;
          right: -2.5rem;
        }
      }
      &:nth-of-type(3) {
        @include sp {
          top: -14.375rem;
        }
      }
      @include sp {
        width: 8.9375rem;
      }
      img {
        width: 100%;
      }
    }
  }

  .arrow {
    display: block;
    width: 7.25rem;
    height: auto;
    margin: 3rem auto 3.9375rem auto;
    @include sp {
      width: 3.75rem;
      margin: 3rem auto 2rem auto;
    }
  }
  .arrow-text {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    @include sp {
      font-size: 0.9375rem;
      line-height: 1.4;
    }
    .bold {
      font-size: 2.5rem;
    @include sp {
      font-size: 1.25rem;
    }
    }

  .line {
    background:linear-gradient(transparent 60%, rgba(242, 171, 52, 0.5) 60%);
  }
  }
}
</style>