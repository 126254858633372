<template>
  <section id="cvarea" class="cvarea bg-pfgray-100">
    <h2 class="unit">ホームページでお悩みの方は<br class="sp">お気軽にご相談ください。</h2>
    <div class="cvarea__inner unit">
      <div class="l-box">
        <p class="text">
          徹底的なヒアリングでお客様にぴったりな<br />サービスのご提案をさせていただきます。
        </p>
        <img src="@/assets/img/cv__img.png" alt="見積もりだけでもOK!!" class="sp">
        <v-col cols="12" md="12" class="sp-width">
          <ButtonService btnname="無料相談はこちらから" link="/contact" arrow class="px-3 px-md-7 max-w-[21rem] md:text-xl"/>
        </v-col>
      </div>
      <div class="r-box pc">
        <img src="@/assets/img/cv__img.png" alt="見積もりだけでもOK!!">
      </div>
    </div>
    <p class="cvarea__tell unit">お電話でのお問い合わせも受け付けております。 <br class="sp"><span class="tell-text">TEL. </span><span class="tell">06-6195-1860</span><br class="sp">※受付時間 平日 9:00-17:00</p>
  </section>
</template>

<script>
import ButtonService from "@/components/modules/ButtonService";

export default {
  components: {
    ButtonService,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
ul {
  padding: 0;
}

p {
  margin: 0;
}

.unit {
  width: 50rem;
  max-width: 100%;
}
.col-md-12.col-12 {
  @include sp {
    text-align: center;
  }
}


/*  CVArea  */
.cvarea {
  padding: 5rem 0;
  background-color: #FAF9F7;
  @include sp {
    padding: 2.5rem 0;
  }
  h2 {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    color: #E56910;
    margin: 0 auto 2.625rem auto;
    @include sp {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom:0.5rem;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 0.625rem auto;
    @include sp {
      display: block;
    }
    .l-box {
      .text {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.7;
        color: #1A1A1A;
        margin-bottom: 2.5rem;
        @include sp {
          font-size: 0.75rem;
          text-align: center;
          margin-bottom: 0.25rem;
        }
      }
      img {
        width: 20.4375rem;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .r-box {
      img {
        display: block;
        width: 21.25rem;
        height: auto;
      }
    }
    .sp-width {
      @include sp {
        width: 17rem;
        margin: 0 auto;
      }
    }
  }
  &__tell {
    font-size: 1rem;
    font-weight: 500;
    margin: 0 auto;
    @include sp {
      font-size: 0.75rem;
      text-align: center;
    }
    .tell-text {
      @include sp {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .tell {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.875rem;
      @include sp {
        font-size: 1.5rem;
        text-align: center;
        margin: 0.25rem auto;
      }
    }
  }
}
</style>