<template>
    <section id="faq" class="faq">
      <div class="faq__inner js-qa-main">
          <SectionTitle title="<span>F</span><span>a</span><span>q</span>"/>
          <div class="r-box">
            <h3 class="js-faq-fade01">よくあるご質問</h3>
            <div class="faq__content" v-for='item in items' :key='item.id' :class='"js-faq-fade" + item.id'>
              <p class="question">{{ item.Title }}</p>
              <p class="answer">{{ item.Text }}</p>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import  gsap  from 'gsap';
import  ScrollTrigger  from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      items : [
        {id : 1,Title : 'ホームページ制作にはどれくらいの期間がかかりますか？',Text : 'A．お申込みから掲載内容の決定までの打ち合わせに数週間〜1か月、そこから完成までに\n約数週間〜1か月となり合計1か月〜2か月になります。\n制作内容によっても異なりますので、詳しくはお問い合せ下さい。',},
        {id : 2,Title : '大阪以外の遠方でも制作していただけますか？',Text : 'A．はい。可能です。Zoom等のテレビ電話を用いてオンラインミーティングでお打合せをさせていただきます。\n大阪近辺のお客様であれば直接対面にてお打合せ可能です。',},
        {id : 3,Title : 'サーバーやドメインの取得もしていただけますか？',Text : 'A．はい。サーバーやドメインの取得にも対応しております。',},
        {id : 4,Title : 'はじめてホームページを依頼しますが、大丈夫でしょうか？',Text : 'A．はい。ご安心ください。お客様のご要望をお伺いさせていただき当社にて構成やデザインなどの\nご提案をさせて頂きます。不安やお困りごとをご相談ください。',},
      ]
    }
  },
  methods: {
    WebFaq() {
      gsap.fromTo('.js-qa-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-qa-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-faq-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-faq-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-faq-fade1' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-faq-fade1',start: 'center 70%'}});
      gsap.fromTo('.js-faq-fade2' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-faq-fade2',start: 'center 70%'}});
      gsap.fromTo('.js-faq-fade3' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-faq-fade3',start: 'center 70%'}});
      gsap.fromTo('.js-faq-fade4' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-faq-fade4',start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
   this.$nextTick(function () {
      this.WebFaq();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}


.faq {
  padding:2rem 0 5rem 0;
  background-color: #F1EEE7;
  @include sp {
    padding: 2.5rem 0;
  }
  h3 {
    @include h2-title(13%);
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
     @include sp {
      width: 1.5rem;
     }
    }
  }

  .r-box {
    margin-left: 3.5rem;
    @include sp {
      width:  100%;
      margin-left: 1.25rem;
    }
  }
 
 &__content {
   margin-bottom: 2.5rem;
    @include sp {
      width: 15.4375rem;
      margin: 0 0 2.5rem 0;
    }
   .question {
     font-size: 1.5rem;
     font-weight: 500;
     margin-bottom: 1rem;
     padding-left: 3rem;
     position: relative;
    @include sp {
      font-size: 1rem;
      line-height: 140%;
      margin-bottom: 0.5rem;
      padding-left: 1.875rem;
    }
     &::before {
       content: '';
       position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 2.25rem;
        height: 2.25rem;
        background-image: url('~@/assets/img/q.png');
        background-repeat: no-repeat;
        background-size: 2.25rem;
        @include sp {
          top: 0;
          transform: translateY(0);
          width: 1.4063rem;
          height: 1.4063rem;
          background-size: 1.4063rem;
        }
     }
   }
   .answer {
     font-size: 1rem;
     font-weight: 500;
     line-height: 200%;
     background-color: #fff;
     border-radius: 0.625rem;
     padding: 1rem 1.5rem;
     white-space: pre-line;
    @include sp {
      font-size: 0.75rem;
      line-height: 180%;
      padding: 1rem 1.5rem;
    }
   }
 }
}
</style>