<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" class="-mx-3 md:-mx-2 md:pt-[10px] inline-block">
    <div v-for="(items, index) in groupedArray" :key="index" class="text-left inline-block">
      <div v-for="(item, index) in items" :key="index" class="py-3 py-md-2 px-3 px-md-1 inline-block">
        <FormLabel>
          <input :id="item.id" :name="name"  type="radio" v-model="inputtedValue" :value="item.value" class="base text-xs md:text-base rounded-small leading-relaxed bg-white border-pfblack-100 
          ">
          <span class="pl-10 pl-md-8 after:bg-pforange-100 before:w-8 before:h-8 md:before:w-6 md:before:h-6 after:w-6 after:h-6 md:after:w-4 md:after:h-4 after:left-1  before:border-4 before:border-pfblack-100 ">
            {{item.text}}
          </span>
        </FormLabel>
      </div>
    </div>
    <p v-show="errors.length" class="help text-pforange-200 pt-2 px-4 px-md-6 mb-0 text-left">
      {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>
<script>
import FormLabel from '@/components/modules/FormLabel';
export default {
  components:{
    FormLabel,
  },
  props:{
    name:{
      type:String
    },
    value: {
      type:String
    },
    options: {
      type: Array
    },
    rules: {
      type: Object,
      required: true
    },
  },
  computed:{
    inputtedValue:{
      get(){
        return this.value
      },
      set(newValue){
        this.$emit("input", newValue)
      }
    },
    groupedArray() {
      const base = this.options.length
      const split_cnt = 2       // 何個ずつに分割するか
      const grouped_array = []
      for (let i=0; i<Math.ceil(base/split_cnt); i++) {
        let multiple_cnt = i * split_cnt  // 3の倍数
        // (i * 3)番目から(i * 3 + 3)番目まで取得
        let result = this.options.slice(multiple_cnt, multiple_cnt + split_cnt) 
        grouped_array.push(result)
      }
      return grouped_array
    }
  }
};
</script>
<style scoped>
.base {
	border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.base:focus-visible ~ span {
  color: theme('colors.pforange.200');
  transition: all 0.5s ease-out;

}
.base + span {
	cursor: pointer;
	display: inline-block;
	margin: 0 0.2em 0;
	padding: 0 0 0 1.2em;
	position: relative;
}
.base + span::before {
	-webkit-transform: translateY(-50%);
	background: #fff;
	border: 1px solid rgba(0, 0, 0, 0.16);
	border-radius: 50%;
	content: "";
	display: block;
	height: 16px;
	left: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 16px;
}
.base + span::after {
	-webkit-transform: translateY(-50%);
	background: rgba(0, 0, 0, 0.32);
	border: 1px solid transparent;
	border-radius: 50%;
	content: "";
	height: 8px;
	left: 4px;
	opacity: 0;
	padding: 2px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.3s ease 0s;
	width: 8px;
}
.base:checked + span::after {
	opacity: 1;
}
</style>