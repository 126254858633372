<template>
    <section id="works" class="works">
      <div class="works__inner js-works-main">
          <SectionTitle title="<span>W</span><span>o</span><span>r</span><span>k</span><span>s</span>"/>
          <div class="r-box">
            <h3 class="js-works-fade01">実績紹介</h3>
            <div class="works__content js-works-fade02">
              <a :href='item.Url' class="item works-item" v-for='(item,index) in items' :key='index' :class='{ isShow:isActive }' target="_blank" rel="noopener noreferrer">
                  <div class="item__img"><img :src="item.Img"></div>
                  <p class="item__name">{{ item.Name }}</p>
                  <p class="item__category">{{ item.Category }}</p>
              </a>
            </div>
            <button class="more-btn" @click='active' v-if='isActive'>もっと見る</button>
            <button class="more-btn more-btn__close" @click='active' v-else>閉じる</button>
          </div>
      </div>
    </section>
</template>

<script>
import  gsap  from 'gsap';
import  ScrollTrigger  from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

const WorksItem = document.getElementsByClassName('works-item');
const lenght = WorksItem.length;
console.log(lenght);

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      isActive: true,

      items : [
        {Name : '卸売業',Category : '通販サイト',Img : require('@/assets/img/webproduct/img01.png'),},
        {Name : '飲食業',Category : '通販サイト',Img : require('@/assets/img/webproduct/img02.png'),},
        {Name : '飲食業',Category : '通販サイト',Img : require('@/assets/img/webproduct/img03.png'),},
        {Name : '卸売業',Category : '通販サイト',Img : require('@/assets/img/webproduct/img04.png'),},
        {Name : '卸売業',Category : '通販サイト',Img : require('@/assets/img/webproduct/img05.png'),},
        {Name : '社労士事務所',Category : '企業サイト',Img : require('@/assets/img/webproduct/img06.png'),Url : 'https://sr.profit-bnk.com/'},
        {Name : '卸売業',Category : '企業サイト',Img : require('@/assets/img/webproduct/img07.png'),},
        {Name : '飲食業',Category : '通販サイト',Img : require('@/assets/img/webproduct/img08.png'),},
        {Name : '飲食業',Category : '通販サイト',Img : require('@/assets/img/webproduct/img09.png'),},
      ]
    }
  },
  methods: {
        active: function () {
            this.isActive = !this.isActive;
        },
    WebWorks() {
      gsap.fromTo('.js-works-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-works-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-works-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-works-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-works-fade02 a' , {opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-works-fade02',start: 'center 70%'} , stagger:{from:'start',amount:'0.5'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
   this.$nextTick(function () {
      this.WebWorks();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}


.works {
  padding:2rem 0 5rem 0;
  background-color: #FAF9F7;
  @include sp {
    padding: 2.5rem 0;
  }
  h3 {
    width: fit-content;
    font-weight: bold;
    font-size: 2.5rem;
    text-align: center;
    margin: 3rem auto 2.5rem auto;
    @include sp {
      font-size: 1.5rem;
      margin: 0 auto 1.5rem auto;
    }
  }
  &__inner {
    width: 58.75rem;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @include sp {
      width: 21.25rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    @include sp {
      width: 15.4375rem;
      margin: 0 auto;
    }
  }
 
 &__content {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin-bottom: 2.5rem;
  &::after {
    content: '';
    width: 16.5rem;
  }
  .item {
    display: block;
    width: 16.5rem;
    margin-bottom: 2.5rem;
    pointer-events: none;
    @include sp {
      width: 7.375rem;
      margin-bottom: 1.625rem;
    }
    // 7番目以降に追加
    &:nth-of-type(n+7) {
      &.isShow {
        display: none;
      }
    }
    &:nth-of-type(6) {
      transition: .2s;
      pointer-events: auto;
      &:hover {
        opacity: .7;
        transition: .2s;
      }
    }
    


    &__img {
      width: 100%;
      min-height: 10.3125rem;
      box-shadow: 0.25rem 0.25rem 0.75rem rgba(51, 51, 51, 0.04);
      margin-bottom: 0.625rem;
      @include sp {
        min-height: 4.5rem;
      }
      img {
        display: block;
        width: 100%;
        object-fit: fill;
      }
    }
    &__name {
      font-size: 1rem;
      font-weight: 700;
      @include sp {
        font-size: 0.75rem;
      }
    }
    &__category {
      width: fit-content;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.5;
      border: 0.0625rem solid;
      padding: 0.0625rem 0.3125rem;
      @include sp {
        font-size: 0.625rem;
        padding: 0.125rem;
      }
    }
  }
 }

 .more-btn {
   display: block;
   width: 21rem;
   background-color: #fff;
   border: 0.0625rem #E56910 solid;
   padding: 1.5625rem 0;
   font-size: 1.25rem;
   font-weight: 700;
   color: #E56910;
   text-align: center;
   margin: 0 auto;
   transition: .2s;
   position: relative;
   @include sp {
     width: 15.4375rem;
     padding: 1rem 0 0.9375rem 0;
     font-size: 1rem;
   }
   &::after {
     content: url('~@/assets/img/arrow.png');
     position: absolute;
     top: 50%;
     right: 2.5rem;
     transform: translateY(-50%) scale(0.5);
     @include sp {
        transform: translateY(-50%) scale(0.4);
     }
   }
   &:hover {
     transition: .2s;
     background-color: #E56910;
     color: #fff;
     &::after {
     content: url('~@/assets/img/w-arrow.png');
     position: absolute;
     top: 50%;
     right: 2.5rem;
     transform: translateY(-50%) scale(0.5);
     @include sp {
        transform: translateY(-50%) scale(0.4);
     }
     }
   }
    // 閉じるボタン
   &__close {
   &::after {
     transform: translateY(-50%) scale(0.5) rotate(180deg);
     @include sp {
        transform: translateY(-50%) scale(0.4) rotate(180deg);
     }
   }
   &:hover {
     &::after {
     transform: translateY(-50%) scale(0.5) rotate(180deg);
     @include sp {
        transform: translateY(-50%) scale(0.4) rotate(180deg);
     }
     }
   }
   }
 }
}
</style>