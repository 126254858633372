<template>
  <div id="contact" class="">
    <v-container class="max-w-310 py-4 py-md-8 px-0 mt-lg-0">
      <v-flex class="section__wrapper w-full">
        <div class="contact-title"><SectionTitle title="<span>C</span><span>o</span><span>n</span><span>t</span><span>a</span><span>c</span><span>t</span>" class="w-28px md:w-52px mr-3 mr-md-13"></SectionTitle></div>
        <div class="content__wrapper w-full">
          <Breadcrumb v-if="true" />
          <router-view :inputs.sync="inputs" class="pb-6 pb-md-12 pt-4 pt-md-6"></router-view>
        </div>
      </v-flex>
    </v-container>
  </div>
</template>
<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Breadcrumb from '@/components/modules/Breadcrumb';
import SectionTitle from '@/components/modules/SectionTitle';
import FormInput from '@/components/modules/FormInput';
import FormRadio from '@/components/modules/FormRadio';
// import FormSelect from '@/components/modules/FormSelect';
import FormTextarea from '@/components/modules/FormTextarea';
import { TEL } from '@/common/constants';

export default {
  components:{
    Breadcrumb,
    SectionTitle
  },
  data() {
    return {
      inputs:[
        {component:FormInput, type:"text", name:'company', title:'会社名', placeholder:'株式会社プロフィットバンク', rules:{}, value:""},
        {component:FormInput, type:"text", name:'yourName', title:'担当者様お名前', placeholder:'山田　太郎', required:true, rules:{required:true}, value:""},
        {component:FormInput, type:"tel", name:'tel', title:'電話番号', placeholder:TEL, required:true, 
          rules:{required: true, regex: "^[0]\\d{1,4}-\\d{1,4}-\\d{3,4}$"}, value:""},
        {component:FormInput, type:"email", name:'email', title:'メールアドレス', placeholder:'info@profit-bnk.com', required:true, rules:{required:true}, value:""},
        {component:FormInput, type:"url", name:'url', title:'WebサイトURL', placeholder:'https://profit-bnk.com', rules:{ regex: "https?://[\\w/:%#\\$&\\?\\(\\)~\\.=\\+\\-]+" }, value:""},
        {component:FormRadio, name:'service', title:'ご希望のサービス', required:true, rules:{required:true}, 
          options:[
                  {id:"account" , value:'経理代行',text:'経理代行'},
                  {id:"webproduct" , value:'ホームページ制作',text:'ホームページ制作'},
                  {id:"development", value:'ソフトウェア受託開発',text:'ソフトウェア受託開発'},
                  {id:"other" , value:'その他',text:'その他'},
                  ], 
          value:"経理代行"},
        // {component:FormSelect, name:'budget', title:'ご予算', required:true, rules:{required:true}, 
        //   options:[
        //           {value:'1万円～5万円',text:'1万円～5万円'},
        //           {value:'5万円～10万円',text:'5万円～10万円'},
        //           {value:'10万円～20万円',text:'10万円～20万円'},
        //           {value:'20万円以上',text:'20万円以上'},
        //           {value:'未定',text:'未定'},
        //           ], 
        //   value:"1万円～5万円"},
        {component:FormTextarea, name:'message', title:'お問い合わせ内容', placeholder:'ご相談内容を自由にお書きください。', required:true, rules:{required:true}, value:""},
      ]
    }
  },
  methods: {
    submit: async function() {
      await this.send();
    },
    Contact() {
      gsap.fromTo('.contact-title h2 span' , {opacity:0 , delay : 0.9 },{opacity:1, delay : 0.9 ,scrollTrigger: {  trigger: '.contact-title h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.Contact();
    });
  },
}
</script>
<style scoped>
  .section__wrapper {
    padding: 0 24px;
  }
  .content__wrapper {
    padding-right: 0; 
  }
  .no-breadcrumb {
    padding-left: 40px;
  }
  @screen md {
    .section__wrapper {
      padding: 0 96px;
    }
    .content__wrapper {
      padding-right: 104px;
    } 
    .no-breadcrumb {
      padding-left: 104px;
    }
  }
</style>