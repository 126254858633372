<template>
    <section id="price" class="price">
      <div class="price__inner js-pp-main">
          <SectionTitle title="<span>P</span><span>r</span><span>i</span><span>c</span><span>e</span> <span>＆</span> <span>P</span><span>l</span><span>a</span><span>n</span><span>s</span>"/>
          <div class="r-box">
            <h3 class="js-pp-fade01">料金プランと内容</h3>
            <div class="plan js-pp-fade02">
              <p class="standard">Standard<span>スタンダードプラン</span></p>
              <div class="flex">
                <p class="number"><span>150,000</span>円/月~</p>
                <ul class="list">
                  <li>訪問(月に数回お伺いします)</li>
                  <li>納品・請求書発行</li>
                  <li>売掛・買掛処理</li>
                  <li>売掛金未入金先への督促</li>
                  <li>売掛金、買掛金の消し込み</li>
                  <li>振込処理</li>
                  <li>経費精算</li>
                  <li>仕分け(勘定科目設定)</li>
                  <li>試算表の作成</li>
                  <li>融資相談</li>
                  <li>給与計算</li>
                </ul>
              </div>
            </div>

            <p class="etc js-pp-fade03">※その他、単独業務の<br class="sp">ご要望も承っております。</p>
            <div class="flex02 js-pp-fade04">
              <p class="text">納品・請求書発行</p>
              <p class="text">売掛・買掛処理</p>
              <p class="text">経費精算</p>
              <p class="text">入出金管理</p>
              <p class="text">試算表の作成</p>
              <p class="text">給与計算</p>
            </div>
            <div class="can-cover js-pp-fade05">
              <p class="can-title">プロフィットバンクにできること</p>
              <p class="can-text">
                私たちのサービスは時間制報酬ではなく、お客様のお困りごとを最後まで着実に完結させることを目的としています。<br>
                他社のサービスでは手が届かない細かな業務にも対応していきます。些細なことでもご相談ください。
              </p>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  methods: {
    AccountingPrice() {
      gsap.fromTo('.js-pp-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-pp-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-pp-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-pp-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-pp-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-pp-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-pp-fade03' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-pp-fade03',start: 'center 70%'}});
      gsap.fromTo('.js-pp-fade04 p' , {opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-pp-fade04 p',start: 'center 70%'},stagger:{from : 'start', amount : 0.5,}});
      gsap.fromTo('.js-pp-fade05' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-pp-fade05',start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.AccountingPrice();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.price {
  padding:2rem 0 5rem 0;
  background-color:#F1EEE7;
  @include sp {
    padding: 1.5rem 0 2rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 4.5rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.5rem 0;
    padding-left: 15%;
  }
  }
  .heding-img {
    display: block;
    width: 43.375rem;
    margin: 0 auto;
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      justify-content: end;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
        padding-top: 1rem;
    }
  }
  .plan {
    width: 39.8125rem;
    min-height: 44.9375rem;
    background-color: #fff;
    border-radius: 1.25rem;
    box-shadow: 0.25rem 0.25rem 0.75rem rgba(51, 51, 51, 0.04);
    margin: 0 auto;
    @include sp {
      width: 15.4375rem;
      min-height: auto;
      border-radius: 0.5rem;
      margin: 0;
    }
  }
  .standard {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    padding: 1.5625rem 0;
    background-color: #F2AB34;
    border-radius: 1.25rem 1.25rem 0 0;
    @include sp {
      font-size: 12px;
      border-radius: 0.5rem 0.5rem 0 0;
      padding: 1rem 0;
    }
    span {
      display: block;
      font-size: 2.375rem;
      margin-top: 1rem;
      @include sp {
        font-size: 20px;
        margin-top: 0.2rem;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
    padding: 4rem 0;
    @include sp {
      display: block!important;
      padding: 1rem 1rem 1.5rem 1rem;
    }
  }
  .number  {
    width: 45%;
    font-size: 20px;
    text-align: center;
    @include sp {
      width: 100%;
      font-size: 16px;
      padding-bottom: 1rem;
    }
    span {
      font-size: 40px;
      font-weight: bold;
      @include sp {
        font-size: 20px;
      }
    }
  }
  .list {
    width: 55%;
    border-left: 1px rgba(196, 196, 196, 1) solid;
    padding-left: 56px;
    @include sp {
      width: 100%;
      padding: 1rem 0 0 20px;
      border-top: 1px rgba(196, 196, 196, 1) solid;
      border-left: none;
    }
    li {
      font-size: 1rem;
      margin-bottom: 1rem;
      padding-left: 1.5rem;
      position: relative;
      @include sp {
        font-size:12px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1.25rem;
        height: 1.25rem;
        background-image: url('~@/assets/img/accounting/check.png');
        background-size: 1.125rem;
        background-position: center;
        background-repeat: no-repeat;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .etc {
    font-size: 1.5rem;
    text-align: center;
    margin: 4rem 0 2.5rem 0;
    @include sp {
      width: fit-content;
      font-size: 12px;
      text-align: center;
      margin: 3rem 0 1rem 0;
      padding-left: 3.5rem;
    }
  }
  .flex02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    @include sp {
      width: 247px;
      margin: 0 0 1rem 0;
    }
    .text {
      width: 16.5rem;
      background-color: #F2AB34;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #F2AB34;
      border-radius: 1.25rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      padding: 1.5rem 0;
      margin-bottom: 1.5rem;
      @include sp {
        width: 115px;
        font-size: 12px;
        border-radius: 0.5rem;
        padding: 1rem 0;
        margin-bottom: 1rem;
      }
    }
  }

  .can-cover {
    border: 3px solid #E56910;
    background-color: #fff;
    padding: 2.5rem 7rem 2.5rem 7.5625rem;
    @include sp {
      width: 247px;
      padding: 1rem 0.7rem 1.5rem 0.7rem;
    }
    .can-title {
      font-size: 2.5rem;
      font-weight: bold;
      color: #E56910;
      margin-bottom: 1.5rem;
      @include sp {
        font-size: 24px;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 0.5rem;
      }
    }
    .can-text {
      font-size: 1rem;
      @include sp {
        font-size: 12px;
      }
    }
  }
}
</style>