<template>
  <div>
    <v-row class="mr-0 md:ml-auto">
      <v-col cols="12" sm="7" md="9" lg="12" class="pr-10 pr-sm-0 px-md-0 md:max-w-130">
        <h3 class="js-fade">
          <span class="inline-block">お客様がより</span>
          <span class="inline-block">創造的な</span>
          <br class="md:hidden">
          <span class="inline-block">仕事を</span>
          <span class="inline-block">行うために</span>
          <span class="inline-block">できることを。</span>
        </h3>
      </v-col>
      <v-col cols="7" sm="4" md="3" lg="8" class="-ml-10 hidden sm:block md:hidden">
        <v-img
          src="@/assets/img/top/mission_img.png"
          contain
          :aspect-ratio="1/1"
          max-width="305"
          loading="lazy"
          >
        </v-img>
      </v-col>
      <v-col cols="12" md="9" lg="9" class="pt-1 pr-10 pr-md-3 md:max-w-130">
        <v-row class="md:flex-col-reverse">
          <v-col cols="7" md="8" lg="8" class="sm:hidden md:block sm:justify-start ml-auto -mt-10 md:-mt-12 md:-mt-16 -mr-10 md:-mr-9 md:-mr-8 pb-0 position">
            <v-img
              src="@/assets/img/top/mission_img.png"
              contain
              :aspect-ratio="1/1"
              max-width="305"
              loading="lazy"
              >
            </v-img>
          </v-col>
          <v-col cols="12" sm="10" md="12" class="md:px-0 z-10">
            <p class="js-fade02">
              <span class="inline-block">当社のミッションは中小企業・小規模事業者の利益率を改善し、更なる成長を促すことです。</span>
              <span class="inline-block">IT技術を駆使することで、人の手では困難な生産性の向上を実現し、また、経理業務のサポートをすることで、見えづらいコストを可視化し効果的なコスト削減を実現します。</span>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


  </div>
</template>
<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default {

  methods: {
    TopMission() {
      gsap.fromTo('.js-fade' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-fade',  start: 'center 70%'}});
      gsap.fromTo('.js-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {  trigger: '.js-fade02',  start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.$nextTick(() => {
      this.TopMission();
    });
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
.position {
  position: relative;
  right: -16.875rem;
  @include sp {
    right: 0;
  }
}


  .mission__background {
    background: url("~@/assets/img/mission_circle.png");
    background-size: 32.125rem 25.4375rem;
    background-repeat: no-repeat;
    background-position: center center;
    width: calc(100% + 8.6875rem);
    height:25.4375rem;
    margin: -2rem -4.6875rem 0 -4rem;
  }

@screen sm {
  .mission__background {
    background-size: contain;
    width:auto;
    height: 33.75rem;
  }
}

  @screen md {
    .mission__background {
      background-size: contain;
      width:auto;
      height: 49.125rem;
      margin: -9.125rem -21.9375rem -0.625rem 0;
    }
  }
</style>