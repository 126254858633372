<template>
    <section id="works" class="works">
      <div class="works__inner js-works-main">
          <SectionTitle title="<span>W</span><span>o</span><span>r</span><span>k</span><span>s</span>"/>
          <div class="r-box">
            <h3 class="js-w-fade01">主な開発実績</h3>

              <div class="flex-box js-w-fade02">
                <div class="m-title">開発システム</div>
                <ul class="flex-box__ul">
                  <li>会計・経理システム</li>
                  <li>在庫・生産管理システム</li>
                  <li class="margin">見積・納品・発注・請求システム</li>
                  <li>顧客管理システム</li>
                  <li>契約書作成・管理システム</li>
                  <li>製造業向け品質管理システム</li>
                  <li>会計・経理システム</li>
                  <li>Google Apps・チャットワーク・freee等の連携API</li>
                </ul>
              </div>

              <div class="flex-box js-w-fade03">
                <div class="m-title">開発言語</div>
                <ul class="flex-box__ul02">
                  <li>サーバー<span class="span01">・・・・・</span>C、C++、C#</li>
                  <li>クライアント<span class="span02">・・・・</span>VBA、Apple Script</li>
                  <li>Web系<span class="span03">・・・・・</span>HTML、CSS、<span class="span04">JavaScript、ASP、Node.js</span></li>
                </ul>
              </div>

              <div class="flex-box js-w-fade04">
                <div class="m-title">開発環境</div>
                <ul class="flex-box__ul02 flex-box__ul02--max">
                  <li>Windows、Mac</li>
                  <li>Windows Server、RHEL、UNIX</li>
                  <li>その他</li>
                </ul>
              </div>

              <p class="under__text js-w-fade05">
                製造業・卸売業・サービス業・水産業・<br class="sp">不動産業など、様々な業種業界にサービス提供を行ってきた<br class="sp">経験を<br class="pc">
                活かしてソフトウェア開発に<br class="sp">取り組んでおります。
              </p>
          </div>
      </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SectionTitle from "@/components/modules/SectionTitle";

export default {
  components: {
    SectionTitle,
  },
  methods: {
    DeveWorks() {
      gsap.fromTo('.js-works-main h2 span' , {opacity:0},{opacity:1,scrollTrigger: {  trigger: '.js-works-main h2 span',  start: 'center 70%' } , stagger : { from : 'start', amount : '0.5' }});
      gsap.fromTo('.js-w-fade01' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-w-fade01',start: 'center 70%'}});
      gsap.fromTo('.js-w-fade02' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-w-fade02',start: 'center 70%'}});
      gsap.fromTo('.js-w-fade03' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-w-fade03',start: 'center 70%'}});
      gsap.fromTo('.js-w-fade04' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-w-fade04',start: 'center 70%'}});
      gsap.fromTo('.js-w-fade05' , 0.5 ,{opacity:0 ,y : 20,},{opacity:1, y : 0,scrollTrigger: {trigger: '.js-w-fade05',start: 'center 70%'}});
    }
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
   this.$nextTick(function () {
      this.DeveWorks();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sp.scss";
@import "@/assets/scss/mixin.scss";
p {
  margin: 0;
}

.works {
  padding:2rem 0 5rem 0;
  background-color: #F1EEE7;
  @include sp {
    padding: 1rem 0 1.5rem 0;
  }
  h3 {
  width: fit-content;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin: 0.1rem auto 2rem auto;
  @include sp {
    font-size: 1.25rem;
    margin: 0 0 1.5rem 0;
    padding-left: 20%;
  }
  }
  .heding-img {
    display: block;
    width: 43.375rem;
    margin: 0 auto;
  }
  &__inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      width: 23.4375rem;
      max-width: 100%;
      padding: 0 1.5rem;
    }
    .title__vertical {
      width: 3.125rem;
      @include sp {
        width: 1.5rem;
      }
    }
  }

  .r-box {
    width: 52.5rem;
    margin-left: 3.5rem;
    padding-top: 3rem;
    @include sp {
        width: 100%;
        display: block;
        margin-left: 1.25rem;
        padding: 1.5rem 0 0 0;
    }

    &__text {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 3.125rem;
      @include sp {
        width: fit-content;
        font-size: 0.75rem;
        margin-bottom: 2.5rem;
      }
      span {
        display: block;
        width: fit-content;
        font-size: 1.5rem;
        font-weight: bold;
        border-bottom: 0.0625rem solid;
        margin: 0 auto 1rem auto;
        @include sp {
          font-size: 1rem;
          margin: 0 0 0.8rem 0;
        }
      }
    }

    .flex-box {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;
      @include sp {
        display: block;
        margin-bottom: 1.5rem;
      }
      .m-title {
        width: 10.9375rem;
        font-size: 1.5rem;
        color: #fff;
        text-align: center;
        background-color: #F2AB34;
        border-radius: 0.625rem;
        padding: 3.5rem 0 3rem 0;
        @include sp {
          width: 15.4375rem;
          font-size: 1rem;
          padding: 1rem 0;
          margin: 0;
        }
      }

      li {
        position: relative;
        padding-left: 0.8125rem;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 0.625rem;
          height: 0.625rem;
          background-image: url('~@/assets/img/development/round.png');
          background-position: center;
          background-size: 0.5rem;
          @include sp {
            top: 0.3125rem;
            transform: translateY(0);
          }
        }
      }

      .margin {
        margin: 0 0 1rem 0.9375rem;
        @include sp {
        margin: 0 0 1rem 0;
        }
      }

      &__ul {
        width: 43.75rem;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        @include sp {
          display: block;
          width: 15rem;
          margin: 0.75rem auto;
          padding-left: 0;
        }
        li {
          min-width: 10rem;
          font-size: 1rem;
          margin-bottom: 1rem;
          margin-right: 0.9375rem;
          @include sp {
            font-size: 0.75rem;
            margin-bottom: 0.5rem;
          }
        }
      }
      &__ul02 {
        width: 43.75rem;
        max-width: 100%;
        margin-top: 1rem;
        @include sp {
          display: block;
          width: 100%;
          margin: 0.75rem auto;
        }
        &--max {
          @include sp {
            max-width: 15rem;
          }
        }
        li {
          font-size: 1rem;
          margin-bottom: 1rem;
        @include sp {
          font-size: 0.75rem;
          margin-bottom: 0.5rem;
        }
        }
        .span01 {
          display: inline-block;
          margin: 0 1rem 0 2rem;
          @include sp {
            margin: 0 0.9375rem 0 0.25rem;
          }
        }
        .span02 {
          display: inline-block;
          margin: 0 1rem 0 1rem;
          @include sp {
            margin: 0 0.25rem 0 0.25rem;
          }
        }
        .span03 {
          display: inline-block;
          margin: 0 1rem 0 2.875rem;
          @include sp {
            margin: 0 1.625rem 0 0.25rem;
          }
        }
        .span04 {
          @include sp {
            display: inline-block;
            width: 6.25rem;
            margin-left: 7.9375rem;
          }
        }
      }
    }
  .under__text {
    font-size: 1rem;
    font-weight: bold;
    line-height: 200%;
    text-align: center;
    padding-top: 1.5rem;
    @include sp {
      width: fit-content;
      font-size: 0.75rem;
      margin-left: 0.625rem;
      padding-top: 2rem;
    }
  }
}
}
</style>