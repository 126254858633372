<template>
  <p :class="{'required':isRequired }" class="inline-block align-middle text-xs md:text-base font-bold leading-relaxed">
    <slot></slot>
  </p>
</template>
<script>
export default {
  props: {
    required: {
      Boolean,
      default:false
    },
    name: {
      String
    }
  },
  data() {
    return {
      isRequired: this.required
    }
  },
}
</script>
<style scoped>
.required::after {
  content: "必須";
  background-color: theme('colors.pforange.200');
  color: #fff;
  font-size: .625rem;
  font-weight: bold;
  min-width: 10px;
  padding: 1px 4px;
  margin: 0px 5px;
  line-height: 1.5714;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
}

@screen md {
  .required::after {
    font-size: .875rem;
    padding: 2px 16px;
  }
}
</style>