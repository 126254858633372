<template>
  <div :id="getId" :class="`bg-${getBgColor}`">
    <!-- <v-container class="max-w-310 pt-4 pt-md-8 pb-10 pb-md-20 px-0 mt-lg-0"> -->
    <v-container class="max-w-310 pb-10 pb-md-20 px-0 mt-lg-0">
      <div class="px-md-0">
        <div>
          <Breadcrumb v-if="isBreadcrumb" />
          <component :is="getContent" :class="(isBreadcrumb) ? 'pt-7':'pt-12'" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Breadcrumb from '@/components/modules/Breadcrumb';
import SectionTitle from '@/components/modules/SectionTitle';
export default {
  components:{
    Breadcrumb,
    SectionTitle
  },
  props:{
    id:{
      type:String,
      default: ''
    },
    title:{
      type:String
    },
    bgcolor:{
      type:String,
      default: 'transparent'
    },
    txtcolor:{
      type:String,
      default: 'pfblack-100'
    },
    content:{
      type:Object
    },
    breadcrumb: {
      type:Boolean,
      default: false
    }
  },
  data(){
    return {
      getId: this.id,
      getTitle: this.title,
      getBgColor: this.bgcolor,
      getTxtColor: this.txtcolor,
      getContent: this.content,
      isBreadcrumb: this.breadcrumb
    }
  }
}
</script>
<style lang="scss" scoped>

.container {
  padding: 0;
}
</style>